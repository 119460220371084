import {
    AnyAction,
    createSlice,
    PayloadAction,
    ThunkAction,
  } from "@reduxjs/toolkit";
  import {
    ICounterTypeService,
    ICounterTypeServiceForm,
    ISetsAndServiceModel,
    ITotemTypeService,
  } from "models/CounterTypeService";
import { ICovenant, IHealthplanStandardtable, IHistory, IItensPLan } from "models/Covenant";
  import { IFilter } from "models/shared";
import { IBatch } from "pages/Covenant/BatchGuides/model";
import { ISubmitHealthPlan } from "pages/Covenant/QueryTabsManually/Form/data";
import { ISubmitSpSadtHealthPlan } from "pages/Covenant/SpSadt/Form/data";
import { IItensguiaspsadt, ISPSADTguide } from "pages/Covenant/SpSadt/List/model";
  import { toast } from "react-toastify";
  import { AppThunk, rootState } from "store/index";
  import api from "utils/API";
  import { history } from "utils/history";
  import { queryStringFromFilterArray } from "utils/network";
  import toastOptions from "utils/toastOptions";
  import { saveAs } from 'file-saver';
import { IStockItem } from "models/StockItem";
import { IItemStockReferences } from "models/ItemStockReferences";
import dayjs from "dayjs";
  
  interface IInitialState {
    isFetchingReport: boolean;
    filterStandardTables: IFilter[];
    consultationGuideFilterArray: IFilter[];
    spSadtGuideFilterArray: IFilter[];
    standardTables: ICovenant[] | null;
    limit: number;
    page: number;
    total: number;
    standardtable: any;
    standard: IHealthplanStandardtable[]|null;
    isFetchingStandard: boolean;
    itensPlan: IItensPLan[];
    consultationGuide: ISubmitHealthPlan[]|null;
    consultationGuideById: ISubmitHealthPlan|null;
    spsadtguides: ISPSADTguide|null;
    spsadtguidesList: ISPSADTguide[] | null;
    filterBatch: IFilter[];
    listBatch: IBatch[];
    isFetchingBatch: boolean;
    isEditingBatch: boolean;
    isDownloadingBatch: boolean;
    batch: IBatch | null;
    itemStock: IStockItem[];
    itemStockReferences: IItemStockReferences[];
    isFetchingHistory: boolean;
    isCreatingHistory: boolean;
    tableVersionHistory: IHistory[];
  }
  
  const initialState: IInitialState = {
   isFetchingReport: false,
   filterStandardTables: [{ key: "tabelapropria", value: null },{key: "nome" , value: null}],
   standardTables: null,
   page: 1,
   limit: 6,
   total: 0,
   standardtable: null,
   standard: null,
   isFetchingStandard: false,
   itensPlan: [],
   consultationGuide: null,
   consultationGuideById: null,

   //batch
   consultationGuideFilterArray: [
    { key: "start_date", value: dayjs(new Date()).format("YYYY-MM-DD") },
    { key: "end_date", value: dayjs(new Date()).format("YYYY-MM-DD") },
    { key: "idplano", value: null },
    { key: "idpaciente", value: null },
    { key: "criador", value: null },
  ],
  spSadtGuideFilterArray: [
    { key: "start_date", value: dayjs(new Date()).format("YYYY-MM-DD") },
    { key: "end_date", value: dayjs(new Date()).format("YYYY-MM-DD") },
    { key: "idplano", value: null },
    { key: "idpaciente", value: null },
    { key: "criador", value: null },
  ],

  //spsadtguides
   spsadtguides: null,
   spsadtguidesList: [],

  //batch
    filterBatch: [
      { key: "start_date", value: new Date()},
      { key: "end_date", value: new Date()},
      { key: "numerolote", value: null },
      { key: "datacriacao", value: null },
      { key: "dataenvio", value: null },
      { key: "envio", value: false },
      { key: "plano", value: null},
      { key: "criacao", value: true},
    ],
    listBatch: [],
    isFetchingBatch: false,
    isDownloadingBatch: false,
    isEditingBatch: false,
    batch: null,

    //itemstock
    itemStock: [],
    itemStockReferences: [],

    isCreatingHistory: false,
    isFetchingHistory: false,
    tableVersionHistory: []
  };
  
  const covenantSlice = createSlice({
    name: "covenantSlice",
    initialState,
    reducers: {
      setSpsadtguides: (state, {payload}:  PayloadAction<ISPSADTguide>) => {
        state.spsadtguides = payload
      },
      setSpsadtguidesList: (state, {payload}:  PayloadAction<ISPSADTguide[] | null>) => {
        state.spsadtguidesList = payload
      },
      setIsFetchingReport: (state, { payload }: PayloadAction<boolean>) => {
        state.isFetchingReport = payload;
      },
      setisFetchingStandard: (state, { payload }: PayloadAction<boolean>) => {
        state.isFetchingStandard = payload;
      },
      updateConsultationGuideFilter: (state, { payload }: PayloadAction<IFilter>) => {
        const index = state.consultationGuideFilterArray.findIndex(
          (item) => item.key === payload.key
        );
        
        if (index === -1) {
          state.consultationGuideFilterArray.push({ 
            key: payload.key, 
            value: payload.value 
          });
        } else {
          state.consultationGuideFilterArray[index].value = payload.value;
        }

        state.consultationGuide = null;
        state.page = 1;
        state.limit = 6;
        state.total = 0;
      },
      clearConsultationGuideFilter: (state) => {
        state.consultationGuideFilterArray =  [
          { key: "start_date", value: dayjs(new Date()).format("YYYY-MM-DD") },
          { key: "end_date", value: dayjs(new Date()).format("YYYY-MM-DD") },
          { key: "idplano", value: null },
          { key: "idpaciente", value: null },
        ];
      },
      updateSpSadtGuideFilter: (state, { payload }: PayloadAction<IFilter>) => {
        const index = state.spSadtGuideFilterArray.findIndex(
          (item) => item.key === payload.key
        );
        
        if (index === -1) {
          state.spSadtGuideFilterArray.push({ 
            key: payload.key, 
            value: payload.value 
          });
        } else {
          state.spSadtGuideFilterArray[index].value = payload.value;
        }

        state.spsadtguidesList = null;
        state.page = 1;
        state.limit = 6;
        state.total = 0;
      },
      clearSpSadtGuideFilter: (state) => {
        state.spSadtGuideFilterArray =  [
          { key: "start_date", value: dayjs(new Date()).format("YYYY-MM-DD") },
          { key: "end_date", value: dayjs(new Date()).format("YYYY-MM-DD") },
          { key: "idplano", value: null },
          { key: "idpaciente", value: null },
        ];
      },
      updateFilterStandardTables: (state, { payload }: PayloadAction<IFilter>) => {
          const index = state.filterStandardTables.findIndex(
            (item) => item.key === payload.key
          );
          if (index === -1) {
            state.filterStandardTables.push({ key: payload.key, value: payload.value },)
          } else {
            state.filterStandardTables[index].value = payload.value;
          }
      },
      updateFilterBatch: (state, { payload }: PayloadAction<IFilter>) => {
        const index = state.filterBatch.findIndex(
          (item) => item.key === payload.key
        );
        if (index === -1) {
          state.filterBatch.push({ key: payload.key, value: payload.value },)
        } else {
          state.filterBatch[index].value = payload.value;
        }
      },
      clearFilterBatch: (state) => {
        state.filterStandardTables =  [
          { key: "numerolote", value: null },
          { key: "datacriacao", value: null },
          { key: "dataenvio", value: null },
          { key: "envio", value: false },
          { key: "idplano", value: null}
        ];
      },
      setListBatch: (state, { payload }: PayloadAction<IBatch[]>) => {
        state.listBatch = payload;
      },
      setBatch: (state, { payload }: PayloadAction<IBatch>) => {
        state.batch = payload;
      },
      setIsFetchingBatch: (state, { payload }: PayloadAction<boolean>) => {
        state.isFetchingBatch = payload;
      },
      setIsDownloadingBatch: (state, { payload }: PayloadAction<boolean>) => {
        state.isDownloadingBatch = payload;
      },
      setIsEditingBatch: (state, { payload }: PayloadAction<boolean>) => {
        state.isEditingBatch = payload;
      },
      setStandardTables: (state, { payload }: PayloadAction<ICovenant[]>) => {
          state.standardTables = payload;
      },
      setStandardtable: (state, { payload }: PayloadAction<any[]>) => {
        state.standardtable = payload;
      },
      clearFilterStandardTables: (state) =>{
          state.filterStandardTables =  [{ key: "tabelapropria", value: null },{key: "nome" , value: null}];
      },
      setGuideTotal: (state, { payload }: PayloadAction<number>) => {
        state.total = payload;
    },
      setGuideLimit: (state, { payload }: PayloadAction<number>) => {
          state.limit = payload;
      },
      setGuidePage: (state, { payload }: PayloadAction<number>) => {
          state.page = payload;
      },
      setStandard : (state, { payload }: PayloadAction<IHealthplanStandardtable[]>) => {
        state.standard = payload;
      },
      setItensPlan : (state, { payload }: PayloadAction<IItensPLan[]>) => {
        state.itensPlan = payload;
      },
      setConsultationGuide : (state, { payload }: PayloadAction<ISubmitHealthPlan[]>) => {
        state.consultationGuide = payload;
      },
      setConsultationGuideById : (state, { payload }: PayloadAction<ISubmitHealthPlan>) => {
        state.consultationGuideById = payload;
      },
      setItemStock : (state, { payload }: PayloadAction<IStockItem[]>) => {
        state.itemStock = payload;
      },
      setItemStockReferences : (state, { payload }: PayloadAction<IItemStockReferences[]>) => {
        state.itemStockReferences = payload
      },
      setIsFetchingHistory: (state, { payload }: PayloadAction<boolean>) => {
        state.isFetchingHistory = payload;
      },
      setIsCreatingHistory: (state, { payload }: PayloadAction<boolean>) => {
        state.isCreatingHistory = payload;
      },
      setTableVersionHistory: (state, { payload }: PayloadAction<IHistory[]>) => {
        state.tableVersionHistory = payload;
      },
    },
  });
  export const fetchStandardtable = (): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingReport, setStandardtable } = covenantSlice.actions;
    dispatch(setIsFetchingReport(true));
    try {
      const response = await api.get(
        `/api/healthplan/standardtable`
      );
      dispatch(setStandardtable(response.data.data));
      dispatch(setIsFetchingReport(false));
    } catch (error: any) {
      dispatch(setIsFetchingReport(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

  export const updateStandard = ({data, isPersonalTable, cb}:{data:IHealthplanStandardtable[], isPersonalTable: boolean, cb: Function}): AppThunk => async (dispatch, getState) => {
    const { setisFetchingStandard, setStandard } = covenantSlice.actions;
    dispatch(setisFetchingStandard(true))
    try {
      const pathParam = isPersonalTable ? 'personaltable' : 'standardtable';

      const bodyParam = isPersonalTable ? 
        { services_health_plan_personal_table: data } : 
        { services_health_plan_standard_table: data }

      await api.put(
        `/api/healthplan/${pathParam}`, bodyParam
      );
      dispatch(fetchDefaultTable())
      cb()
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setisFetchingStandard(false))
    }
  };

  export const insertBatchServiceStandard = ({ 
    services_personal_table, 
    table_version,
    cb 
  }: { 
    services_personal_table: any, 
    table_version: string, 
    cb?: Function 
  }): AppThunk => async (dispatch, getState) => {
    const { setisFetchingStandard } = covenantSlice.actions;

    dispatch(setisFetchingStandard(true));

    try {
      await api.post(
        "/api/healthplan/personaltable/batch", { 
          services_personal_table, 
          table_version 
        } 
      );

      dispatch(fetchDefaultTable())
      
      cb && cb();
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setisFetchingStandard(false));
    }
  };

  export const fetchStandard = ({
    id, 
    isPersonalTable 
  }:{
    id: string,
    isPersonalTable: boolean
  }): AppThunk => async (dispatch, getState) => {
    const { setisFetchingStandard, setStandard } = covenantSlice.actions;
    dispatch(setisFetchingStandard(true))
    try {
      const pathParam = isPersonalTable ? 'personaltable' : 'standardtable';

      const response = await api.get(
        `/api/healthplan/${pathParam}?idversaotabela=${id}`
      );

      if (isPersonalTable) {
        dispatch(setStandard(response.data.data.health_plan_service_personal_table));
      } else {
        dispatch(setStandard(response.data.data.health_plan_service_standard_table));
      }

      dispatch(setisFetchingStandard(false))

    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
      dispatch(setisFetchingStandard(false))
    }
  };

  export const fetchDefaultTable = (): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingReport, setStandardTables } = covenantSlice.actions;
    dispatch(setIsFetchingReport(true));
    try {
      // aaa
      const state = getState();
      const { filterStandardTables, page, limit } = state.covenant;
      const queryParameters = queryStringFromFilterArray(filterStandardTables);
      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;
      const response = await api.get(
        `/api/healthplan/tableversions${queryParameters}${pageAndLimit}`
      );
      dispatch(setStandardTables(response.data.data.health_plan_table_versions));
      dispatch(setIsFetchingReport(false));
    } catch (error: any) {
      dispatch(setIsFetchingReport(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

 

  export const fetchItensPlan = (id:string): AppThunk => async (dispatch, getState) => {
    const { setItensPlan } = covenantSlice.actions;
    try{
     const response = await api.get(`api/servicehealthplancategories?health_plan_category_id=${id}&page=1&limit=9999`) 
      dispatch(setItensPlan(response.data.data.service_health_plan_categories))
    }catch(error:any){
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  }

  // consultas

  export const fetchConsultationguides = (): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingReport, setStandardTables } = covenantSlice.actions;
    dispatch(setIsFetchingReport(true));
    try {
      const state = getState();
      const response = await api.get(
        `/api/healthplans/consultationguides`
      );
      dispatch(setIsFetchingReport(false));
    } catch (error: any) {
      dispatch(setIsFetchingReport(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

  export const submitConsultationGuide = (data:any, cb: Function): AppThunk => async (dispatch, getState) => {
    try{
     const response = await api.post(`/api/healthplans/consultationguides`, data)
     cb(response.data.data.idguiaconsulta)
     toast.success("Guia de consulta criada com sucesso!");
    }catch(error:any){
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  }

  export const editConsultationGuide = (id: string , data:any, cb: Function): AppThunk => async (dispatch, getState) => {
    try{
     const response = await api.put(`/api/healthplans/consultationguides/${id}`, data)
     cb()
     toast.success("Guia de consulta Atualizada com sucesso!");
    }catch(error:any){
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  }

  export const fetchConsultationGuide = ({ 
    page = 1, 
    limit = 6
  }): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingReport, setConsultationGuide, setGuideTotal } = covenantSlice.actions;
    const state = getState();
    const { consultationGuideFilterArray } = state.covenant;
    const queryParameters = queryStringFromFilterArray(consultationGuideFilterArray);

    dispatch(setIsFetchingReport(true));
    
    try {  
      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;

      const response = await api.get(
        `/api/healthplans/consultationguides${queryParameters}${pageAndLimit}`
      );
      dispatch(setGuideTotal(response.data.data.total));
      dispatch(setConsultationGuide(response.data.data.health_plan_consultation_guides));
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsFetchingReport(false));
    }
  };

  export const fetchConsultationGuideById = (id:string): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingReport, setConsultationGuideById } = covenantSlice.actions;
    const state = getState();
      const { page, limit } = state.covenant;
    dispatch(setIsFetchingReport(true));
    try {
      const response = await api.get(
        `/api/healthplans/consultationguides/${id}`
      );
      
      dispatch(setConsultationGuideById(response.data.data));
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsFetchingReport(false));
    }
  };


  //sp-sadt

  export const submitSpsadtguides = (data:any, cb: Function): AppThunk => async (dispatch, getState) => {
    try{
     const response = await api.post(`/api/healthplans/spsadtguides`, data)
     cb(response.data.data.idguiaspsadt)
     toast.success("Guia spsadt criada com sucesso!");
    }catch(error:any){
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  }

  export const editSpsadtguides = (id: string , data:any, cb: Function): AppThunk => async (dispatch, getState) => {
    try{
     const response = await api.put(`/api/healthplans/spsadtguides/${id}`, data)
     cb()
     toast.success("Guia Atualizada com sucesso!");
    }catch(error:any){
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  }

  export const fetchSpsadtguides = (): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingReport, setSpsadtguidesList, setGuideTotal } = covenantSlice.actions;
    const state = getState();
      const { page, limit } = state.covenant;

    const { spSadtGuideFilterArray } = state.covenant;
    const queryParameters = queryStringFromFilterArray(spSadtGuideFilterArray);

    dispatch(setIsFetchingReport(true));
    
    try {
      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;

      const response = await api.get(
        `/api/healthplans/spsadtguides${queryParameters}${pageAndLimit}`
      );
      dispatch(setGuideTotal(response.data.data.total));
      dispatch(setSpsadtguidesList(response.data.data.health_plan_sp_sadt_guides));
      dispatch(setIsFetchingReport(false));
    } catch (error: any) {
      dispatch(setIsFetchingReport(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

  export const fetchSpsadtguidesById = (id:string): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingReport, setSpsadtguides } = covenantSlice.actions;
    const state = getState();
    dispatch(setIsFetchingReport(true));
    try {
      const response = await api.get(
        `/api/healthplans/spsadtguides/${id}`
      );
      dispatch(setSpsadtguides(response.data.data));
      dispatch(setIsFetchingReport(false));
    } catch (error: any) {
      dispatch(setIsFetchingReport(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

  export const deleteMedicalProcedure = (healthPlanSpSadtGuideItemId:string, healthPlanSpSadtGuideId: string): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingReport, setSpsadtguides } = covenantSlice.actions;
    const state = getState();
    dispatch(setIsFetchingReport(true));
    try {
      const response = await api.delete(
        `/api/healthplans/spsadtguides/${healthPlanSpSadtGuideId}/items/${healthPlanSpSadtGuideItemId}`
      );
     toast.success("Excluido com sucesso");
      dispatch(setIsFetchingReport(false));
    } catch (error: any) {
      dispatch(setIsFetchingReport(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

  export const insertMedicalProcedure = (data:IItensguiaspsadt): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingReport, setSpsadtguides } = covenantSlice.actions;
    const state = getState();
    dispatch(setIsFetchingReport(true));
    try {
      // const response = await api.post(
      //   `/api/healthplans/spsadtguides/${idspsadt}/items`
      // );
      dispatch(setIsFetchingReport(false));
    } catch (error: any) {
      dispatch(setIsFetchingReport(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

  export const editMedicalProcedure = (data:IItensguiaspsadt): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingReport, setSpsadtguides } = covenantSlice.actions;
    const state = getState();
    dispatch(setIsFetchingReport(true));
    try {
      // const response = await api.put(
      //   `/api/healthplans/spsadtguides/${id}`, data
      // );
      dispatch(setIsFetchingReport(false));
    } catch (error: any) {
      dispatch(setIsFetchingReport(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };


  //batch
  export const fetchListBatch = (): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingBatch, setListBatch, setGuideTotal } = covenantSlice.actions;
    const state = getState();
      const { page, limit, listBatch, filterBatch } = state.covenant;
    dispatch(setIsFetchingBatch(true));
    try {
      const queryParameters = queryStringFromFilterArray(filterBatch);

      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;
      const response = await api.get(
        `/api/healthplans/guidebatch${queryParameters}${pageAndLimit}`
      );
      dispatch(setGuideTotal(response.data.data.total));
      dispatch(setListBatch(response.data.data.health_plan_guide_batch));
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsFetchingBatch(false));
    }
  };

  export const fetchBatchById = (id:string, cb: Function): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingBatch, setBatch } = covenantSlice.actions;
    const state = getState();
      const { page, limit, listBatch } = state.covenant;
    // dispatch(setIsFetchingBatch(true));
    try {
      const response = await api.get(
        `/api/healthplans/guidebatch/${id}`
      );
      dispatch(setBatch(response.data.data));
      if(cb){
        cb()
      }
      dispatch(setIsFetchingBatch(false));
    } catch (error: any) {
      dispatch(setIsFetchingBatch(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

  export const EditBatchById = (
    id:string, 
    data:IBatch, 
    cb?: Function
    ): AppThunk => async (dispatch, getState) => {
    const { setIsEditingBatch, setBatch } = covenantSlice.actions;
    const state = getState();
      const { page, limit, listBatch } = state.covenant;
    dispatch(setIsEditingBatch(true));
    try {
      const response = await api.put(
        `/api/healthplans/guidebatch/${id}`, data
      );

      dispatch(setBatch(response.data.data));

      toast.success("Lote editado com sucesso", toastOptions);

      if (cb) {
        dispatch(setGuidePage(1));
        dispatch(setGuideLimit(6)); 
        cb();
      }
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsEditingBatch(false));
    }
  };

  export const GetXmlBatchByID = (idloteguias:string,versaoxml:string, lote:string): AppThunk => async (dispatch, getState) => {
    const { setIsDownloadingBatch, setBatch } = covenantSlice.actions;
    const state = getState();
      const { page, limit, listBatch } = state.covenant;
    dispatch(setIsDownloadingBatch(true));
    try {
      toast.info("Download inicado", toastOptions);
      const response = await api.get(
        `/api/healthplans/guidebatch/generatexml?idloteguias=${idloteguias}&versaoxml=${versaoxml}`
      );
      const blob = new Blob([response.data], { type: 'application/xml' });
      saveAs(blob, `${lote}(${versaoxml}).xml`);
      toast.success("Download concluido", toastOptions);
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsDownloadingBatch(false));
    }
  };

  export const updateStatusGuides = (data:any, cb: Function): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingBatch, setBatch } = covenantSlice.actions;
    const state = getState();
      const { page, limit, listBatch } = state.covenant;
    dispatch(setIsFetchingBatch(true));
    try {
      const response = await api.patch(
        `/api/healthplans/guidebatch/status`, data
      );
      dispatch(setBatch(response.data.data));
      if(cb){
        cb()
      }
      dispatch(fetchListBatch())
      toast.success("Status alterado com sucesso", toastOptions);
      dispatch(setIsFetchingBatch(false));
    } catch (error: any) {
      dispatch(setIsFetchingBatch(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

  export const deleteGuideBatch = (data: any, cb: Function): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingBatch, setBatch } = covenantSlice.actions;
    const state = getState();
    // dispatch(setIsFetchingBatch(true));
    try {
      const response = await api.delete(
        `/api/healthplans/guidebatch/removeguides`, {
          data: data,
        } 
      );
      if(cb){
        cb()
      }
      toast.success("Guia removida com sucesso", toastOptions);
      dispatch(setIsFetchingBatch(false));
    } catch (error: any) {
      dispatch(setIsFetchingBatch(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

  export const sendBatchToBillsToReceive = (
    data: {
      accountId: string;
      paymentMethodId: string;
      receiptDate: Date;
      batchGuideId: string;
    }, callback: Function
  ): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingBatch } = covenantSlice.actions;
    const state = getState();
    
    dispatch(setIsFetchingBatch(true));

    try {
      const response = await api.post(
        `/api/healthplans/guidebatch/${data.batchGuideId}/billstoreceive`, {
          accountId: data.accountId,
          paymentMethodId: data.paymentMethodId,
          receiptDate: data.receiptDate
        }
      );

      if (callback) {
        callback();
      }

      toast.success("Envio realizado com sucesso", toastOptions);
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsFetchingBatch(false));
    }
  };

  export const fetchStockitems = (): AppThunk => async (dispatch, getState) => {
    const { setItemStock, setIsFetchingBatch } = covenantSlice.actions;
    const state = getState();
    
    dispatch(setIsFetchingBatch(true));

    try {
      const response = await api.get(
        `/api/stockitems`
      );
      dispatch(setItemStock(response.data.data.stock_items))
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsFetchingBatch(false));
    }
  };

  export const fetchStockitemReferences = ({id}:{id?:string}): AppThunk => async (dispatch, getState) => {
    const { setItemStockReferences, setIsFetchingBatch } = covenantSlice.actions;
    const state = getState();
    dispatch(setIsFetchingBatch(true));
    try {
      const response = await api.get(
        `/api/stockitem/references?${id ? 'iditemestoque='+ id : 'limit=9999'}`
      );
      dispatch(setItemStockReferences(response.data.data.stock_item_references))
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsFetchingBatch(false));
    }
  };

  export const fetchHistory = ({ tableVersionId }:
    {
      tableVersionId?: string,
    }): AppThunk => async (
      dispatch
    ) => {
      const {
        setIsFetchingHistory,
        setTableVersionHistory,
      } = covenantSlice.actions;
      dispatch(setIsFetchingHistory(true));
      try {
        if (tableVersionId) {
          const response = await api.get(
            `/api/healthplan-changelogs?idversaotabela=${tableVersionId}`
          );
          dispatch(setTableVersionHistory(response.data.data.documents))
        }
      } catch (error: any) {
        if (error.response) {
          toast.error(error.response.data?.error?.message, toastOptions);
        } else {
          console.log(error.message);
        }
      } finally {
        dispatch(setIsFetchingHistory(false));
      }
    };
  
  export const createHistory = ({ tableVersionId, descricao, callback }:
    {
      tableVersionId?: string,
      descricao: string,
      callback?: Function,
    }): AppThunk => async (
      dispatch
    ) => {
      const { setIsCreatingHistory } = covenantSlice.actions;
      
      dispatch(setIsCreatingHistory(true));
      try {
        if (tableVersionId) {
          await api.post(`/api/healthplan-changelogs`,
            {
              idversaotabela: tableVersionId,
              descricao: descricao,
            });
            
          toast.success("Atividade adicionada ao histórico", toastOptions);
        }

        if (callback) callback()
      } catch (error: any) {
        if (error.response) {
          toast.error(error.response.data?.error?.message, toastOptions);
        } else {
          console.log(error.message);
        }
      } finally {
        dispatch(setIsCreatingHistory(false));
      }
    };

  export const {
    setSpsadtguides,
    setIsFetchingReport,
    updateFilterStandardTables,
    clearFilterStandardTables,
    setStandardTables,
    setGuideLimit,
    setGuidePage,
    setStandard,
    clearFilterBatch,
    updateFilterBatch,
    setIsFetchingBatch,
    updateConsultationGuideFilter,
    clearConsultationGuideFilter,
    updateSpSadtGuideFilter,
    clearSpSadtGuideFilter,
    setSpsadtguidesList,
    setConsultationGuide,
    setListBatch,
    setIsEditingBatch
  } = covenantSlice.actions;
  
  export default covenantSlice.reducer;
  