import { Clinica } from "models/ProfessionalReport";

export interface HealthPlan {
  idconvenio: string;
  nome: string;
  razaosocial: string;
  registroans: string;
  logourl: string;
  cnpj: string;
  cep: string;
  estado: string;
  cidade: string;
  bairro: string;
  endereco: string;
  numero: string;
  complemento: string;
  responsavel: string;
  numerovidas: number;
  ativo: boolean;
  descontos: boolean;
  faturamentolote: boolean;
  datacriacao?: Date;
  dataatualizacao?: Date;
  agendamentoonline?: boolean;
  iniciovigencia?: Date;
  diasretorno: number;
  diasenvioguias: number;
  diasrecursoglosas: number;
  diasrecebimento: number;
  envioguiaseletronicas: boolean;
  envioguiasfisicas: boolean;
  emissaonf: INVOICE_ISSUANCE;
  nomecontratadoexecutante: string;
  codigocontratadoexecutante: string;
  codigooperadora: string;
  cnes: string;
  versaotissenvio: string;
  versaotissretorno: string;
}

export interface HealthPlanReducerState {
  healthPlans: HealthPlan[];
  allHealthPlans: HealthPlan[];
  healthPlanRestrictions: HealthPlanRestrictions[];
  healthPlan: HealthPlan;
  isFetchingHealthPlans: boolean;
  isFetchingHealthPlan: boolean;
  isUpdatingHealthPlan: boolean;
  isCreatingHealthPlan: boolean;
  listPage: number;
  listLimit: number;
  listTotalSize: number;
  healthPlanFilterId: string;
  healthPlansByServiceItem: HealthPlan[];
  healthPlanTissSetup: HealthPlanTissSetup[];
  healthPlanGuideSetup: HealthPlanGuideSetup[];
}

export interface HealthPlanTissSetup {
  idconfiguracaotiss: string;
  idclinica: string;
  idconvenio: string;
  nomecontratadoexecutante: string;
  codigocontratadoexecutante: string;
  codigooperadora: string;
  cnes: string;
  tipoguia: HEALTH_PLAN_GUIDE_TYPE;
  clinica: Clinica;
  ativo: boolean;
}

export interface HealthPlanGuideSetup extends IGuideSetup {
  idconfiguracaoguia: string;
  idconvenio: string;
}

export interface HealthPlanFetchResponse {
  data: {
    data: {
      health_plans: HealthPlan[];
      page: number;
      total: number;
    };
    success: boolean;
  };
}

export interface HealthPlanRestrictionFetchResponse {
  data: {
    data: {
      health_plan_restrictions: HealthPlanRestrictions[];
      page: number;
      total: number;
    };
    success: boolean;
  };
}

export interface ICreateHealthPlan {
  idconvenio?: string;
  nome: string;
  registroans: string;
  razaosocial: string;
  logourl: string;
  cnpj: string;
  cep: string;
  estado: string;
  cidade: string;
  bairro: string;
  endereco: string;
  numero: string;
  complemento: string;
  responsavel: string;
  ativo: boolean;
  descontos: boolean;
  faturamentolote: boolean;
  numerovidas: number;
  agendamentoonline?: boolean;
  iniciovigencia: Date;
  diasretorno: number;
  diasenvioguias: number;
  diasrecursoglosas: number;
  diasrecebimento: number;
  envioguiaseletronicas: boolean;
  envioguiasfisicas: boolean;
  emissaonf: INVOICE_ISSUANCE;
}

export enum INVOICE_ISSUANCE {
  SEND_BILLING = "SEND_BILLING",
  PAYMENT_RELEASE = "PAYMENT_RELEASE",
  AFTER_PAYMENT = "AFTER_PAYMENT"
}

export enum HEALTH_PLAN_GUIDE_TYPE {
  SP_SADT = "sp_sadt",
  CONSULTATION = "consulta",
  ALL = "todas",
}

export interface IGuideSetup {
  idconvenio: string;
  limitelote: number;
  atendimentorn: boolean | null;
  indicacaoacidente: string | null;
  carateratendimento: string | null;
  regimeatendimento: string | null;
  tipoatendimento: string | null;
  tipoconsulta: string | null;
  tipoguia: HEALTH_PLAN_GUIDE_TYPE;
}

export interface ICreateTissSetup {
  idconvenio: string;
  idclinica?: string | null;
  nomecontratadoexecutante: string;
  codigocontratadoexecutante: string;
  codigooperadora: string;
  cnes: string;
  tipoguia: HEALTH_PLAN_GUIDE_TYPE;
}

export interface ITissVersion {
  versaotissenvio: string;
  versaotissretorno: string;
}

export interface HealthPlanRestrictions {
  idrestricao: string;
  idconvenio: string;
  convenio: HealthPlan;
  idprofissional: string;
  limiteconvenios: number;
  datacriacao?: Date;
  dataatualizacao?: Date;
}

export interface ICreateHealthPlanRestriction {
  idconvenio: string;
  idprofissional: string;
  limiteconvenios: number;
}
