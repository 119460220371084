import { FullWidthContainer } from "components/layout/base";
import jwt_decode from "jwt-decode";
import { AppointmentWebSetup } from "pages/AppointmentWebSetup";
import PreAppointmentReleaseMedicalRecords from "pages/PreAppointmentReleaseMedicalRecords";
import RankingCollaborators from "pages/Reports/RankingCollaborators";
import HealthPlanRanking from "pages/Reports/RankingHealthPlan";
import React, { lazy, useEffect, useState } from "react";
import { Route, useHistory } from "react-router-dom";
import { lazyWithRetry } from "utils/lazyWithRetry";

import { useSelector } from "react-redux";
import { rootState } from "store";
import { isString, isArray } from "lodash";

// * Componentes
const MenuAppBar = lazyWithRetry(() => import("components/MenuAppBar"));
const MenuDrawer = lazyWithRetry(() => import("components/MenuDrawer"));
const ContentContainer = lazyWithRetry(
  () => import("components/ContentContainer")
);

// * Contas bancárias
const ListBankAccounts = lazyWithRetry(() => import("pages/BankAccounts"));
const BankAccountsForm = lazyWithRetry(() => import("pages/BankAccounts/Form"));

// * Máquinas
const ListMachines = lazy(() => import("pages/Financeiro/PaymentMachines"));
const MachinesForm = lazy(() => import("pages/Financeiro/PaymentMachines/Form"));

// * Formas de pagamento
const ListPaymentMethods = lazyWithRetry(
  () => import("pages/Financeiro/PaymentMethods")
);
const PaymentMethodsForm = lazyWithRetry(
  () => import("pages/Financeiro/PaymentMethods/Form")
);

// * Caixas
const ListCashDesks = lazyWithRetry(() => import("pages/CashDesks"));
const CashDesksForm = lazyWithRetry(() => import("pages/CashDesks/Form"));

// * Transações
const ListTransactions = lazyWithRetry(() => import("pages/Transactions"));
const TransactionsForm = lazyWithRetry(() => import("pages/Transactions/Form"));

//  * Healtplan
const ListHealthPlan = lazyWithRetry(() => import("pages/HealthPlan/List"));
const HealthPlanForm = lazyWithRetry(() => import("pages/HealthPlan/Form"));
const HealthPlanCategory = lazyWithRetry(
  () => import("pages/HealthPlanCategory/List")
);
const HealthPlanCategoryForm = lazyWithRetry(
  () => import("pages/HealthPlanCategory/Form")
);

const ListServicesCategories = lazyWithRetry(
  () => import("pages/services/categories/List")
);
const ServicesCategoriesForm = lazyWithRetry(
  () => import("pages/services/categories/Form")
);
const ListServicesGroups = lazyWithRetry(
  () => import("pages/services/groups/List")
);
const ServicesGroupsForm = lazyWithRetry(
  () => import("pages/services/groups/Form")
);

const ListPatients = lazyWithRetry(() => import("pages/patients/List"));
const PatientReport = lazyWithRetry(() => import("pages/patients/Report"));
const PatientsForm = lazyWithRetry(() => import("pages/patients/Form"));

const ListUsers = lazyWithRetry(() => import("pages/users/List"));
// const UsersForm = lazyWithRetry(() => import("pages/users/Form"));
const UserEdit = lazyWithRetry(() => import("pages/users"));

const ListLabs = lazyWithRetry(() => import("pages/Labs/List"));
// const LabsForm = lazyWithRetry(() => import("pages/Labs/Form"));
const LabsEdit = lazyWithRetry(() => import("pages/Labs"));
const ListLabsExams = lazyWithRetry(() => import("pages/Clinics/Exams/List"));
const LabsExamsForm = lazyWithRetry(() => import("pages/Clinics/Exams/Form"));
const ListLabsOffices = lazyWithRetry(() => import("pages/Clinics/Offices"));

const ListClinics = lazyWithRetry(() => import("pages/Clinics/List"));
const ClinicsForm = lazyWithRetry(() => import("pages/Clinics/Form"));
const ClinicNfseForm = lazyWithRetry(() => import("pages/Clinics/Nfse/Form"));

// const Dashboard = lazyWithRetry(() => import("pages/Dashboard/index01"));
const Dashboard = lazyWithRetry(() => import("pages/Dashboard/dashboard"));

const ListDepartments = lazyWithRetry(() => import("pages/Departments/List"));

const Roles = lazyWithRetry(() => import("pages/Roles"));

const ProtectedRoute = lazyWithRetry(
  () => import("components/ProtectedRoute/index")
);
const SchedulePage = lazyWithRetry(() => import("pages/SchedulePage"));
const ClinicsSchedule = lazyWithRetry(
  () => import("pages/Clinics/OfficesSchedule")
);

// * Service packages
const ServicePackagesForm = lazyWithRetry(
  () => import("pages/ServicePackages/Form")
);
const ServicePackages = lazyWithRetry(() => import("pages/ServicePackages"));
const ServicePackageServiceItems = lazyWithRetry(
  () => import("pages/ServicePackages/ServicePacakgeServiceItems")
);
const AddServiceItemToServicePacakge = lazyWithRetry(
  () =>
    import(
      "pages/ServicePackages/ServicePacakgeServiceItems/AddServiceItemToServicePackage"
    )
);

//Reports
const ReceivedPayments = lazyWithRetry(
  () => import("pages/Reports/ReceivedPayments")
);
const InvoiceReport = lazyWithRetry(
  () => import("pages/Reports/InvoiceReport")
);
const RankingServices = lazyWithRetry(
  () => import("pages/Reports/RankingServices")
);
const ServicesResults = lazyWithRetry(
  () => import("pages/Reports/ServicesResults")
);
const HealthPlansServiceResults = lazyWithRetry(
  () => import("pages/Reports/HealthPlansServiceResults")
);
const PriceList = lazyWithRetry(() => import("pages/Reports/PriceList"));
const AppointmentsByStatus = lazyWithRetry(
  () => import("pages/Reports/AppointmentsByStatus")
);
const AppointmentsMap = lazyWithRetry(
  () => import("pages/Reports/AppointmentsMap")
);
const CustomerQuotesReports = lazyWithRetry(
  () => import("pages/Reports/CustomerQuotes")
);
const ProfessionalPerformance = lazyWithRetry(
  () => import("pages/Reports/ProfessionalPerformance")
);
const SchedulesOfDay = lazyWithRetry(
  () => import("pages/Reports/SchedulesOfDay")
);
const MedicinePrescriptions = lazyWithRetry(
  () => import("pages/Reports/MedicinePrescriptions")
);
const CombosReports = lazyWithRetry(() => import("pages/Reports/Combos"));
const ProfessionalsReports = lazyWithRetry(
  () => import("pages/Reports/ProfessionalsReports")
);
const laboratoryProduction = lazyWithRetry(
  () => import("pages/Reports/LaboratoryProduction")
);
const HealthPlanProduction = lazyWithRetry(
  () => import("pages/Reports/HealthPlanProduction")
);
const ServiceOrders = lazyWithRetry(() => import("pages/ServiceOrders"));
const CustomersQuote = lazyWithRetry(() => import("pages/CustomersQuote"));
const CustomersQuoteForm = lazyWithRetry(
  () => import("pages/CustomersQuote/Form")
);
const RoleUsers = lazyWithRetry(
  () => import("pages/Roles/components/RoleUsers")
);
const DepartmentsForm = lazyWithRetry(() => import("pages/Departments/Form"));
const ServiceOrdersForm = lazyWithRetry(
  () => import("pages/ServiceOrders/form")
);
const Supplies = lazyWithRetry(() => import("pages/Supplies"));
const SuppliesForm = lazyWithRetry(() => import("pages/Supplies/Form"));
const ListTutorials = lazyWithRetry(() => import("pages/Tutorials"));
const TutorialsForm = lazyWithRetry(() => import("pages/Tutorials/Form"));
const ClientesEFornecedores = lazyWithRetry(
  () => import("pages/Financeiro/ClientesEFornecedores/List")
);
const ClientesEFornecedoresForm = lazyWithRetry(
  () => import("pages/Financeiro/ClientesEFornecedores/Form")
);

const FluxoDeCaixa = lazyWithRetry(
  () => import("pages/Financeiro/FluxoDeCaixa/List")
);
const FluxoDeCaixaForm = lazyWithRetry(
  () => import("pages/Financeiro/FluxoDeCaixa/Form")
);
const Repasses = lazyWithRetry(() => import("pages/ProfessionalsReports"));
const ProfessionalProductionReports = lazyWithRetry(() => import("pages/ProfessionalsProductionReports"));
const RepassesConvenios = lazyWithRetry(() => import("pages/HealthPlanReports"));
const ContasAPagar = lazyWithRetry(() => import("pages/Financeiro/ContasAPagar/List"));
const AntecipacaoDeCartoes = lazyWithRetry(() => import("pages/Financeiro/AntecipacaoDeCartoes/List"));
const ContasAPagarForm = lazyWithRetry(
  () => import("pages/Financeiro/ContasAPagar/Form")
);
const ContasAReceber = lazyWithRetry(
  () => import("pages/Financeiro/ContasAReceber/List")
);
const ContasAReceberForm = lazyWithRetry(
  () => import("pages/Financeiro/ContasAReceber/Form")
);
const ResultadosDeServicos = lazyWithRetry(
  () => import("pages/Financeiro/ResultadosDeServicos")
);
const MedicalRecords = lazyWithRetry(() => import("pages/MedicalRecord"));
const PatientsAttended = lazyWithRetry(() => import("pages/PatientsAttended"));
const ReleaseMedicalRecords = lazyWithRetry(
  () => import("pages/ReleaseMedicalRecords")
);
const PatientMedicalRecord = lazyWithRetry(
  () => import("pages/MedicalRecord/components/PatientMedicalRecord")
);
const SalaDeEsperaProfissional = lazyWithRetry(
  () => import("pages/SalaDeEspera")
);
const SalaDeEsperaSemAgenda = lazyWithRetry(
  () => import("pages/SalaDeEsperaSemAgenda")
);
const SalaDeEspera = lazyWithRetry(() => import("pages/SalaDeEsperaAdmin"));
const AgendaProfissional = lazyWithRetry(
  () => import("pages/ProfessionalSchedule")
);
const Blocks = lazyWithRetry(() => import("pages/Blocks"));
const BlocksForm = lazyWithRetry(() => import("pages/Blocks/form"));
const ServiceItemsForm = lazyWithRetry(() => import("pages/ServiceItems/Form"));
const ListServiceItems = lazyWithRetry(() => import("pages/ServiceItems"));
const Offices = lazyWithRetry(() => import("pages/Offices"));
const OfficesForm = lazyWithRetry(() => import("pages/Offices/Form"));
const ServiceProfessionalsByServiceItemList = lazyWithRetry(
  () => import("pages/ServiceItems/ServiceProfessionalsByServiceItemList")
);
const ServiceProfessionalsByServiceItemForm = lazyWithRetry(
  () => import("pages/ServiceItems/ServiceProfessionalsByServiceItemForm")
);
// * Home
const HomePage = lazyWithRetry(() => import("pages/HomePage"));
const ProfessionalFavorites = lazyWithRetry(
  () => import("pages/ProfessionalFavorites")
);
const ExamsKit = lazyWithRetry(() => import("pages/ExamsKit/List"));
const ExamsKitForm = lazyWithRetry(() => import("pages/ExamsKit/Form"));
const MedicalKits = lazyWithRetry(() => import("pages/MedicalKits/List"));
const MedicalKitsForm = lazyWithRetry(() => import("pages/MedicalKits/Form"));

// Custom Medical Forms
const CustomMedicalForms = lazyWithRetry(
  () => import("pages/CustomMedicalForm/MedicalReport")
);
const CustomExamResult = lazyWithRetry(
  () => import("pages/CustomMedicalForm/ExamResult")
);

// counter
const CounterList = lazyWithRetry(() => import("pages/Counter/List"));
const CounterForm = lazyWithRetry(() => import("pages/Counter/Form"));

const WindowSetList = lazyWithRetry(() => import("pages/WindowSet/List"));
const WindowSetsForm = lazyWithRetry(() => import("pages/WindowSet/Form"));

const CounterTypeServiceList = lazyWithRetry(
  () => import("pages/CounterTypeService/List")
);
const CounterTypeServiceForm = lazyWithRetry(
  () => import("pages/CounterTypeService/Form")
);
const AttendanceGroupList = lazyWithRetry(
  () => import("pages/Attendancegroup/List")
);
const AttendanceGroupForm = lazyWithRetry(
  () => import("pages/Attendancegroup/Form")
);
const CallPanelList = lazyWithRetry(() => import("pages/CallPanel/List"));
const CallPanelForm = lazyWithRetry(() => import("pages/CallPanel/Form"));
const TotemList = lazyWithRetry(() => import("pages/Totem/List"));
const TotemForm = lazyWithRetry(() => import("pages/Totem/Form"));

// Price list
const ServiceItemsPrices = lazy(() => import("pages/PriceList/ServiceItems"));
const HealthPlanPriceList = lazy(
  () => import("pages/PriceList/HealthPlanItems")
);

// convênio
const CovenantStandardTable = lazy(
  () => import("pages/Covenant/TabelasPadrao/index")
);
const QueryTabsManually = lazy(
  () => import("pages/Covenant/QueryTabsManually/index")
);
const QueryTabsManuallyForm = lazy(
  () => import("pages/Covenant/QueryTabsManually/Form/index")
);
const SpSadt = lazy(
  () => import("pages/Covenant/SpSadt")
);
const SpSadtForm = lazy(
  () => import("pages/Covenant/SpSadt/Form/index")
);
const BatchGuides = lazy(() => import("pages/Covenant/BatchGuides"))
const PaymentWriteOffs = lazy(() => import("pages/Covenant/PaymentWriteOffs"))

//estoque
const StockManufacturers = lazy(
  () => import("pages/Stock/Manufacturers/index")
);
const StockManufacturersForm = lazy(
  () => import("pages/Stock/Manufacturers/Form/index")
);
//local
const StockLocal = lazy(
  () => import("pages/Stock/Local/index")
);
const StockLocalForm = lazy(
  () => import("pages/Stock/Local/Form/index")
);
// categoria
const StockCategory = lazy(
  () => import("pages/Stock/Category/index")
);
const StockCategoryForm = lazy(
  () => import("pages/Stock/Category/Form/index")
);
// itens 
const StockItens = lazy(
  () => import("pages/Stock/Itens/index")
);
const StockItensForm = lazy(
  () => import("pages/Stock/Itens/Form/index")
);

// import Attendances from "pages/Attendances";
const AttendancesList = lazyWithRetry(() => import("pages/Attendances"));
const HelpCenter = lazyWithRetry(() => import("pages/HelpCenter"));
const HelpCenterSubpage = lazyWithRetry(() => import("pages/HelpCenter/Component/SubPage/index"));

const NotFoundRoute = () => {
  return (
    <FullWidthContainer>
      <h1>
        Parece que você não tem permissão para acessar essa página...
        <br />
        Tente sair e logar novamente ou entre em contato com um admnistrador
      </h1>
    </FullWidthContainer>
  );
};

const Routes = () => {
  const [roles, setRoles] = useState<string[]>([]);
  const [checkedUser, setCheckedUser] = useState(false);
  const [tokenDecoded, setTokenDecoded] = useState<any>();
  const history = useHistory();
  const { signedInUser } = useSelector((state: rootState) => state.users);
  useEffect(() => {
    const token = localStorage.getItem("@token");
    if (token) {
      try {
        const decodedToken: any = jwt_decode(token);
        let role_names = decodedToken.cargo.nome as string[];
        if (!isArray(role_names)) role_names = [role_names] 
        role_names.map(role_name => {
          role_name = role_name.toLowerCase();
        });

        setRoles(role_names);
        setTokenDecoded(decodedToken);
        setCheckedUser(true);
      } catch (error) {
        console.log(error);
        history.replace("/auth/signin");
      }
    } else {
      history.replace("/auth/signin");
    }
  }, [history]);
  return checkedUser ? (
    <>
    <div style={{ display: "flex", height: "calc(100% - 70px)" }}>
      <MenuAppBar />
      <MenuDrawer userRoles={roles} />
      <ContentContainer>
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/"
          // allowedRoles={["admin", "Admin"]}
          render={HomePage}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/dashboard"
          allowedRoles={
            tokenDecoded.cargo.dashboard
              ? [tokenDecoded.cargo.nome]
              : ["Nenhum-Cargo"]
          }
          render={Dashboard}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/health-plan"
          allowedRoles={
            tokenDecoded.cargo.convenio
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={ListHealthPlan}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/health-plan/form/:healthPlanId?"
          allowedRoles={
            tokenDecoded.cargo.convenio
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={HealthPlanForm}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/planos-convenio"
          allowedRoles={
            tokenDecoded.cargo.convenio
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={HealthPlanCategory}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/planos-convenio/form/:healthPlanId?"
          allowedRoles={
            tokenDecoded.cargo.convenio
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={HealthPlanCategoryForm}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/convenio/tabelas-padrao"
          allowedRoles={
            tokenDecoded.cargo.convenio
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={CovenantStandardTable}
        />        
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/convenio/guia-consulta"
          allowedRoles={
            tokenDecoded.cargo.convenio
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={QueryTabsManually}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/convenio/guia-consulta/form"
          allowedRoles={
            tokenDecoded.cargo.convenio
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={QueryTabsManuallyForm}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/convenio/guia-sp-sadt"
          allowedRoles={
            tokenDecoded.cargo.convenio
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={SpSadt}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/convenio/guia-sp-sadt/form/:spSadtGuideId?"
          allowedRoles={
            tokenDecoded.cargo.convenio
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={SpSadtForm}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/convenio/batch-guides"
          allowedRoles={
            tokenDecoded.cargo.convenio
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={BatchGuides}
        />

        <ProtectedRoute
          userRoles={roles}
          exact
          path="/convenio/baixa-pagamentos"
          allowedRoles={
            tokenDecoded.cargo.convenio
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={PaymentWriteOffs}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/services/categories"
          allowedRoles={
            tokenDecoded.cargo.itemservico
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={ListServicesCategories}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/services/categories/form/:serviceCategoryId?"
          allowedRoles={
            tokenDecoded.cargo.itemservico
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={ServicesCategoriesForm}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/services/groups"
          allowedRoles={
            tokenDecoded.cargo.itemservico
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={ListServicesGroups}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/services/groups/form/:serviceGroupId?"
          allowedRoles={
            tokenDecoded.cargo.itemservico
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={ServicesGroupsForm}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/patients/list"
          allowedRoles={
            tokenDecoded.cargo.pacientes
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={ListPatients}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/patients/report"
          allowedRoles={
            tokenDecoded.cargo.pacientes
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={PatientReport}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/patients/medical-records"
          allowedRoles={
            tokenDecoded.cargo.fichapaciente
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={MedicalRecords}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/patients/sala-de-espera-exames"
          allowedRoles={
            tokenDecoded.cargo.saladeesperadeexames
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={PatientsAttended}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/report/laboratory-production"
          allowedRoles={
            tokenDecoded.cargo.relatorioproducaolab
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={laboratoryProduction}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/report/healthplan-production"
          allowedRoles={
            tokenDecoded.cargo.relatorioproducaolab
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={HealthPlanProduction}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/patients/baixa-atendimentos-medicos"
          allowedRoles={
            tokenDecoded.cargo.baixaatendimentosmedicos
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={ReleaseMedicalRecords}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/patients/pre-consulta"
          allowedRoles={
            tokenDecoded.cargo.preconsulta
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={PreAppointmentReleaseMedicalRecords}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/patients/medical-records/:patientId/:idMedicalRecord"
          allowedRoles={
            tokenDecoded.cargo.fichapaciente
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={PatientMedicalRecord}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/patients/form/:patientId?"
          allowedRoles={
            tokenDecoded.cargo.pacientes
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={PatientsForm}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/users"
          allowedRoles={
            tokenDecoded.cargo.usuario
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={ListUsers}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/users/link-role/:roleId"
          allowedRoles={
            tokenDecoded.cargo.usuario
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={ListUsers}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/users/form/:userId?"
          allowedRoles={
            tokenDecoded.cargo.usuario
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={UserEdit}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/labs"
          allowedRoles={
            tokenDecoded.cargo.parceirosexternos
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={ListLabs}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/labs/form/:labId?"
          allowedRoles={
            tokenDecoded.cargo.parceirosexternos
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador", "login unificado"]
          }
          render={LabsEdit}
        />
        {/* <ProtectedRoute
          userRoles={roles}
          exact
          path="/labs/form/:labId?"
          allowedRoles={tokenDecoded.cargo.parceirosexternos ? [tokenDecoded.cargo.nome] : ["Admin", "admin", "administrador", "Administrador"]}
          render={LabsForm}
        /> */}
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/clinics/:labId/exams"
            allowedRoles={
              tokenDecoded.cargo.clinica
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={ListLabsExams}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/clinics/:labId/exams/form"
            allowedRoles={
              tokenDecoded.cargo.clinica
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={LabsExamsForm}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/clinics/:labId/offices"
            allowedRoles={
              tokenDecoded.cargo.clinica
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={ListLabsOffices}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/clinics/:labId/schedule"
            allowedRoles={
              tokenDecoded.cargo.clinica
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={ClinicsSchedule}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/clinics"
            allowedRoles={
              tokenDecoded.cargo.clinica
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={ListClinics}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/clinics/:clinicId/departments/:departmentId/blocks"
            allowedRoles={
              tokenDecoded.cargo.clinica
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={Blocks}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/clinics/:clinicId/departments/:departmentId/blocks/form/:blockId?"
            allowedRoles={
              tokenDecoded.cargo.clinica
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={BlocksForm}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/clinics/:clinicId/departments/:departmentId/blocks/:blockId/offices"
            allowedRoles={
              tokenDecoded.cargo.clinica
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={Offices}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/clinics/:clinicId/departments/:departmentId/blocks/:blockId/offices/form/:officeId?"
            allowedRoles={
              tokenDecoded.cargo.clinica
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={OfficesForm}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/clinics/form/:clinicId?"
            allowedRoles={
              tokenDecoded.cargo.clinica
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={ClinicsForm}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/clinics/:clinicId/nfse/:clinicNfseId?"
            allowedRoles={
              tokenDecoded.cargo.clinica
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={ClinicNfseForm}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/clinics/:clinicId/departments"
            allowedRoles={
              tokenDecoded.cargo.clinica
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={ListDepartments}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/clinics/:clinicId/departments/form/:departmentId?"
            allowedRoles={
              tokenDecoded.cargo.clinica
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={DepartmentsForm}
          />
          <ProtectedRoute userRoles={roles} exact path="/roles" render={Roles} />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/roles/:roleId/users"
            allowedRoles={
              tokenDecoded.cargo.cargo
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={RoleUsers}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/schedule"
            allowedRoles={
              tokenDecoded.cargo.agendamentos
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={SchedulePage}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/service-packages"
            allowedRoles={
              tokenDecoded.cargo.combo
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={ServicePackages}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/service-packages/form/:servicePackageId?"
            allowedRoles={
              tokenDecoded.cargo.combo
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={ServicePackagesForm}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/service-packages/:idcombo/service-items"
            allowedRoles={
              tokenDecoded.cargo.combo
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={ServicePackageServiceItems}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/service-packages/:idcombo/service-items/add"
            allowedRoles={
              tokenDecoded.cargo.combo
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={AddServiceItemToServicePacakge}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/service-orders"
            allowedRoles={
              tokenDecoded.cargo.ordemservico
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={ServiceOrders}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/service-orders/form/:serviceOrderId?"
            allowedRoles={
              tokenDecoded.cargo.ordemservico
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={ServiceOrdersForm}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/supplies"
            render={Supplies}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/supplies/form/:supplyId?"
            render={SuppliesForm}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/tutorials"
            allowedRoles={
              tokenDecoded.cargo.tutorial
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={ListTutorials}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/tutorials/form/:tutorialId?"
            allowedRoles={
              tokenDecoded.cargo.tutorial
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={TutorialsForm}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/customers-quote"
            allowedRoles={
              tokenDecoded.cargo.orcamento
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={CustomersQuote}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/customers-quote/form"
            allowedRoles={
              tokenDecoded.cargo.orcamento
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={CustomersQuoteForm}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/customers-quote/form/:customerQuoteId"
            allowedRoles={
              tokenDecoded.cargo.orcamento
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={CustomersQuoteForm}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/financeiro/clientes-e-fornecedores"
            allowedRoles={
              tokenDecoded.cargo.operarcaixa
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={ClientesEFornecedores}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/financeiro/clientes-e-fornecedores/form"
            allowedRoles={
              tokenDecoded.cargo.operarcaixa
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={ClientesEFornecedoresForm}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/financeiro/clientes-e-fornecedores/form/:clienteFornecedorId"
            allowedRoles={tokenDecoded.cargo.operarcaixa ? [tokenDecoded.cargo.nome] : ["Admin", "admin", "administrador", "Administrador"]}
            render={ClientesEFornecedoresForm}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/financeiro/fluxo-de-caixa"
            allowedRoles={
              tokenDecoded.cargo.operarcaixa
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={FluxoDeCaixa}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/financeiro/fluxo-de-caixa/form"
            allowedRoles={
              tokenDecoded.cargo.operarcaixa
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={FluxoDeCaixaForm}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/financeiro/repasses"
            allowedRoles={
              tokenDecoded.cargo.repasses
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={Repasses}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/financeiro/relatorio-producao"
            allowedRoles={[
              "Admin",
              "admin",
              "administrador",
              "Administrador",
              "profissional",
              "login unificado"
            ]}
            render={ProfessionalProductionReports}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/convenio/faturamento"
            allowedRoles={
              tokenDecoded.cargo.repasses
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={RepassesConvenios}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/financeiro/contas-a-pagar"
            allowedRoles={
              tokenDecoded.cargo.contasapagar
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={ContasAPagar}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/financeiro/contas-a-pagar/form"
            allowedRoles={
              tokenDecoded.cargo.contasapagar
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={ContasAPagarForm}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/financeiro/contas-a-pagar/form/:contasAPagarId"
            allowedRoles={tokenDecoded.cargo.contasapagar ? [tokenDecoded.cargo.nome] : ["Admin", "admin", "administrador", "Administrador"]}
            render={ContasAPagarForm}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/financeiro/contas-a-receber"
            allowedRoles={
              tokenDecoded.cargo.contasareceber
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={ContasAReceber}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/financeiro/contas-a-receber/form"
            allowedRoles={
              tokenDecoded.cargo.contasareceber
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={ContasAReceberForm}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/financeiro/contas-a-receber/form/:contasAReceberId"
            allowedRoles={tokenDecoded.cargo.contasareceber ? [tokenDecoded.cargo.nome] : ["Admin", "admin", "administrador", "Administrador"]}
            render={ContasAReceberForm}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/financeiro/antecipacao-de-cartoes"
            allowedRoles={
              tokenDecoded.cargo.contasareceber
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={AntecipacaoDeCartoes}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/financeiro/resultados-servicos"
            allowedRoles={
              tokenDecoded.cargo.resultadosdeservico
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={ResultadosDeServicos}
          />
          <Route userRoles={[]} exact path="/500" render={NotFoundRoute} />
          <ProtectedRoute
            allowedRoles={
              tokenDecoded.cargo.cargodeprofissional || tokenDecoded.cargo.cargodediagnostico
              ? [tokenDecoded.cargo.nome] 
              : [
                  "profissional", 
                  "Admin", 
                  "admin", 
                  "administrador", 
                  "Administrador", 
                  "diagnostico", 
                  "login unificado"
                ]
              }
            userRoles={roles}
            exact
            path="/sala-de-espera-profissional"
            render={SalaDeEsperaProfissional}
          />
          <ProtectedRoute
            allowedRoles={
              tokenDecoded.cargo.cargodeprofissional || 
                tokenDecoded.cargo.cargodediagnostico || 
                  tokenDecoded.cargo.cargodelaboratorio
              ? [tokenDecoded.cargo.nome] 
              : [
                "profissional",
                "Admin",
                "admin",
                "administrador",
                "Administrador",
                "diagnostico",
                "laboratorio",
                "Laboratorio",
                "login unificado"
              ]}
            userRoles={roles}
            exact
            path="/sala-de-espera-sem-agenda"
            render={SalaDeEsperaSemAgenda}
          />
          <ProtectedRoute
            allowedRoles={
              tokenDecoded.cargo.cargodeprofissional || tokenDecoded.cargo.cargodediagnostico
              ? [tokenDecoded.cargo.nome] 
              : [
                  "profissional", 
                  "Admin", 
                  "admin", 
                  "administrador", 
                  "Administrador", 
                  "diagnostico"
                ]
              }
            userRoles={roles}
            exact
            path="/sala-de-espera"
            render={SalaDeEspera}
          />
          <ProtectedRoute
            allowedRoles={[
              "Admin",
              "admin",
              "administrador",
              "Administrador",
              "profissional",
              "login unificado"
            ]}
            userRoles={roles}
            exact
            path="/agenda"
            render={AgendaProfissional}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/service-items"
            allowedRoles={
              tokenDecoded.cargo.itemservico
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={ListServiceItems}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/service-items/:serviceItemId/service-professionals"
            allowedRoles={
              tokenDecoded.cargo.itemservico
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={ServiceProfessionalsByServiceItemList}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/service-items/:serviceItemId/service-professionals/form/:serviceProfessionalId?"
            allowedRoles={
              tokenDecoded.cargo.itemservico
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={ServiceProfessionalsByServiceItemForm}
          />
          {/* <ProtectedRoute
          userRoles={roles}
          exact
          path="/service-items/form"
          render={ServiceItemsForm}
        /> */}
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/service-items/form/:serviceItemId?"
            allowedRoles={
              tokenDecoded.cargo.itemservico
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={ServiceItemsForm}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/bank-accounts"
            allowedRoles={
              tokenDecoded.cargo.conta
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={ListBankAccounts}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/bank-accounts/form/:bankAccountId?"
            allowedRoles={
              tokenDecoded.cargo.conta
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={BankAccountsForm}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/financeiro/maquinas"
            allowedRoles={
              tokenDecoded.cargo.conta
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={ListMachines}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/financeiro/maquinas/form/:paymentMachineId?"
            allowedRoles={
              tokenDecoded.cargo.conta
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={MachinesForm}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/financeiro/caixas"
            allowedRoles={
              tokenDecoded.cargo.caixa
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={ListCashDesks}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/financeiro/caixas/form/:cashDeskId?"
            allowedRoles={
              tokenDecoded.cargo.caixa
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={CashDesksForm}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/financeiro/transacoes"
            allowedRoles={
              tokenDecoded.cargo.transacoes
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={ListTransactions}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/financeiro/transacoes/form/:transactionId?"
            allowedRoles={
              tokenDecoded.cargo.transacoes
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={TransactionsForm}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/financeiro/metodos-pagamento"
            allowedRoles={
              tokenDecoded.cargo.formapagamento
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={ListPaymentMethods}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/financeiro/metodos-pagamento/form/:paymentMethodId?"
            allowedRoles={
              tokenDecoded.cargo.formapagamento
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={PaymentMethodsForm}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/reports/received-payments"
            allowedRoles={
              tokenDecoded.cargo.relatoriopagamentosrecebidos
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={ReceivedPayments}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/reports/invoice-report"
            allowedRoles={
              tokenDecoded.cargo.relatoriopagamentosrecebidos
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={InvoiceReport}
            />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/reports/ranking-services"
            allowedRoles={
              tokenDecoded.cargo.relatoriorankdeservicos
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={RankingServices}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/reports/ranking-health-plan"
            allowedRoles={
              tokenDecoded.cargo.relatoriorankdeservicos
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={HealthPlanRanking}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/reports/ranking-collaborators"
            allowedRoles={
              tokenDecoded.cargo.relatorioperformancecolaboradores
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={RankingCollaborators}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/reports/services-results"
            allowedRoles={
              tokenDecoded.cargo.relatorioresultadosdeservico
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={ServicesResults}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/reports/health-plans-service-results"
            allowedRoles={
              tokenDecoded.cargo.relatorioconvenios
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={HealthPlansServiceResults}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/reports/price-list"
            allowedRoles={
              tokenDecoded.cargo.relatoriolistadeprecos
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={PriceList}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/reports/professional-performance"
            allowedRoles={
              tokenDecoded.cargo.conta
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={ProfessionalPerformance}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/reports/appointments-by-status"
            allowedRoles={
              tokenDecoded.cargo.relatorioagendamentosporstatus
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={AppointmentsByStatus}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/reports/appointments-map"
            allowedRoles={
              tokenDecoded.cargo.relatorioagendamentosporstatus
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={AppointmentsMap}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/reports/customer-quotes"
            allowedRoles={
              tokenDecoded.cargo.relatorioorcamentos
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={CustomerQuotesReports}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/reports/professionals"
            allowedRoles={
              tokenDecoded.cargo.relatorioprofissional
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={ProfessionalsReports}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/reports/schedules"
            allowedRoles={
              tokenDecoded.cargo.relatorioagendamentosdodia
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={SchedulesOfDay}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/reports/medicine-prescriptions"
            allowedRoles={
              tokenDecoded.cargo.relatorioprescricoesmedicamentos
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={MedicinePrescriptions}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/help-center"
            allowedRoles={
              tokenDecoded.cargo.relatoriodecombos
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={HelpCenter}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/help-center/category/:category?"
            allowedRoles={
              tokenDecoded.cargo.relatoriodecombos
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={HelpCenterSubpage}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/reports/combos"
            allowedRoles={
              tokenDecoded.cargo.relatoriodecombos
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={CombosReports}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/price-list/service-items"
            allowedRoles={
              tokenDecoded.cargo.relatorioprecoitemsdeservico
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={ServiceItemsPrices}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/price-list/health-plan-items"
            allowedRoles={
              tokenDecoded.cargo.relatorioprecoitemsdeconvenio
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={HealthPlanPriceList}
          />
          <ProtectedRoute
            allowedRoles={
              tokenDecoded.cargo.cargodeprofissional || tokenDecoded.cargo.cargodediagnostico
              ? [tokenDecoded.cargo.nome] 
              : [
                  "profissional", 
                  "Admin", 
                  "admin", 
                  "administrador", 
                  "Administrador", 
                  "diagnostico", 
                  "login unificado"
                ]
              }
            userRoles={roles}
            exact
            path="/professional-favorites"
            render={ProfessionalFavorites}
          />
          <ProtectedRoute
            allowedRoles={
              tokenDecoded.cargo.cargodeprofissional || tokenDecoded.cargo.cargodediagnostico
              ? [tokenDecoded.cargo.nome] 
              : [
                  "profissional", 
                  "Admin", 
                  "admin", 
                  "administrador", 
                  "Administrador", 
                  "diagnostico", 
                  "login unificado"
                ]
              }
            userRoles={roles}
            exact
            path="/exams-kits"
            render={ExamsKit}
          />
          <ProtectedRoute
            allowedRoles={
              tokenDecoded.cargo.cargodeprofissional || tokenDecoded.cargo.cargodediagnostico
              ? [tokenDecoded.cargo.nome] 
              : [
                  "profissional", 
                  "Admin", 
                  "admin", 
                  "administrador", 
                  "Administrador", 
                  "diagnostico", 
                  "login unificado"
                ]
              }
            userRoles={roles}
            exact
            path="/exams-kits/form/:examKitId?"
            render={ExamsKitForm}
          />
          <ProtectedRoute
            allowedRoles={
              tokenDecoded.cargo.cargodeprofissional || tokenDecoded.cargo.cargodediagnostico
              ? [tokenDecoded.cargo.nome] 
              : [
                  "profissional", 
                  "Admin", 
                  "admin", 
                  "administrador", 
                  "Administrador", 
                  "diagnostico", 
                  "login unificado"
                ]
              }
            userRoles={roles}
            exact
            path="/medical-kits"
            render={MedicalKits}
          />
          <ProtectedRoute
            allowedRoles={
              tokenDecoded.cargo.cargodeprofissional || tokenDecoded.cargo.cargodediagnostico
              ? [tokenDecoded.cargo.nome] 
              : [
                  "profissional", 
                  "Admin", 
                  "admin", 
                  "administrador", 
                  "Administrador", 
                  "diagnostico", 
                  "login unificado"
                ]
              }
            userRoles={roles}
            exact
            path="/medical-kits/form/:medicalKitId?"
            render={MedicalKitsForm}
          />
          <ProtectedRoute
            allowedRoles={
              tokenDecoded.cargo.cargodeprofissional || tokenDecoded.cargo.cargodediagnostico
              ? [tokenDecoded.cargo.nome] 
              : [
                  "profissional", 
                  "Admin", 
                  "admin", 
                  "administrador", 
                  "Administrador", 
                  "diagnostico", 
                  "login unificado"
                ]
              }
            userRoles={roles}
            exact
            path="/professionals-custom/medical-form"
            render={CustomMedicalForms}
          />
          <ProtectedRoute
            allowedRoles={
              tokenDecoded.cargo.cargodeprofissional || tokenDecoded.cargo.cargodediagnostico
              ? [tokenDecoded.cargo.nome] 
              : [
                  "profissional", 
                  "Admin", 
                  "admin", 
                  "administrador", 
                  "Administrador", 
                  "diagnostico", 
                  "login unificado"
                ]
              }
            userRoles={roles}
            exact
            path="/professionals-custom/exams-results"
            render={CustomExamResult}
          />
          {/* {signedInUser?.cargo.super_admin ? (
          <>
            <ProtectedRoute
              allowedRoles={[
                "profissional",
                "Admin",
                "admin",
                "administrador",
                "Administrador",
                "diagnostico",
              ]}
              userRoles={roles}
              exact
              path="/professionals-custom/medical-form"
              render={CustomMedicalForms}
            />
            <ProtectedRoute
              allowedRoles={[
                "profissional",
                "Admin",
                "admin",
                "administrador",
                "Administrador",
                "diagnostico",
              ]}
              userRoles={roles}
              exact
              path="/professionals-custom/exams-results"
              render={CustomExamResult}
            />
          </>
        ) : (
          <></>
        )} */}
        <ProtectedRoute
          allowedRoles={
            tokenDecoded.cargo.cargodeprofissional || tokenDecoded.cargo.cargodediagnostico
            ? [tokenDecoded.cargo.nome] 
            : [
                "profissional", 
                "Admin", 
                "admin", 
                "administrador", 
                "Administrador", 
                "diagnostico", 
                "login unificado"
              ]
            }
          userRoles={roles}
          exact
          path="/clinics/:clinicId/departments/:departmentId/blocks/:blockId/attendance/window-sets/:windowSetId/counter"
          render={CounterList}
        />
        <ProtectedRoute
          allowedRoles={
            tokenDecoded.cargo.cargodeprofissional || tokenDecoded.cargo.cargodediagnostico
            ? [tokenDecoded.cargo.nome] 
            : [
                "profissional", 
                "Admin", 
                "admin", 
                "administrador", 
                "Administrador", 
                "diagnostico", 
                "login unificado"
              ]
            }
          userRoles={roles}
          exact
          path="/clinics/:clinicId/departments/:departmentId/blocks/:blockId/attendance/window-sets/:windowSetId/counter/form/:counterId?"
          render={CounterForm}
        />
        <ProtectedRoute
          allowedRoles={[
            "profissional",
            "Admin",
            "admin",
            "administrador",
            "Administrador",
            "diagnostico",
            "login unificado"
          ]}
          userRoles={roles}
          exact
          path="/clinics/:clinicId/departments/:departmentId/blocks/:blockId/attendance/window-sets"
          render={WindowSetList}
        />
        <ProtectedRoute
          allowedRoles={
            tokenDecoded.cargo.cargodeprofissional || tokenDecoded.cargo.cargodediagnostico
            ? [tokenDecoded.cargo.nome] 
            : [
                "profissional", 
                "Admin", 
                "admin", 
                "administrador", 
                "Administrador", 
                "diagnostico", 
                "login unificado"
              ]
            }
          userRoles={roles}
          exact
          path="/clinics/:clinicId/departments/:departmentId/blocks/:blockId/attendance/window-sets/form/:windowSetId?"
          render={WindowSetsForm}
        />
        <ProtectedRoute
          allowedRoles={
            tokenDecoded.cargo.cargodeprofissional || tokenDecoded.cargo.cargodediagnostico
            ? [tokenDecoded.cargo.nome] 
            : [
                "profissional", 
                "Admin", 
                "admin", 
                "administrador", 
                "Administrador", 
                "diagnostico", 
                "login unificado"
              ]
            }
          userRoles={roles}
          exact
          path="/clinics/:clinicId/departments/:departmentId/blocks/:blockId/attendance/counter-type-service"
          render={CounterTypeServiceList}
        />
        <ProtectedRoute
          allowedRoles={
            tokenDecoded.cargo.cargodeprofissional || tokenDecoded.cargo.cargodediagnostico
            ? [tokenDecoded.cargo.nome] 
            : [
                "profissional", 
                "Admin", 
                "admin", 
                "administrador", 
                "Administrador", 
                "diagnostico", 
                "login unificado"
              ]
            }
          userRoles={roles}
          exact
          path="/clinics/:clinicId/departments/:departmentId/blocks/:blockId/attendance/counter-type-service/form/:counterTypeServiceId?"
          render={CounterTypeServiceForm}
        />
        <ProtectedRoute
          allowedRoles={
            tokenDecoded.cargo.cargodeprofissional || tokenDecoded.cargo.cargodediagnostico
            ? [tokenDecoded.cargo.nome] 
            : [
                "profissional", 
                "Admin", 
                "admin", 
                "administrador", 
                "Administrador", 
                "diagnostico", 
                "login unificado"
              ]
            }
          userRoles={roles}
          exact
          path="/clinics/:clinicId/departments/:departmentId/blocks/:blockId/attendance/attendance-groups"
          render={AttendanceGroupList}
        />
        <ProtectedRoute
          allowedRoles={
            tokenDecoded.cargo.cargodeprofissional || tokenDecoded.cargo.cargodediagnostico
            ? [tokenDecoded.cargo.nome] 
            : [
                "profissional", 
                "Admin", 
                "admin", 
                "administrador", 
                "Administrador", 
                "diagnostico", 
                "login unificado"
              ]
            }
          userRoles={roles}
          exact
          path="/clinics/:clinicId/departments/:departmentId/blocks/:blockId/attendance/attendance-groups/form/:attendanceGroupId?"
          render={AttendanceGroupForm}
        />
        <ProtectedRoute
          allowedRoles={
            tokenDecoded.cargo.cargodeprofissional || tokenDecoded.cargo.cargodediagnostico
            ? [tokenDecoded.cargo.nome] 
            : [
                "profissional", 
                "Admin", 
                "admin", 
                "administrador", 
                "Administrador", 
                "diagnostico", 
                "login unificado"
              ]
            }
          userRoles={roles}
          exact
          path="/clinics/:clinicId/departments/:departmentId/blocks/:blockId/attendance/call-panel"
          render={CallPanelList}
        />
        <ProtectedRoute
          allowedRoles={
            tokenDecoded.cargo.cargodeprofissional || tokenDecoded.cargo.cargodediagnostico
            ? [tokenDecoded.cargo.nome] 
            : [
                "profissional", 
                "Admin", 
                "admin", 
                "administrador", 
                "Administrador", 
                "diagnostico", 
                "login unificado"
              ]
            }
          userRoles={roles}
          exact
          path="/clinics/:clinicId/departments/:departmentId/blocks/:blockId/attendance/call-panel/form/:callPanelId?"
          render={CallPanelForm}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/clinics/:clinicId/departments/:departmentId/blocks/:blockId/attendance/"
          allowedRoles={
            tokenDecoded.cargo.clinica
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={AttendancesList}
        />
        <ProtectedRoute
          allowedRoles={
            tokenDecoded.cargo.cargodeprofissional || tokenDecoded.cargo.cargodediagnostico
            ? [tokenDecoded.cargo.nome] 
            : [
                "profissional", 
                "Admin", 
                "admin", 
                "administrador", 
                "Administrador", 
                "diagnostico", 
                "login unificado"
              ]
            }
          userRoles={roles}
          exact
          path="/clinics/:clinicId/departments/:departmentId/blocks/:blockId/attendance/totem"
          render={TotemList}
        />
        <ProtectedRoute
          allowedRoles={
            tokenDecoded.cargo.cargodeprofissional || tokenDecoded.cargo.cargodediagnostico
            ? [tokenDecoded.cargo.nome] 
            : [
                "profissional", 
                "Admin", 
                "admin", 
                "administrador", 
                "Administrador", 
                "diagnostico", 
                "login unificado"
              ]
            }
          userRoles={roles}
          exact
          path="/clinics/:clinicId/departments/:departmentId/blocks/:blockId/attendance/totem/form/:totemId?"
          render={TotemForm}
        />
        <ProtectedRoute
          allowedRoles={
            tokenDecoded.cargo.cargodeprofissional || tokenDecoded.cargo.cargodediagnostico
            ? [tokenDecoded.cargo.nome] 
            : [
                "profissional", 
                "Admin", 
                "admin", 
                "administrador", 
                "Administrador", 
                "diagnostico", 
                "login unificado"
              ]
            }
          userRoles={roles}
          exact
          path="/appointment-web"
          render={AppointmentWebSetup}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/stock/manufacturers"
          allowedRoles={
            tokenDecoded.cargo.itemservico
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={StockManufacturers}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/stock/manufacturers/form/:manufacturersId?"
          allowedRoles={
            tokenDecoded.cargo.itemservico
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={StockManufacturersForm}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/stock/local"
          allowedRoles={
            tokenDecoded.cargo.itemservico
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={StockLocal}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/stock/local/form/:localId?"
          allowedRoles={
            tokenDecoded.cargo.itemservico
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={StockLocalForm}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/stock/category"
          allowedRoles={
            tokenDecoded.cargo.itemservico
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={StockCategory}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/stock/category/form/:categoryId?"
          allowedRoles={
            tokenDecoded.cargo.itemservico
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={StockCategoryForm}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/stock/items"
          allowedRoles={
            tokenDecoded.cargo.itemservico
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={StockItens}
        />
        <ProtectedRoute
          userRoles={roles}
          exact
          path="/stock/items/form/:itemsId?"
          allowedRoles={
            tokenDecoded.cargo.itemservico
              ? [tokenDecoded.cargo.nome]
              : ["Admin", "admin", "administrador", "Administrador"]
          }
          render={StockItensForm}
        />
        <ProtectedRoute
            userRoles={roles}
            exact
            path="/help-center"
            allowedRoles={
              tokenDecoded.cargo.relatoriodecombos
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={HelpCenter}
          />
          <ProtectedRoute
            userRoles={roles}
            exact
            path="/help-center/category/:category?"
            allowedRoles={
              tokenDecoded.cargo.relatoriodecombos
                ? [tokenDecoded.cargo.nome]
                : ["Admin", "admin", "administrador", "Administrador"]
            }
            render={HelpCenterSubpage}
          />
      </ContentContainer>
    </div>
    </>
  ) : null;
};
  
export default Routes;
