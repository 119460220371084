import {
  AnyAction,
  createSlice,
  PayloadAction,
  ThunkAction,
} from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { IOffice, IOfficeForm, IProfessionalCheckin } from "models/Offices";
import { IFilter } from "models/shared/index";
import { Paciente } from "pages/Reports/ReceivedPayments/ReceivedPaymentsTable/data.types";
import { toast } from "react-toastify";
import { AppThunk, rootState } from "store/index";
import api from "utils/API";
import { history } from "utils/history";
import { queryStringFromFilterArray } from "utils/network";
import toastOptions from "utils/toastOptions";

interface IInitialState {
  isFetchingOffices: boolean;
  isCreatingOffice: boolean;
  isDeletingOffice: boolean;
  isCheckinOffice: boolean;
  offices: IOffice[] | null;
  professionalOffices: IOffice[];
  currentOffice: IOffice | null;
  currentlyHasCheckin: boolean;
  currentProfessionalCheckin: IProfessionalCheckin | null;
  updateExpiresCheckinFetchIntervalId: number;
  total: number;
  page: number;
  filterArray: IFilter[];
  currentPatient?: Paciente | null;
  isOpenPatientInfos: boolean;
  isOpenPatientHistory: boolean;
  amountOfItemsToShow: number;
}

const initialState: IInitialState = {
  isFetchingOffices: false,
  isCreatingOffice: false,
  isDeletingOffice: false,
  offices: [],
  professionalOffices: [],
  isCheckinOffice: false,
  currentOffice: null,
  currentlyHasCheckin: false,
  currentProfessionalCheckin: null,
  total: 0,
  page: 1,
  amountOfItemsToShow: 6,
  filterArray: [
    { key: "nome", value: [] },
    { key: "idclinica", value: [] },
  ],
  currentPatient: null,
  isOpenPatientInfos: false,
  isOpenPatientHistory: false,
  updateExpiresCheckinFetchIntervalId: -1,
};

const officesSlice = createSlice({
  name: "officesSlice",
  initialState,
  reducers: {
    setCurrentlyChecked: (state, { payload }: PayloadAction<boolean>) => {
      state.currentlyHasCheckin = payload;
    },
    setIsFetchingOffices: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingOffices = payload;
    },
    setIsCreatingOffice: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreatingOffice = payload;
    },
    setisDeletingOffice: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeletingOffice = payload;
    },
    setProfessionalOffices: (state, { payload }: PayloadAction<IOffice[]>) => {
      state.professionalOffices = payload;
    },
    setIsCheckinOffice: (state, { payload }: PayloadAction<boolean>) => {
      state.isCheckinOffice = payload;
    },
    setUpdateExpiresCheckinFetchIntervalId: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.updateExpiresCheckinFetchIntervalId = payload;
    },
    setOffices: (
      state,
      {
        payload: { offices, total, page },
      }: PayloadAction<{
        offices: IOffice[];
        total: number;
        page: number;
      }>
    ) => {
      state.offices = offices;
      state.total = total;
      state.page = page;
    },
    setCurrentOffice: (state, { payload }: PayloadAction<IOffice>) => {
      state.currentOffice = payload;
    },
    setProfessionalCurrentCheckin: (
      state,
      { payload }: PayloadAction<IProfessionalCheckin | null>
    ) => {
      state.currentProfessionalCheckin = payload;
    },
    updateFilter: (state, { payload }: PayloadAction<IFilter>) => {
      const index = state.filterArray.findIndex(
        (item) => item.key === payload.key
      );
      state.filterArray[index].value = payload.value;
    },
    setPatient: (state, { payload }: PayloadAction<Paciente>) => {
      state.currentPatient = payload;
    },
    setIsOpenPatientInfos: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenPatientInfos = payload;
    },
    setIsOpenPatientHistory: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenPatientHistory = payload;
    },
    setPageToOffice: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setAmountOfItemsToShow: (state, { payload }: PayloadAction<number>) => {
      state.amountOfItemsToShow = payload;
    },
  },
});

export const fetchOffices =
  ({
    page = 1,
    limit = 6,
    blockId,
  }: {
    page?: number;
    limit?: number;
    blockId?: number | string;
  }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingOffices, setOffices } = officesSlice.actions;
    dispatch(setIsFetchingOffices(true));
    try {
      const state = getState();
      const { filterArray } = state.offices;
      const queryParameters = queryStringFromFilterArray(filterArray);
      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;
      const response = await api.get(
        `/api/offices${queryParameters}${pageAndLimit}&idbloco=${blockId}`
      );
      dispatch(setOffices(response.data.data));
      dispatch(setIsFetchingOffices(false));
    } catch (error: any | AxiosError) {
      dispatch(setIsFetchingOffices(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const officeActivation =
  ({
    isOfficeActive,
    officeId,
    blockId,
    limit,
    page,
  }: {
    isOfficeActive: boolean;
    officeId: string;
    page?: number;
    limit?: number;
    blockId: string | number;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingOffice } = officesSlice.actions;
    dispatch(setIsCreatingOffice(true));
    try {
      await api.put(`/api/offices/${officeId}`, { ativo: !isOfficeActive });
      dispatch(setIsCreatingOffice(false));
      dispatch(
        fetchOffices({
          blockId,
          limit,
          page,
        })
      );
      toast.success(
        `Consultório ${isOfficeActive ? "desativado" : "ativado"} com sucesso`,
        toastOptions
      );
    } catch (error: any) {
      dispatch(setIsCreatingOffice(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchOfficeById =
  (officeId: string): AppThunk =>
  async (dispatch) => {
    const { setIsFetchingOffices, setCurrentOffice } = officesSlice.actions;
    dispatch(setIsFetchingOffices(true));
    try {
      const response = await api.get(`/api/offices/${officeId}`);
      dispatch(setCurrentOffice(response.data.data));
      dispatch(setIsFetchingOffices(false));
    } catch (error: any | AxiosError) {
      dispatch(setIsFetchingOffices(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };
export const fetchOfficesByProfessional =
  (professionalId: string, idclinica?: string, idbloco?: string): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingOffices, setProfessionalOffices } =
      officesSlice.actions;
    dispatch(setIsFetchingOffices(true));
    try {
      const state = getState();
      const { filterArray } = state.offices;
      const queryParameters = queryStringFromFilterArray(filterArray);
      const pageAndLimit =
        queryParameters.length === 0
          ? `?idclinica=${idclinica}&idbloco=${idbloco}`
          : `&idclinica=${idclinica}&idbloco=${idbloco}`;
      const response = await api.get(
        `/api/professional/${professionalId}/offices${queryParameters}${pageAndLimit}`
      );
      dispatch(setProfessionalOffices(response.data.data));
      dispatch(setIsFetchingOffices(false));
    } catch (error: any | AxiosError) {
      dispatch(setIsFetchingOffices(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const checkinProfessionalInOffice =
  ({
    officeId,
    callback,
  }: {
    officeId: string;
    callback?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCheckinOffice, setProfessionalCurrentCheckin } =
      officesSlice.actions;
    dispatch(setIsCheckinOffice(true));
    try {
      const response = await api.post(`/api/professional/checkin`, {
        idconsultorio: officeId,
      });
      dispatch(setProfessionalCurrentCheckin(response.data.data));
      dispatch(setIsCheckinOffice(false));

      toast.success("Check-in realizado", toastOptions);
      if (callback) callback(response.data.data);
    } catch (error: any | AxiosError) {
      dispatch(setIsCheckinOffice(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
      if (callback) callback(null);
    }
  };

export const checkExpiresCheckinProfessionalInOffice =
  ({ idcheckin }: { idcheckin?: string }): AppThunk =>
  async (dispatch) => {
    try {
      await api.put(`/api/professional/checkin/${idcheckin}`);
      // toast.success("Check-in atualizado", toastOptions);
    } catch (error: any | AxiosError) {
      sessionStorage.removeItem("checkinProfessional")
      dispatch(
        checkoutProfessionalInOffice({
          idcheckin,
        })
      );
      window.location.reload();
    }
  };

export const StartUpdateExpiresCheckinProfessionalInOffice =
  ({ idcheckin }: { idcheckin?: string }): AppThunk =>
  async (dispatch, getState) => {
    const { setUpdateExpiresCheckinFetchIntervalId } = officesSlice.actions;
    const TIME_INTERVAL = 1000 * 10; // 10s
    const state = getState();
    const { updateExpiresCheckinFetchIntervalId } = state.offices;

    if (updateExpiresCheckinFetchIntervalId) {
      clearInterval(updateExpiresCheckinFetchIntervalId);
    }

    var _tid = setInterval(() => {
      const location = window.location.href.split("/");
      const inSchedulePage = location[location.length - 1].includes("sala");
      const inMedicalRecordPage = location.includes("medical-records");

      dispatch(
        checkExpiresCheckinProfessionalInOffice({
          idcheckin,
        })
      );
    }, TIME_INTERVAL);

    dispatch(setUpdateExpiresCheckinFetchIntervalId(_tid));
  };

export const ClearUpdateExpiresCheckinProfessionalInOfficeInterval =
  (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const { updateExpiresCheckinFetchIntervalId } = state.offices;

    if (updateExpiresCheckinFetchIntervalId) {
      clearInterval(updateExpiresCheckinFetchIntervalId);
    }
  };

export const checkoutProfessionalInOffice =
  ({
    idcheckin,
    callback,
  }: {
    idcheckin?: string;
    callback?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCheckinOffice } = officesSlice.actions;
    dispatch(setIsCheckinOffice(true));
    try {
      await api.patch(`/api/professional/checkin/${idcheckin}`);
      dispatch(setIsCheckinOffice(false));

      toast.success("Check-out realizado");
      if (callback) callback();
    } catch (error: any | AxiosError) {
      dispatch(setIsCheckinOffice(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchProfessionalCheckin =
  (): ThunkAction<void, rootState, unknown, AnyAction> => async (dispatch) => {
    const { setIsCheckinOffice, setProfessionalCurrentCheckin } =
      officesSlice.actions;
    dispatch(setIsCheckinOffice(true));
    try {
      const response = await api.get(`/api/professional/checkin`);
      dispatch(setProfessionalCurrentCheckin(response.data.data));
      dispatch(setIsCheckinOffice(false));
    } catch (error: any | AxiosError) {
      dispatch(setIsCheckinOffice(false));
      dispatch(setProfessionalCurrentCheckin(null));
      if (error.response) {
        toast.error(error.response.data?.error?.message);
      } else {
        console.log(error.message);
      }
    }
  };

export const createOffice =
  ({
    office,
    clinicId,
    departmentId,
    idspainelchamado,
  }: {
    office: IOfficeForm;
    clinicId: string;
    departmentId: string;
    idspainelchamado: string[];
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingOffice } = officesSlice.actions;
    dispatch(setIsCreatingOffice(true));
    try {
      await api.post(`/api/offices`, { ...office, idspainelchamado });
      dispatch(setIsCreatingOffice(false));
      history.replace(
        `/clinics/${clinicId}/departments/${departmentId}/blocks/${office.idbloco}/offices`
      );
      toast.success("Consultório cadastrado", toastOptions);
    } catch (error: any | AxiosError) {
      dispatch(setIsCreatingOffice(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updateOffice =
  ({
    office,
    officeId,
    clinicId,
    departmentId,
    idspainelchamado,
  }: {
    office: IOfficeForm;
    officeId: string;
    clinicId: string;
    departmentId: string;
    idspainelchamado: string[];
  }): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingOffice } = officesSlice.actions;
    dispatch(setIsCreatingOffice(true));
    try {
      await api.put(`/api/offices/${officeId}`, {
        ...office,
        idspainelchamado,
      });
      dispatch(setIsCreatingOffice(false));
      toast.success("Consultório atualizado", toastOptions);
      history.replace(
        `/clinics/${clinicId}/departments/${departmentId}/blocks/${office.idbloco}/offices`
      );
    } catch (error: any | AxiosError) {
      dispatch(setIsCreatingOffice(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const deleteOffice =
  ({ blockId, officeId }: { blockId: string; officeId: string }): AppThunk =>
  async (dispatch) => {
    const { setisDeletingOffice } = officesSlice.actions;
    dispatch(setisDeletingOffice(true));
    try {
      await api.delete(`/api/offices/${officeId}`);
      dispatch(setisDeletingOffice(false));
      toast.success("Consultório excluido com sucesso", toastOptions);
      dispatch(fetchOffices({ blockId }));
    } catch (error: any | AxiosError) {
      dispatch(setisDeletingOffice(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const {
  updateFilter: updateOfficesFilter,
  setCurrentlyChecked,
  setPatient,
  setIsOpenPatientInfos,
  setIsOpenPatientHistory,
  setProfessionalOffices,
  setPageToOffice,
  setAmountOfItemsToShow,
} = officesSlice.actions;

export default officesSlice.reducer;
