import { IFilter } from "models/shared/index";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "store/index";
import { toast } from "react-toastify";
import toastOptions from "utils/toastOptions";
import api from "utils/API";
import { queryStringFromFilterArray } from "utils/network";
import {
  IClinicTimetable,
  IScheduleByProfessional,
  TimeStatus,
} from "models/Clinics/timetable";
import {
  IAppointment,
  IAppointmentForm,
  IAppointmentFromServiceOrderForm,
  ICheckinsWithoutAppointment,
  IHistory,
} from "models/Appointments";
import { ISelectOption } from "pages/SchedulePage";
import { clearUpdateDisponibilityMapFetchInterval } from "../DisponibilityMapReducer";
import {
  removeServicePacakgeToBeScheduled,
  setIsServicePackageDialogOpen,
} from "../ServicePackages";
import { IServiceOrderServiceItem } from "models/ServiceOrder/ServiceOrderServiceItem";
import {
  removeItemFromQuoteToBeScheduled,
  setIsSchedulingFromQuoteDialogOpen,
} from "../CustomerQuotesReducer";
import { fetchPatientsAttended } from "../PatientsAttendedReducer";
import { IAppointmentServiceItem } from "models/Appointments/AppointmentServiceItem";

interface IInitialState {
  isFetchingAppointments: boolean;
  isFetchingAppointmentsWithoutTimeout: boolean;
  isCreatingAppointment: boolean;
  isDeletingAppointment: boolean;
  isUpdatingAppointment: boolean;
  isFinishingAppointment: boolean;
  isFetchingHistory: boolean;
  isCreatingHistory: boolean;
  currentAppointmentStatus: string | null;
  isAddingServiceItemToAppointment: boolean;
  serviceItemsToBeScheduled:
    | IServiceOrderServiceItem[]
    | IAppointmentServiceItem[];
  isServiceItemDialogOpen: boolean;
  isSchedulingServiceItems: boolean;
  isCreatingOS: boolean;
  appointmentsCheckin: any[];
  checkinsWithoutAppointment: ICheckinsWithoutAppointment[];
  checkinAfterSchedulingOsItem: boolean;
  rescheduleOrderItem: boolean;
  appointmentHistory: IHistory[];
  serviceOrderHistory: IHistory[];
  customerQuoteHistory: IHistory[];
  appointments: IScheduleByProfessional[] | null;
  clinicAppointmentsByStatus: TimeStatus[];
  currentAppointment: IAppointment | null;
  currentAppointments: IAppointment[] | null;
  appointmentSummary: IAppointment[];
  professinalFutureAppointments: any[];
  filterArray: IFilter[];
  checkinsWithoutAppointmentsFilterArray: IFilter[];
  checkinsFilterArray: IFilter[];
  checkinsTableFilterArray: IFilter[];
  futureAppointmentsFilterArray: IFilter[];
  futureAppointmentsPage: number;
  futureAppointmentsTotalItems: number;
  currentFilterOption: ISelectOption;
  currentTableFilterOption: ISelectOption;
  currentAttendance: ICheckinsWithoutAppointment | null;
  updateSchedulesFetchIntervalId: number;
  updatePatientsWithoutAppointmentFecthIntervalId: number;
  updateOfficePatientsFetchIntervalId: number;
  closeWindowEvent: boolean;
  checkinFilterlistGroup: boolean;
  isUpdatingCheckinsFilter: boolean;
  filteredAppointmentsCheckinByClinic: IAppointment[];
  serviceItemsToScheduleFromWallet: IServiceOrderServiceItem[];
  guidance: boolean;
  schedulingViaSchedulingDialog: boolean;
  modalBackToDialog: boolean;
  returnSchedulingModal: boolean;
  currentEvent: string | null;
}

const initialState: IInitialState = {
  isFetchingAppointments: false,
  isFetchingAppointmentsWithoutTimeout: false,
  isCreatingAppointment: false,
  isDeletingAppointment: false,
  isUpdatingAppointment: false,
  isFinishingAppointment: false,
  isFetchingHistory: false,
  isCreatingHistory: false,
  isAddingServiceItemToAppointment: false,
  currentAppointmentStatus: null,
  clinicAppointmentsByStatus: [],
  isServiceItemDialogOpen: false,
  isSchedulingServiceItems: false,
  serviceItemsToBeScheduled: [],
  appointmentsCheckin: [],
  checkinsWithoutAppointment: [],
  checkinAfterSchedulingOsItem: false,
  schedulingViaSchedulingDialog: false,
  modalBackToDialog: false,
  returnSchedulingModal: false,
  rescheduleOrderItem: false,
  appointmentHistory: [],
  serviceOrderHistory: [],
  customerQuoteHistory: [],
  appointments: null,
  currentAppointment: null,
  currentAppointments: [],
  appointmentSummary: [],
  futureAppointmentsPage: 0,
  futureAppointmentsTotalItems: 0,
  professinalFutureAppointments: [],
  filterArray: [
    { key: "idclinica", value: null },
    { key: "idcategoriaservico", value: null },
    { key: "idprofissional", value: null },
    { key: "iditemservico", value: null },
    { key: "paciente", value: null },
  ],
  checkinsWithoutAppointmentsFilterArray: [
    { key: "idclinica", value: null },
    { key: "iddepartamento", value: null },
    { key: "idbloco", value: null },
    { key: "nomepaciente", value: null },
    { key: "idprofissional", value: null },
    { key: "status", value: null },
    { key: "start_date", value: null },
    { key: "end_date", value: null },
    { key: "page", value: null },
  ],
  checkinsFilterArray: [
    { key: "order", value: null },
    { key: "bloco", value: false },
  ],
  checkinsTableFilterArray: [],
  futureAppointmentsFilterArray: [
    { key: "start_date", value: null },
    { key: "end_date", value: null },
    { key: "idclinica", value: null },
  ],
  currentFilterOption: { label: "", value: "" },
  currentTableFilterOption: { label: "Todos", value: "all" },
  checkinFilterlistGroup: false,
  currentAttendance: null,
  updateSchedulesFetchIntervalId: -1,
  updatePatientsWithoutAppointmentFecthIntervalId: -1,
  updateOfficePatientsFetchIntervalId: -1,
  closeWindowEvent: false,
  isCreatingOS: false,
  isUpdatingCheckinsFilter: false,
  filteredAppointmentsCheckinByClinic: [],
  serviceItemsToScheduleFromWallet: [],
  guidance: false,
  currentEvent: null,
};

const appointmentsSlice = createSlice({
  name: "appointmentsSlice",
  initialState,
  reducers: {
    setCurrentAppointmentStatus: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.currentAppointmentStatus = payload;
    },
    setIsFinishingAppointment: (state, { payload }: PayloadAction<boolean>) => {
      state.isFinishingAppointment = payload;
    },
    setIsFetchingAppointments: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingAppointments = payload;
    },
    setSchedulingViaSchedulingDialog: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.schedulingViaSchedulingDialog = payload;
    },
    setReturnSchedulingModal: (state, { payload }: PayloadAction<boolean>) => {
      state.returnSchedulingModal = payload;
    },
    setIsFetchingAppointmentsWithoutTimeout: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingAppointmentsWithoutTimeout = payload;
    },
    setIsCreatingAppointment: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreatingAppointment = payload;
    },
    setIsCreatingOS: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreatingOS = payload;
    },
    setIsUpdatingAppointment: (state, { payload }: PayloadAction<boolean>) => {
      state.isUpdatingAppointment = payload;
    },
    setisDeletingAppointment: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeletingAppointment = payload;
    },
    setUpdateSchedulesFetchIntervalId: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.updateSchedulesFetchIntervalId = payload;
    },
    setCheckinFilterlistGroup: (state, { payload }: PayloadAction<boolean>) => {
      state.checkinFilterlistGroup = payload;
    },
    setUpdatePatientsWithoutAppointmentFecthIntervalId: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.updatePatientsWithoutAppointmentFecthIntervalId = payload;
    },
    setUpdateOfficePatientsFetchIntervalId: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.updateOfficePatientsFetchIntervalId = payload;
    },
    setCloseWindowEvent: (state, { payload }: PayloadAction<boolean>) => {
      state.closeWindowEvent = payload;
    },
    setIsAddingItemToAppointment: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isAddingServiceItemToAppointment = payload;
    },
    setServiceItemToBeScheduled: (
      state,
      {
        payload: { serviceItems },
      }: PayloadAction<{
        serviceItems: IServiceOrderServiceItem[] | IAppointmentServiceItem[];
      }>
    ) => {
      state.serviceItemsToBeScheduled = serviceItems;
    },
    setIsServiceItemDialogOpen: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isServiceItemDialogOpen = payload;
    },
    removeServiceItemToBeScheduled: (
      state,
      {
        payload: { serviceItemId },
      }: PayloadAction<{
        serviceItemId: string;
      }>
    ) => {
      state.serviceItemsToBeScheduled = state.serviceItemsToBeScheduled.filter(
        (serviceItems) => serviceItems.iditemservico !== serviceItemId
      );
    },
    setModalBackToDialog: (state, { payload }: PayloadAction<boolean>) => {
      state.modalBackToDialog = payload;
    },

    setIsSchedulingServiceItems: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isSchedulingServiceItems = payload;
    },
    setAppointmentsHasCheckin: (state, { payload }: PayloadAction<any[]>) => {
      state.appointmentsCheckin = payload;
    },
    setCheckinsWithoutAppointment: (
      state,
      { payload }: PayloadAction<ICheckinsWithoutAppointment[]>
    ) => {
      state.checkinsWithoutAppointment = payload;
    },
    setCheckinAfterSchedulingOsItem: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.checkinAfterSchedulingOsItem = payload;
    },
    setRescheduleOrderItem: (state, { payload }: PayloadAction<boolean>) => {
      state.rescheduleOrderItem = payload;
    },
    setIsFetchingHistory: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingHistory = payload;
    },
    setIsCreatingHistory: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreatingHistory = payload;
    },
    setAppointmentsHistory: (state, { payload }: PayloadAction<IHistory[]>) => {
      state.appointmentHistory = payload;
    },
    setServiceOrderHistory: (state, { payload }: PayloadAction<IHistory[]>) => {
      state.serviceOrderHistory = payload;
    },
    setCustomerQuoteHistory: (
      state,
      { payload }: PayloadAction<IHistory[]>
    ) => {
      state.customerQuoteHistory = payload;
    },
    setCurrentFilterOption: (
      state,
      { payload }: PayloadAction<ISelectOption>
    ) => {
      state.currentFilterOption = payload;
    },
    setCurrentTableFilterOption: (
      state,
      { payload }: PayloadAction<ISelectOption>
    ) => {
      state.currentTableFilterOption = payload;
    },
    setAppointments: (state, { payload }: PayloadAction<IClinicTimetable>) => {
      state.appointments = payload.scheduleByProfessional;
      state.clinicAppointmentsByStatus = payload.status;
    },
    clearAppointments: (state) => {
      state.appointments = null;
      state.clinicAppointmentsByStatus = [];
    },
    setCurrentAppointments: (
      state,
      { payload }: PayloadAction<IAppointment[]>
    ) => {
      state.currentAppointments = payload;
    },
    setCurrentAppointment: (
      state,
      { payload }: PayloadAction<IAppointment | null>
    ) => {
      state.currentAppointment = payload;
    },
    setCurrentAttendance: (
      state,
      { payload }: PayloadAction<ICheckinsWithoutAppointment>
    ) => {
      state.currentAttendance = payload;
    },
    setAppointmentSummary: (
      state,
      { payload }: PayloadAction<IAppointment[]>
    ) => {
      state.appointmentSummary = payload;
    },
    setFutureAppointments: (state, { payload }: PayloadAction<any[]>) => {
      state.professinalFutureAppointments = payload;
    },
    setStepGuidance: (state, { payload }: PayloadAction<boolean>) => {
      state.guidance = payload;
    },
    setFutureAppointmentsInfo: (
      state,
      {
        payload: { total, page },
      }: PayloadAction<{
        total: number;
        page: number;
      }>
    ) => {
      state.futureAppointmentsTotalItems = total;
      state.futureAppointmentsPage = page;
    },
    updateFilter: (state, { payload }: PayloadAction<IFilter>) => {
      const index = state.filterArray.findIndex(
        (item) => item.key === payload.key
      );
      state.filterArray[index].value = payload.value;
    },
    updateCheckinsWithoutAppointmentFilter: (
      state,
      { payload }: PayloadAction<IFilter>
    ) => {
      const index = state.checkinsWithoutAppointmentsFilterArray.findIndex(
        (item) => item.key === payload.key
      );
      if (index === -1) {
        state.checkinsWithoutAppointmentsFilterArray.push({
          key: payload.key,
          value: payload.value,
        });
      } else {
        state.checkinsWithoutAppointmentsFilterArray[index].value =
          payload.value;
      }
    },
    updateCheckinsFilter: (state, { payload }: PayloadAction<IFilter>) => {
      const index = state.checkinsFilterArray.findIndex(
        (item) => item.key === payload.key
      );
      if (index === -1) {
        state.checkinsFilterArray.push({
          key: payload.key,
          value: payload.value,
        });
      } else {
        state.checkinsFilterArray[index].value = payload.value;
      }
    },
    updateCheckinsTableFilter: (state, { payload }: PayloadAction<IFilter>) => {
      const index = state.checkinsFilterArray.findIndex(
        (item) => item.key === payload.key
      );
      if (index === -1) {
        state.checkinsTableFilterArray = [];
        state.checkinsTableFilterArray.push({
          key: payload.key,
          value: payload.value,
        });
      } else {
        state.checkinsTableFilterArray[index].value = payload.value;
      }
    },
    updateFutureAppointmentsFilter: (
      state,
      { payload }: PayloadAction<IFilter>
    ) => {
      const index = state.futureAppointmentsFilterArray.findIndex(
        (item) => item.key === payload.key
      );
      state.futureAppointmentsFilterArray[index].value = payload.value;
    },
    setIsUpdatingCheckinsFilter: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isUpdatingCheckinsFilter = payload;
    },
    setFilteredAppointmentsCheckinByClinic: (
      state,
      { payload }: PayloadAction<IAppointment[]>
    ) => {
      state.filteredAppointmentsCheckinByClinic = payload;
    },
    setServiceItemsToScheduleFromWallet: (
      state,
      { payload }: PayloadAction<IServiceOrderServiceItem[]>
    ) => {
      state.serviceItemsToScheduleFromWallet = payload;
    },
    setCurrentEvent: (state, { payload }: PayloadAction<string | null>) => {
      state.currentEvent = payload;
    },
  },
});

export const startUpdateSchedulesFetchInterval =
  (): AppThunk => async (dispatch, getState) => {
    const { setUpdateSchedulesFetchIntervalId } = appointmentsSlice.actions;

    const state = getState();
    const { updateSchedulesFetchIntervalId } = state.appointments;

    if (updateSchedulesFetchIntervalId) {
      clearInterval(updateSchedulesFetchIntervalId);
    }

    var _tid = setInterval(() => {
      const location = window.location.href.split("/");
      const inSchedulePage = location[location.length - 1].includes("schedule");

      if (inSchedulePage) {
        dispatch(fetchAppointmentsByClinicId());
      } else {
        dispatch(clearUpdateSchedulesFecthInterval());
      }
    }, 180000);

    dispatch(clearUpdateDisponibilityMapFetchInterval());
    dispatch(setUpdateSchedulesFetchIntervalId(_tid));
  };

export const clearUpdateSchedulesFecthInterval =
  (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const { updateSchedulesFetchIntervalId } = state.appointments;

    if (updateSchedulesFetchIntervalId) {
      clearInterval(updateSchedulesFetchIntervalId);
    }
  };

export const fetchAppointmentsByClinicId =
  (): AppThunk => async (dispatch, getState) => {
    const { setIsFetchingAppointments, setAppointments, setStepGuidance } =
      appointmentsSlice.actions;
    try {
      const state = getState();
      const { schedulePageFilterArray, currentPatient } = state.schedulePage;
      const date = state.schedulePage.currentDate;
      const moment = require("moment");
      const parsedDate = moment(new Date(date));
      let queryParameters = queryStringFromFilterArray(
        schedulePageFilterArray
      );
      const patient = currentPatient.idpaciente
        ? `&idpatient=${currentPatient.idpaciente}`
        : "";

      if (queryParameters.startsWith("&")) {
        queryParameters = "?" + queryParameters;
      }

      if (
        schedulePageFilterArray.find((item) => item.key === "idclinica")?.value
      ) {
        dispatch(setIsFetchingAppointments(true));
        const response = await api.get(
          `/api/clinics/available-times${queryParameters}${patient}&data=${parsedDate.format(
            "YYYY-MM-DD"
          )}`
        );
        if (response.data.data.scheduleByProfessional.length) {
          dispatch(setStepGuidance(false));
        } else {
          dispatch(setStepGuidance(true));
        }
        dispatch(setAppointments(response.data.data));
        dispatch(setIsFetchingAppointments(false));
      }
    } catch (error: any) {
      dispatch(setIsFetchingAppointments(false));
      if (error.response) {
        toast.error(error.response.data?.error.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const createAppointment =
  ({
    newAppointmentData,
    callback,
    forceAppointmentReturnCallback,
  }: {
    newAppointmentData: IAppointmentForm;
    callback?: (idItem: string) => void;
    forceAppointmentReturnCallback?: (
      isForced: boolean,
      errorMessage: string
    ) => void;
  }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsCreatingAppointment } = appointmentsSlice.actions;
    dispatch(setIsCreatingAppointment(true));
    const newAppointment: IAppointmentForm = {
      ...newAppointmentData,
      data: newAppointmentData.data.toString().replace(".000Z", ""),
      generateserviceorder: false,
    };
    try {
      const response = await api.post(`/api/appointments`, newAppointment);
      toast.success("Agendamento cadastrado", toastOptions);
      const {
        disponibilityMap: { disponibilityMapFilterArray, currentServiceItems },
      } = getState();
      const disponibilityMapServiceItems = disponibilityMapFilterArray.find(
        (filter) => filter.key === "iditemservico"
      );
      if (disponibilityMapServiceItems && disponibilityMapServiceItems.value) {
        const newAppointmentItems: string[] = response.data.data.serviceItemIds;
        // @ts-ignore
        const newDisponibilityMapServiceItemsFilterArray =
          disponibilityMapServiceItems.value.filter(
            (item: ISelectOption) => !newAppointmentItems.includes(item.value)
          );
        const newDisponibilityMapServiceItemsOptions =
          currentServiceItems.filter(
            (item) => !newAppointmentItems.includes(item.value)
          );
        newAppointmentItems.forEach((item) => {
          dispatch(removeServicePacakgeToBeScheduled({ serviceItemId: item }));
        });
        newAppointmentItems.forEach((item) => {
          dispatch(removeServiceItemToBeScheduled({ serviceItemId: item }));
        });
        newAppointmentItems.forEach((item) => {
          dispatch(removeItemFromQuoteToBeScheduled({ serviceItemId: item }));
        });
        dispatch(setIsServiceItemDialogOpen(true));
        dispatch(setIsSchedulingFromQuoteDialogOpen(true));
        dispatch(setIsServicePackageDialogOpen(true));
      }
      if (callback) {
        callback(response.data.data.idconsulta);
      }
      dispatch(setIsCreatingAppointment(false));
    } catch (error: any) {
      dispatch(setIsCreatingAppointment(false));
      if (error.response) {
        if (
          forceAppointmentReturnCallback &&
          (error.response.data?.error?.message.includes(
            "Retorno fora do prazo"
          ) ||
            error.response.data?.error?.message.includes(
              "retornos máximo permitido"
            ) ||
            error.response.data?.error?.message.includes(
              "Email e senha necessários"
            ))
        ) {
          toast.error(error.response.data?.error?.message, toastOptions);
          forceAppointmentReturnCallback(
            true,
            error.response.data?.error?.message
          );
        } else {
          toast.error(error.response.data?.error?.message, toastOptions);
        }
      } else {
        console.log(error.message);
      }
    }
  };

export const updateAppointment =
  (appointmentId: string, status: string): AppThunk =>
  async (dispatch) => {
    const { setIsUpdatingAppointment, setCurrentAppointmentStatus } =
      appointmentsSlice.actions;
    try {
      await api.put(`/api/appointments/${appointmentId}`, {
        status,
      });
      dispatch(fetchAppointmentById(appointmentId));
      toast.success("Agendamento atualizado", toastOptions);
      dispatch(setCurrentAppointmentStatus(status));
      dispatch(setIsUpdatingAppointment(false));
    } catch (error: any) {
      if (error.response) {
        dispatch(setIsUpdatingAppointment(false));
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updateAppointmentData =
  (appointmentId: string, data: any): AppThunk =>
  async (dispatch) => {
    try {
      await api.put(`/api/appointments/${appointmentId}`, data);
      dispatch(fetchAppointmentById(appointmentId));
      toast.success("Agendamento atualizado", toastOptions);
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const cancelAppointment =
  (appointmentId: string, status: string, reason: string): AppThunk =>
  async (dispatch) => {
    const { setIsUpdatingAppointment, setCurrentAppointmentStatus } =
      appointmentsSlice.actions;
    try {
      await api.put(`/api/appointments-cancel/${appointmentId}`, {
        status,
        reason,
      });
      dispatch(fetchAppointmentById(appointmentId));
      toast.success("Agendamento atualizado", toastOptions);
      dispatch(setCurrentAppointmentStatus(status));
      dispatch(setIsUpdatingAppointment(false));
    } catch (error: any) {
      if (error.response) {
        dispatch(setIsUpdatingAppointment(false));
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const addItemToAppointment =
  ({
    serviceItemId,
    appointmentId,
    amount,
    callback,
  }: {
    serviceItemId: string;
    appointmentId: string;
    amount: number;
    callback?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsAddingItemToAppointment } = appointmentsSlice.actions;
    try {
      dispatch(setIsAddingItemToAppointment(true));
      await api.post(`/api/appointment/${appointmentId}/item`, {
        iditemservico: serviceItemId,
        quantidade: 1,
      });
      if (callback) callback();
      toast.success("Item adicionado com sucesso", toastOptions);
      dispatch(setIsAddingItemToAppointment(false));
    } catch (error: any) {
      if (error.response) {
        dispatch(setIsAddingItemToAppointment(false));
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const removeItemFromAppointment =
  ({
    serviceItemId,
    appointmentId,
    callback,
  }: {
    serviceItemId: string;
    appointmentId: string;
    callback?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsAddingItemToAppointment } = appointmentsSlice.actions;
    try {
      dispatch(setIsAddingItemToAppointment(true));
      await api.delete(
        `/api/appointment/${appointmentId}/item/${serviceItemId}`
      );
      if (callback) callback();
      toast.success("Item removido com sucesso", toastOptions);
      dispatch(setIsAddingItemToAppointment(false));
    } catch (error: any) {
      if (error.response) {
        dispatch(setIsAddingItemToAppointment(false));
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const editItemFromAppointment =
  ({
    serviceItemId,
    appointmentId,
    healthPlanCategoryId,
    callback,
  }: {
    serviceItemId: string;
    appointmentId: string;
    healthPlanCategoryId?: string;
    callback?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsAddingItemToAppointment } = appointmentsSlice.actions;
    try {
      dispatch(setIsAddingItemToAppointment(true));
      let data = {};

      if (healthPlanCategoryId) {
        data = {
          idplano: healthPlanCategoryId,
        };
      }
      await api.put(
        `/api/appointment/${appointmentId}/item/${serviceItemId}`,
        data
      );
      if (callback) callback();
      toast.success("Item editado com sucesso", toastOptions);
      dispatch(setIsAddingItemToAppointment(false));
    } catch (error: any) {
      if (error.response) {
        dispatch(setIsAddingItemToAppointment(false));
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const checkinPatientOnAppointment =
  (appointmentId: string, cb?: Function): AppThunk =>
  async (dispatch) => {
    const { setIsUpdatingAppointment, setCurrentAppointmentStatus } =
      appointmentsSlice.actions;
    try {
      const response = await api.post(`/api/totems/checkin/${appointmentId}`);
      if (cb) {
        cb();
      }
      dispatch(fetchAppointmentById(appointmentId));
      if (response.data.data?.idconsulta) {
        toast.success("Checkin realizado com sucesso!", toastOptions);
        dispatch(setCurrentAppointmentStatus("checkin"));
        dispatch(setIsUpdatingAppointment(false));
      } else {
        toast.success("Checkin revertido com sucesso!", toastOptions);
        dispatch(setCurrentAppointmentStatus("quitado"));
        dispatch(setIsUpdatingAppointment(false));
      }
    } catch (error: any) {
      if (error.response) {
        dispatch(setIsUpdatingAppointment(false));
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const checkinWithoutAppointment =
  ({
    order_item_ids,
    idprofissional,
    cb,
    idcheckin,
    idclinica,
    idconsultorio,
    usoprontuario,
    forceFinish,
  }: {
    order_item_ids: string[];
    idclinica: string;
    idcheckin?: string;
    idprofissional?: string;
    idconsultorio?: string;
    usoprontuario?: boolean;
    forceFinish?: boolean;
    cb?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsUpdatingAppointment, setCurrentAppointmentStatus } =
      appointmentsSlice.actions;
    try {
      await api.post(`/api/totems/checkin`, {
        order_item_ids: order_item_ids,
        idcheckin: idcheckin,
        idprofissional: idprofissional,
        idclinica: idclinica,
        idconsultorio: idconsultorio,
        usoprontuario: usoprontuario,
        forceFinish: forceFinish,
      });
      if (cb) {
        cb();
      }
      // fetchAppointmentById(appointmentId);
      if (idcheckin) {
        toast.success("Checkin revertido com sucesso!", toastOptions);
        dispatch(setCurrentAppointmentStatus("quitado"));
      } else {
        toast.success("Checkin realizado com sucesso!", toastOptions);
        dispatch(setCurrentAppointmentStatus("checkin"));
      }
      dispatch(setIsUpdatingAppointment(false));
    } catch (error: any) {
      if (error.response) {
        dispatch(setIsUpdatingAppointment(false));
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchAppointmentByServiceOrderId =
  (serviceOrderId: string, cb?: any): AppThunk =>
  async (dispatch) => {
    const { setIsFetchingAppointments, setCurrentAppointments } =
      appointmentsSlice.actions;
    // dispatch(setIsFetchingAppointments(true));
    try {
      const response = await api.get(
        `/api/appointments/?idordemservico=${serviceOrderId}`
      );
      // dispatch(setCurrentAppointment(response.data.data));
      // dispatch(setCurrentAppointmentStatus(response.data.data.status))
      dispatch(setCurrentAppointments(response.data.data.appointments));
      // dispatch(setIsFetchingAppointments(false));
      cb && cb();
    } catch (error: any) {
      dispatch(setIsFetchingAppointments(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchAppointmentById =
  (appointmentId: string, cb?: any, cbWithResponse = false): AppThunk =>
  async (dispatch) => {
    const {
      setIsFetchingAppointments,
      setCurrentAppointment,
      setCurrentAppointmentStatus,
    } = appointmentsSlice.actions;
    dispatch(setIsFetchingAppointments(true));
    try {
      const response = await api.get(`/api/appointments/${appointmentId}`);
      dispatch(setCurrentAppointment(response.data.data));
      dispatch(setCurrentAppointmentStatus(response.data.data.status));
      dispatch(setIsFetchingAppointments(false));
      // dispatch(setCurrentAppointmentOS(response.data.data.orderitems[0].idordemservico));
      if (cb) {
        cbWithResponse && cb(response.data.data);
        !cbWithResponse && cb();
      }
    } catch (error: any) {
      dispatch(setIsFetchingAppointments(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const finishAppointmentWithItemsId =
  ({
    appointmentId,
    orderItems,
    data,
  }: {
    appointmentId: string;
    orderItems: string[];
    data?: Date;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsFinishingAppointment } = appointmentsSlice.actions;
    dispatch(setIsFinishingAppointment(true));
    try {
      await api.patch(`api/appointments/${appointmentId}/finish`, {
        itensordem: orderItems,
        data: data,
      });
      toast.success("Serviço Atendido", toastOptions);
      dispatch(fetchAppointmentById(appointmentId));
      dispatch(setIsFinishingAppointment(false));
    } catch (error: any) {
      if (error.response) {
        dispatch(setIsFinishingAppointment(false));
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const finishAppointmentWithGlobal =
  ({
    appointmentId,
    orderItems,
    data,
  }: {
    appointmentId: string;
    orderItems: string[];
    data?: Date;
  }): AppThunk =>
  async (dispatch) => {
    const { setIsFinishingAppointment } = appointmentsSlice.actions;
    dispatch(setIsFinishingAppointment(true));
    try {
      await api.patch(`api/appointments/${appointmentId}/finish/all`, {
        itensordem: orderItems,
        data: data,
      });
      toast.success("Serviço Atendido", toastOptions);
      dispatch(fetchAppointmentById(appointmentId));
      dispatch(setIsFinishingAppointment(false));
    } catch (error: any) {
      if (error.response) {
        dispatch(setIsFinishingAppointment(false));
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const finishAppointmentWithItemsIdAndFetchAppointments =
  ({
    appointmentId,
    orderItems,
    roomId,
  }: {
    appointmentId: string;
    orderItems: string[];
    roomId: string;
  }): AppThunk =>
  async (dispatch) => {
    await dispatch(finishAppointmentWithItemsId({ appointmentId, orderItems }));
    dispatch(fetchOfficePatients(roomId));
  };

export const finishAppointmentWithItemsIdAndFetchAppointmentsToExamsWaitingRoom =

    ({
      appointmentId,
      orderItems,
      data,
    }: {
      appointmentId: string;
      orderItems: string[];
      data?: Date;
    }): AppThunk =>
    async (dispatch, getState) => {
      const state = getState();
      const { patientAttended } = state;

      await dispatch(
        finishAppointmentWithItemsId({ appointmentId, orderItems, data })
      );
      dispatch(
        fetchPatientsAttended({
          page: patientAttended.page,
          limit: patientAttended.limit,
        })
      );
      dispatch(fetchCheckinsWithoutAppointment({}));
    };

export const finishAttendancetWithItemsId =
  ({
    idCheckin,
    idorderitems,
    idMedicalRecord,
    data,
  }: {
    idCheckin: string;
    idorderitems: string[];
    idMedicalRecord: string;
    data?: Date;
  }): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const { patientAttended } = state;

    const { setIsFinishingAppointment } = appointmentsSlice.actions;
    dispatch(setIsFinishingAppointment(true));
    try {
      await api.put(`/api/attendance/finalize-order-items`, {
        idCheckin: idCheckin,
        idorderitems: idorderitems,
        idMedicalRecord: idMedicalRecord,
        data: data,
      });
      toast.success("Serviço(s) Atendido", toastOptions);
      dispatch(
        fetchPatientsAttended({
          page: patientAttended.page,
          limit: patientAttended.limit,
        })
      );
      dispatch(fetchCheckinsWithoutAppointment({}));
      dispatch(setIsFinishingAppointment(false));
    } catch (error: any) {
      if (error.response) {
        dispatch(setIsFinishingAppointment(false));
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const setDateFinish =
  ({
    idCheckin,
    idorderitems,
    data,
  }: {
    idCheckin: string;
    idorderitems: string[];
    idMedicalRecord: string;
    data?: Date;
  }): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const { patientAttended } = state;

    const { setIsFinishingAppointment } = appointmentsSlice.actions;
    dispatch(setIsFinishingAppointment(true));
    try {
      await api.put(`/api/attendance/date-finalize-order-items`, {
        idCheckin: idCheckin,
        idorderitems: idorderitems,
        data: data,
      });
      toast.success("Data de atendimento atualizada", toastOptions);
      dispatch(
        fetchPatientsAttended({
          page: patientAttended.page,
          limit: patientAttended.limit,
        })
      );
      dispatch(fetchCheckinsWithoutAppointment({}));
      dispatch(setIsFinishingAppointment(false));
    } catch (error: any) {
      if (error.response) {
        dispatch(setIsFinishingAppointment(false));
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const setDateFinishAll =
  ({
    idCheckin,
    idorderitems,
    data,
  }: {
    idCheckin: string;
    idorderitems: string[];
    data?: Date;
  }): AppThunk =>
  async (dispatch, getState) => {
    const state = getState();
    const { patientAttended } = state;

    const { setIsFinishingAppointment } = appointmentsSlice.actions;
    dispatch(setIsFinishingAppointment(true));
    try {
      await api.put(`/api/attendance/date-finalize-order-all`, {
        idCheckin: idCheckin,
        idorderitems: idorderitems,
        data: data,
      });
      toast.success("Data de atendimento atualizada", toastOptions);
      dispatch(
        fetchPatientsAttended({
          page: patientAttended.page,
          limit: patientAttended.limit,
        })
      );
      dispatch(fetchCheckinsWithoutAppointment({}));
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsFinishingAppointment(false));
    }
  };

export const fetchAppointmentsOfDayByUser =
  ({
    idpaciente,
    day,
    cb,
  }: {
    idpaciente: string;
    day: string;
    cb?: any;
  }): AppThunk =>
  async (dispatch) => {
    const { setAppointmentSummary } = appointmentsSlice.actions;
    try {
      dispatch(setAppointmentSummary([]));
      const response = await api.get(
        `/api/appointments/?idpaciente=${idpaciente}&data=${day}`
      );
      dispatch(setAppointmentSummary(response.data.data.appointments));
      cb && cb();
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchProfessionalFutureAppointments =
  ({
    idprofissional,
    page = 1,
    limit = 20,
    cb,
  }: {
    page?: number;
    limit?: number;
    idprofissional: string;
    cb?: any;
  }): AppThunk =>
  async (dispatch, getState) => {
    const { setFutureAppointments, setFutureAppointmentsInfo } =
      appointmentsSlice.actions;
    try {
      const state = getState();
      const { futureAppointmentsFilterArray } = state.appointments;
      const queryParameters = queryStringFromFilterArray(
        futureAppointmentsFilterArray
      );
      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;
      setFutureAppointments([]);
      const response = await api.get(
        `/api/appointments${queryParameters}${pageAndLimit}&idprofissional=${idprofissional}`
      );
      dispatch(setFutureAppointmentsInfo(response.data.data));
      dispatch(setFutureAppointments(response.data.data.appointments));
      cb && cb();
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchOfficePatients =
  (officeId: string, cb?: any): AppThunk =>
  async (dispatch, getState) => {
    const {
      setIsFetchingAppointments,
      setAppointmentsHasCheckin,
      setIsUpdatingCheckinsFilter,
      setIsFetchingAppointmentsWithoutTimeout,
    } = appointmentsSlice.actions;
    dispatch(setIsFetchingAppointments(true));
    try {
      const state = getState();
      const { checkinsFilterArray } = state.appointments;
      const queryParameters = queryStringFromFilterArray(checkinsFilterArray);
      const response = await api.get(
        `/api/professional/office/${officeId}/patients${queryParameters}`
      );
      dispatch(setAppointmentsHasCheckin(response.data.data.attendanceNumber));
      dispatch(setIsFetchingAppointments(false));
      dispatch(setIsFetchingAppointmentsWithoutTimeout(false));
      dispatch(setIsUpdatingCheckinsFilter(false));
      if (cb) cb();
    } catch (error: any) {
      dispatch(setIsFetchingAppointments(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const StartUpdateOfficePatientsFetchInterval =
  (officeId: string, cb?: any): AppThunk =>
  async (dispatch, getState) => {
    const { setUpdateOfficePatientsFetchIntervalId } =
      appointmentsSlice.actions;

    const state = getState();
    const { updateOfficePatientsFetchIntervalId } = state.appointments;

    if (updateOfficePatientsFetchIntervalId) {
      clearInterval(updateOfficePatientsFetchIntervalId);
    }

    var _tid = setInterval(() => {
      const location = window.location.href.split("/");
      const inSchedulePage = location[location.length - 1].includes("sala");

      if (inSchedulePage) {
        dispatch(fetchOfficePatients(officeId, cb));
      } else {
        dispatch(ClearUpdateOfficePatientsFetchInterval());
      }
    }, 10000);

    dispatch(setUpdateOfficePatientsFetchIntervalId(_tid));
  };

export const ClearUpdateOfficePatientsFetchInterval =
  (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const { updateOfficePatientsFetchIntervalId } = state.appointments;

    if (updateOfficePatientsFetchIntervalId) {
      clearInterval(updateOfficePatientsFetchIntervalId);
    }
  };

export const fetchCheckinsWithoutAppointment =
  ({
    page = 1,
    limit = 20,
    cb,
  }: {
    page?: number;
    limit?: number;
    cb?: any;
  }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsFetchingAppointments, setCheckinsWithoutAppointment } =
      appointmentsSlice.actions;
    dispatch(setIsFetchingAppointments(true));
    try {
      const state = getState();
      const { checkinsWithoutAppointmentsFilterArray } = state.appointments;
      const queryParameters = queryStringFromFilterArray(
        checkinsWithoutAppointmentsFilterArray
      );
      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;
      const temconsulta =
        queryParameters.length === 0
          ? "?tem_consulta=false"
          : "&tem_consulta=false";
      const response = await api.get(
        `/api/professional/patients${queryParameters}${temconsulta}`
      );
      dispatch(
        setCheckinsWithoutAppointment(response.data.data.attendanceNumber)
      );
      dispatch(setIsFetchingAppointments(false));
      if (cb) cb();
    } catch (error: any) {
      dispatch(setIsFetchingAppointments(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const StartUpdatePatientsWithoutAppointmentFecthInterval =
  (): AppThunk => async (dispatch, getState) => {
    const { setUpdatePatientsWithoutAppointmentFecthIntervalId } =
      appointmentsSlice.actions;

    const state = getState();
    const { updatePatientsWithoutAppointmentFecthIntervalId } =
      state.appointments;

    if (updatePatientsWithoutAppointmentFecthIntervalId) {
      clearInterval(updatePatientsWithoutAppointmentFecthIntervalId);
    }

    var _tid = setInterval(() => {
      const location = window.location.href.split("/");
      const inSchedulePage = location[location.length - 1].includes("sala");

      if (inSchedulePage) {
        dispatch(fetchCheckinsWithoutAppointment({}));
      } else {
        dispatch(ClearUpdatePatientsWithoutAppointmentFecthInterval());
      }
    }, 10000);

    dispatch(setUpdatePatientsWithoutAppointmentFecthIntervalId(_tid));
  };

export const ClearUpdatePatientsWithoutAppointmentFecthInterval =
  (): AppThunk => async (dispatch, getState) => {
    const state = getState();
    const { updatePatientsWithoutAppointmentFecthIntervalId } =
      state.appointments;

    if (updatePatientsWithoutAppointmentFecthIntervalId) {
      clearInterval(updatePatientsWithoutAppointmentFecthIntervalId);
    }
  };

export const addOrderServices =
  (appointmentIdArray: string[], cb: Function, join_items: boolean): AppThunk =>
  async (dispatch) => {
    const { setIsCreatingOS } = appointmentsSlice.actions;
    dispatch(setIsCreatingOS(true));
    try {
      await api.post(`/api/appointments-create-service-order`, {
        idagendamento: appointmentIdArray,
        join_items,
      });
      dispatch(
        fetchAppointmentById(
          appointmentIdArray[appointmentIdArray.length - 1],
          cb
        )
      );
      toast.success("Ordem de serviço criada com sucesso!", toastOptions);
      dispatch(setIsCreatingOS(false));
    } catch (error: any) {
      dispatch(setIsCreatingOS(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const verifyCheckinPatient =
  (appointmentId: string): AppThunk =>
  async (dispatch) => {
    try {
      const res = await api.get(`/api/totems?idconsulta=${appointmentId}`);
      return res.data.data;
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const createAppointmentFromServiceOrderItem =
  ({
    appointment,
    callback,
  }: {
    appointment: IAppointmentFromServiceOrderForm;
    callback?: (idconsulta: string) => void;
  }): AppThunk =>
  async (dispatch, getState) => {
    const { setIsCreatingAppointment } = appointmentsSlice.actions;
    dispatch(setIsCreatingAppointment(true));
    // const newAppointment: IAppointmentForm = {
    //   ...newAppointmentData,
    //   data: newAppointmentData.data.toString().replace(".000Z", ""),
    //   generateserviceorder: false,
    // };
    try {
      const response = await api.post(`/api/appointments-from-service-orders`, {
        appointment,
      });
      if (appointment.checkin) {
        toast.success(
          "Serviço da Ordem agendado, tentativa de check-in realizada. Para confirmar revise o agendamento.",
          toastOptions
        );
      } else {
        toast.success(
          `Serviço da Ordem ${
            appointment.remarcarItem ? "remarcado" : "agendado"
          }`,
          toastOptions
        );
      }
      const {
        disponibilityMap: { disponibilityMapFilterArray, currentServiceItems },
      } = getState();
      const disponibilityMapServiceItems = disponibilityMapFilterArray.find(
        (filter) => filter.key === "iditemservico"
      );
      if (disponibilityMapServiceItems && disponibilityMapServiceItems.value) {
        if (appointment.checkin) {
          const newAppointmentItems: string[] =
            response.data.data.appointment.orderitems.map(
              (item: any) => item.iditemservico
            );
          newAppointmentItems.forEach((item) => {
            dispatch(removeServiceItemToBeScheduled({ serviceItemId: item }));
          });
          dispatch(setIsServiceItemDialogOpen(true));
        }
        if (!appointment.checkin) {
          const newAppointmentItems: string[] =
            response.data.data.orderitems.map(
              (item: any) => item.iditemservico
            );
          newAppointmentItems.forEach((item) => {
            dispatch(removeServiceItemToBeScheduled({ serviceItemId: item }));
          });
          dispatch(setIsServiceItemDialogOpen(true));
        }
        // @ts-ignore
        // const newDisponibilityMapServiceItemsFilterArray = disponibilityMapServiceItems.value.filter(
        //   (item: ISelectOption) => !newAppointmentItems.includes(item.value)
        // );
        // const newDisponibilityMapServiceItemsOptions = currentServiceItems.filter(
        //   (item) => !newAppointmentItems.includes(item.value)
        // );
        // newAppointmentItems.forEach((item) => {
        //   dispatch(removeServicePacakgeToBeScheduled({ serviceItemId: item }));
        // });
      }
      if (callback && !appointment.checkin) {
        callback(response.data.data.idconsulta);
      }
      if (callback && appointment.checkin) {
        callback(response.data.data.appointment.idconsulta);
      }
      dispatch(setIsCreatingAppointment(false));
    } catch (error: any) {
      dispatch(setIsCreatingAppointment(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchHistory =
  ({
    appointmentId,
    serviceOrderId,
    customerQuoteId,
  }: {
    appointmentId?: string;
    serviceOrderId?: string;
    customerQuoteId?: string;
  }): AppThunk =>
  async (dispatch) => {
    const {
      setIsFetchingHistory,
      setAppointmentsHistory,
      setServiceOrderHistory,
      setCustomerQuoteHistory,
    } = appointmentsSlice.actions;
    dispatch(setIsFetchingHistory(true));
    try {
      if (appointmentId) {
        const response = await api.get(
          `/api/changelogs?idconsulta=${appointmentId}`
        );
        console.log("resposta appointmentId", response);
        dispatch(setAppointmentsHistory(response.data.data.documents));
      }
      if (serviceOrderId) {
        const response = await api.get(
          `/api/changelogs?idordemservico=${serviceOrderId}`
        );
        console.log("resposta serviceOrderId", response);
        dispatch(setServiceOrderHistory(response.data.data.documents));
      }
      if (customerQuoteId) {
        const response = await api.get(
          `/api/changelogs?idorcamento=${customerQuoteId}`
        );
        console.log("resposta customerQuoteId", response);
        dispatch(setCustomerQuoteHistory(response.data.data.documents));
      }
      // console.log("ag ===>", response.data.data);
      // dispatch(setCheckinsWithoutAppointment(response.data.data.attendanceNumber));
      dispatch(setIsFetchingHistory(false));
    } catch (error: any) {
      dispatch(setIsFetchingHistory(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const createHistory =
  ({
    appointmentId,
    serviceOrderId,
    customerQuoteId,
    descricao,
    observacao,
    disableToast,
    callback,
  }: {
    appointmentId?: string;
    serviceOrderId?: string;
    customerQuoteId?: string;
    descricao: string;
    observacao?: string;
    disableToast?: boolean;
    callback?: Function;
  }): AppThunk =>
  async (dispatch) => {
    const {
      setIsCreatingHistory,
      setAppointmentsHistory,
      setServiceOrderHistory,
    } = appointmentsSlice.actions;
    dispatch(setIsCreatingHistory(true));
    try {
      if (appointmentId) {
        const response = await api.post(`/api/changelogs`, {
          idconsulta: appointmentId,
          descricao: descricao,
          observacao: observacao,
        });
        // dispatch(setAppointmentsHistory(response.data.data))

        if (!disableToast) {
          toast.success("Histórico adicionado", toastOptions);
        }
      }
      if (serviceOrderId) {
        const response = await api.post(`/api/changelogs`, {
          idordemservico: serviceOrderId,
          descricao: descricao,
          observacao: observacao,
        });
        // dispatch(setServiceOrderHistory(response.data.data))
        if (!disableToast) {
          toast.success("Histórico adicionado", toastOptions);
        }
      }
      if (customerQuoteId) {
        const response = await api.post(`/api/changelogs`, {
          idorcamento: customerQuoteId,
          descricao: descricao,
          observacao: observacao,
        });
        // dispatch(setServiceOrderHistory(response.data.data))
        if (!disableToast) {
          toast.success("Histórico adicionado", toastOptions);
        }
      }
      if (callback) callback();
      // console.log("ag ===>", response.data.data);
      // dispatch(setCheckinsWithoutAppointment(response.data.data.attendanceNumber));
      dispatch(setIsCreatingHistory(false));
    } catch (error: any) {
      dispatch(setIsCreatingHistory(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };
export const clearCurrentAppointments = (): AppThunk => async (dispatch) => {
  const { setCurrentAppointment } = appointmentsSlice.actions;

  dispatch(setCurrentAppointment(null));
};

export const {
  updateCheckinsFilter,
  updateCheckinsTableFilter,
  setCheckinAfterSchedulingOsItem,
  updateCheckinsWithoutAppointmentFilter,
  updateFutureAppointmentsFilter,
  setServiceItemToBeScheduled,
  setIsServiceItemDialogOpen,
  removeServiceItemToBeScheduled,
  setIsSchedulingServiceItems,
  setRescheduleOrderItem,
  clearAppointments,
  setCurrentFilterOption,
  setCurrentTableFilterOption,
  setCurrentAttendance,
  setUpdateSchedulesFetchIntervalId,
  setUpdatePatientsWithoutAppointmentFecthIntervalId,
  setUpdateOfficePatientsFetchIntervalId,
  setCloseWindowEvent,
  setCheckinFilterlistGroup,
  setIsUpdatingAppointment,
  setIsFetchingAppointmentsWithoutTimeout,
  setIsFetchingAppointments,
  setIsUpdatingCheckinsFilter,
  setFilteredAppointmentsCheckinByClinic,
  setServiceItemsToScheduleFromWallet,
  setStepGuidance,
  setAppointmentSummary,
  setSchedulingViaSchedulingDialog,
  setModalBackToDialog,
  setReturnSchedulingModal,
  setAppointmentsHistory,
  setServiceOrderHistory,
  setCustomerQuoteHistory,
  setCurrentEvent,
  setCurrentAppointment,
} = appointmentsSlice.actions;

export default appointmentsSlice.reducer;
