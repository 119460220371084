import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { AppThunk } from "store";
import api from "utils/API";
import toastOptions from "utils/toastOptions";
import {
	HealthPlanFetchResponse,
	HealthPlanReducerState,
	HealthPlanRestrictionFetchResponse,
	ICreateHealthPlan,
	ICreateHealthPlanRestriction,
	ICreateTissSetup,
	IGuideSetup,
	INVOICE_ISSUANCE,
	ITissVersion,
} from "./HealthPlanReducer.types";
import { history } from "utils/history";

const initialState: HealthPlanReducerState = {
	healthPlans: [],
	allHealthPlans: [],
	healthPlan: {
		idconvenio: "",
		nome: "",
		razaosocial: "",
		cnpj: "",
		registroans: "",
		logourl: "",
		cep: "",
		bairro: "",
		cidade: "",
		estado: "",
		complemento: "",
		numero: "",
		endereco: "",
		responsavel: "",
		numerovidas: 0,
		ativo: false,
		descontos: false,
		cnes: "",
		codigocontratadoexecutante: "",
		codigooperadora: "",
		diasretorno: 0,
		diasenvioguias: 0,
		diasrecebimento: 0,
		diasrecursoglosas: 0,
		emissaonf: INVOICE_ISSUANCE.SEND_BILLING,
		envioguiaseletronicas: false,
		envioguiasfisicas: false,
		nomecontratadoexecutante: "",
		versaotissenvio: "",
		versaotissretorno: ""
	},
	healthPlanRestrictions: [],
	isFetchingHealthPlans: false,
	isFetchingHealthPlan: false,
	isCreatingHealthPlan: false,
	isUpdatingHealthPlan: false,
	listPage: 0,
	listLimit: 6,
	listTotalSize: 0,
	healthPlanFilterId: "",
	healthPlansByServiceItem: [],
	healthPlanTissSetup: [],
	healthPlanGuideSetup: [],
};

const healthPlanSlice = createSlice({
	name: "healthPlan",
	initialState,
	reducers: {
		setHealthPlans: (state, { payload }) => {
			state.healthPlans = payload;
		},
		setHealthPlan: (state, { payload }) => {
			state.healthPlan = payload;
		},
		setAllHealthPlans: (state, { payload }) => {
			state.allHealthPlans = payload;
		},
		setHealthPlanRestrictions: (state, { payload }) => {
			state.healthPlanRestrictions = payload;
		},
		setIsFetchingHealthPlans: (state, { payload }) => {
			state.isFetchingHealthPlans = payload;
		},
		setIsFetchingHealthPlan: (state, { payload }) => {
			state.isFetchingHealthPlan = payload;
		},
		setIsUpdatingHealthPlan: (state, { payload }) => {
			state.isUpdatingHealthPlan = payload;
		},
		setListPage: (state, { payload }) => {
			state.listPage = payload;
		},
		setListLimit: (state, { payload }) => {
			state.listLimit = payload;
		},
		setListTotalSize: (state, { payload }) => {
			state.listTotalSize = payload;
		},
		setIsCreatingHealthPlan: (state, { payload }) => {
			state.isCreatingHealthPlan = payload;
		},
		setHealthPlanFilterId: (state, { payload }) => {
			state.healthPlanFilterId = payload;
		},
		setHealthPlanByServiceItem: (state, { payload }) => {
			state.healthPlansByServiceItem = payload;
		},
		setHealthPlanTissSetup: (state, { payload }) => {
			state.healthPlanTissSetup = payload;
		},
		setHealthPlanGuideSetup: (state, { payload }) => {
			state.healthPlanGuideSetup = payload;
		},
	},
});

export const fetchHealthPlans =
	({
		page = 1,
		limit = 6,
	}: {
		page: number;
		limit: number;
		idconvenio?: string;
	}): AppThunk =>
	async (dispatch, getState) => {
		const {
			setHealthPlans,
			setListPage,
			setListTotalSize,
			setIsFetchingHealthPlans,
		} = healthPlanSlice.actions;
		dispatch(setIsFetchingHealthPlans(true));

		try {
			const { data }: HealthPlanFetchResponse = await api.get(
				`/api/healthplans?page=${page}&limit=${limit}`
			);
			dispatch(setHealthPlans(data.data.health_plans));
			dispatch(setListPage(data.data.page));
			dispatch(setListTotalSize(data.data.total));
		} catch (error: any) {
			console.log(error);

			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingHealthPlans(false));
		}
	};

export const fetchHealthPlanById =
	(healthPlanId: string): AppThunk =>
	async (dispatch, getState) => {
		const { setHealthPlan, setIsFetchingHealthPlan } = healthPlanSlice.actions;
		dispatch(setIsFetchingHealthPlan(true));
		try {
			const { data }: HealthPlanFetchResponse = await api.get(
				`/api/healthplans/${healthPlanId}`
			);
			dispatch(setHealthPlan(data.data));
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingHealthPlan(false));
		}
	};

export const fetchHealthPlansByServiceItemId =
	(service_item_id: string): AppThunk =>
	async (dispatch, getState) => {
		const { setHealthPlanByServiceItem, setIsFetchingHealthPlan } =
			healthPlanSlice.actions;
		dispatch(setIsFetchingHealthPlan(true));
		try {
			const { data }: HealthPlanFetchResponse = await api.get(
				`/api/service-items/healthplans?service_item_id=${service_item_id}`
			);
			dispatch(setHealthPlanByServiceItem(data.data));
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingHealthPlan(false));
		}
	};

	export const fetchHealthPlansByProfessionalId =
	(professional_id: string): AppThunk =>
	async (dispatch, getState) => {
		const { setHealthPlanByServiceItem, setIsFetchingHealthPlan } =
			healthPlanSlice.actions;
		dispatch(setIsFetchingHealthPlan(true));
		try {
			const { data }: HealthPlanFetchResponse = await api.get(
				`/api/professional/healthplans?professional_id=${professional_id}`
			);
			dispatch(setHealthPlanByServiceItem(data.data));
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingHealthPlan(false));
		}
	};

export const fetchHealthPlansByServiceItemsAndProfessional =
	({
		service_item_id,
		professional_id,
	}: {
		service_item_id: string;
		professional_id: string;
	}): AppThunk =>
	async (dispatch, getState) => {
		const { setHealthPlanByServiceItem, setIsFetchingHealthPlans } =
			healthPlanSlice.actions;
		dispatch(setIsFetchingHealthPlans(true));
		try {
			const { data } = await api.get(
				`/api/professional-service-items/healthplans?service_item_id=${service_item_id}&professional_id=${professional_id}`
			);
			dispatch(setHealthPlanByServiceItem(data.data));
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingHealthPlans(false));
		}
	};

export const fetchHealthPlansByServiceItemsAndProvider =
	({
		service_item_id,
		prestador_id,
	}: {
		service_item_id: string;
		prestador_id: string;
	}): AppThunk =>
	async (dispatch, getState) => {
		const { setHealthPlanByServiceItem, setIsFetchingHealthPlans } =
			healthPlanSlice.actions;
		dispatch(setIsFetchingHealthPlans(true));
		try {
			const { data } = await api.get(
				`/api/professional-service-items/healthplans?service_item_id=${service_item_id}&prestador_id=${prestador_id}`
			);
			dispatch(setHealthPlanByServiceItem(data.data));
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingHealthPlans(false));
		}
	};

export const fetchAllHealthPlans =
	(): AppThunk => async (dispatch, getState) => {
		const { setAllHealthPlans, setIsFetchingHealthPlans } =
			healthPlanSlice.actions;
		dispatch(setIsFetchingHealthPlans(true));

		try {
			const { data }: HealthPlanFetchResponse = await api.get(
				`/api/healthplans?page=1&limit=9999`
			);
			dispatch(setAllHealthPlans(data.data.health_plans));
		} catch (error: any) {
			console.log(error);

			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingHealthPlans(false));
		}
	};

	export const fetchHealthPlanRestrictions =
	(professional_id: string): AppThunk => async (dispatch, getState) => {
		const { setHealthPlanRestrictions, setIsFetchingHealthPlans } =
			healthPlanSlice.actions;
		dispatch(setIsFetchingHealthPlans(true));

		try {
			const { data }: HealthPlanRestrictionFetchResponse = await api.get(
				`/api/health-plan-restrictions?professional_id=${professional_id}`
			);

			dispatch(setHealthPlanRestrictions(data.data.health_plan_restrictions));
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.error.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingHealthPlans(false));
		}
	};

	
export const createHealthPlanRestriction =
(healthPlanRestriction: ICreateHealthPlanRestriction): AppThunk =>
async (dispatch, getState) => {
	dispatch(setIsCreatingHealthPlan(true));
	try {
		await api.post("/api/health-plan-restrictions", healthPlanRestriction);
		toast.success("Restrição para o convênio criada com sucesso");

		dispatch(fetchHealthPlanRestrictions(healthPlanRestriction.idprofissional));
	} catch (error: any) {
		console.log(error.response);
		if (error.response) {
			toast.error(error.response.data.error.message, toastOptions);
		}
	} finally {
		setIsCreatingHealthPlan(false);
	}
};

export const editHealthPlanRestriction =
	({
		idrestricao,
		limiteconvenios,
		idprofissional
	}: {
		idrestricao: string;
		limiteconvenios: number;
		idprofissional: string;
	}): AppThunk =>
	async (dispatch, getState) => {
		const { setIsFetchingHealthPlan } = healthPlanSlice.actions;
		dispatch(setIsFetchingHealthPlan(true));
		try {
			await api.put(`/api/health-plan-restrictions/${idrestricao}`, {
				limiteconvenios
			});
			toast.success("Convênio editado com sucesso");
			dispatch(fetchHealthPlanRestrictions(idprofissional));
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.error.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingHealthPlan(false));
		}
	};

export const deleteHealthPlanRestriction =
	({
		idrestricao,
		idprofissional
	}: {
		idrestricao: string;
		idprofissional: string;
	}): AppThunk =>
	async (dispatch, getState) => {
		const { setIsFetchingHealthPlan } = healthPlanSlice.actions;
		dispatch(setIsFetchingHealthPlan(true));
		try {
			await api.delete(`/api/health-plan-restrictions/${idrestricao}`);
			toast.success("Convênio desvinculado com sucesso");
			dispatch(fetchHealthPlanRestrictions(idprofissional));
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.error.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingHealthPlan(false));
		}
	};

export const createHealthPlan =
	(healthPlan: ICreateHealthPlan): AppThunk =>
	async (dispatch, getState) => {
		dispatch(setIsCreatingHealthPlan(true));
		try {
			const response = await api.post("/api/healthplans", healthPlan);
			toast.success("Convênio criado com sucesso");

			if (response.data.success) {
				history.push(`/health-plan/form/${response.data.data.idconvenio}`);
			}
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			setIsCreatingHealthPlan(false);
		}
	};

export const editHealthPlan =
	({
		idconvenio,
		body,
		closeDialog,
	}: {
		idconvenio: string;
		body: 
			ICreateHealthPlan | 
			ICreateTissSetup |
		 	ITissVersion | 
			{ logourl: string };
		closeDialog?: boolean;
	}): AppThunk =>
	async (dispatch, getState) => {
		const { setIsUpdatingHealthPlan } = healthPlanSlice.actions;
		dispatch(setIsUpdatingHealthPlan(true));
		try {
			const { data }: HealthPlanFetchResponse = await api.put(`/api/healthplans/${idconvenio}`, body);
			toast.success("Convênio editado com sucesso");

			if (closeDialog && data.success) {
				dispatch(fetchHealthPlanById(idconvenio))
			}
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsUpdatingHealthPlan(false));
		}
	};

export const deleteHealthPlan =
	(healthPlanId: string): AppThunk =>
	async (dispatch, getState) => {
		const { setIsFetchingHealthPlans } = healthPlanSlice.actions;
		dispatch(setIsFetchingHealthPlans(true));
		try {
			await api.delete(`/api/healthplans/${healthPlanId}`);
			dispatch(fetchAllHealthPlans());
			toast.success("Convênio excluído com sucesso");
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingHealthPlans(false));
		}
	};

export const insertHealthPlanTissSetup =
	({
		body,
		closeDialog,
	}: {
		body: ICreateTissSetup;
		closeDialog?: boolean;
	}): AppThunk =>
	async (dispatch, getState) => {
		const { setIsFetchingHealthPlan } = healthPlanSlice.actions;
		
		dispatch(setIsFetchingHealthPlan(true));

		try {
			const { data }: HealthPlanFetchResponse = await api.post(
				`/api/healthplan/tiss-setup`, 
				body
			);

			toast.success("Configuração TISS incluída com sucesso");

			if (closeDialog && data.success) {
				dispatch(fetchHealthPlanTissSetup({ idconvenio: body.idconvenio }))
			}
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingHealthPlan(false));
		}
	};

export const editHealthPlanTissSetup =
	({
		idconfiguracaotiss,
		body,
		closeDialog,
	}: {
		idconfiguracaotiss: string;
		body: ICreateTissSetup;
		closeDialog?: boolean;
	}): AppThunk =>
	async (dispatch, getState) => {
		const { setIsFetchingHealthPlan } = healthPlanSlice.actions;
		
		dispatch(setIsFetchingHealthPlan(true));

		try {
			const { data }: HealthPlanFetchResponse = await api.put(
				`/api/healthplan/tiss-setup/${idconfiguracaotiss}`, 
				body
			);

			toast.success("Configuração TISS editada com sucesso");

			if (closeDialog && data.success) {
				dispatch(fetchHealthPlanTissSetup({ idconvenio: body.idconvenio }))
			}
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingHealthPlan(false));
		}
	};

export const deleteHealthPlanTissSetup =
	({
		idconfiguracaotiss,
		idconvenio,
		closeDialog,
	}: {
		idconfiguracaotiss: string;
		idconvenio: string;
		closeDialog?: boolean;
	}): AppThunk =>
	async (dispatch, getState) => {
		const { setIsFetchingHealthPlan } = healthPlanSlice.actions;
		
		dispatch(setIsFetchingHealthPlan(true));

		try {
			const { data }: HealthPlanFetchResponse = await api.delete(
				`/api/healthplan/tiss-setup/${idconfiguracaotiss}`);

			toast.success("Configuração TISS modificada com sucesso");

			if (closeDialog && data.success) {
				dispatch(fetchHealthPlanTissSetup({ idconvenio }))
			}
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingHealthPlan(false));
		}
	};

export const fetchHealthPlanTissSetup =
	({
		idconvenio,
		typeGuide
	}: {
		idconvenio: string;
		typeGuide?: string;
	}): AppThunk =>
	async (dispatch, getState) => {
		const { setIsFetchingHealthPlan, setHealthPlanTissSetup } = healthPlanSlice.actions;
		
		dispatch(setIsFetchingHealthPlan(true));

		try {
			const response = await api.get(
				`/api/healthplan/tiss-setup?idconvenio=${idconvenio}&ativo=true&${typeGuide ? "&tipoguia="+ typeGuide : ""}`,
			);

			dispatch(setHealthPlanTissSetup(response.data.data.health_plan_tiss_setups));
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingHealthPlan(false));
		}
	};

export const insertHealthPlanGuideSetup =
	({
		body,
		closeDialog,
	}: {
		body: IGuideSetup;
		closeDialog?: boolean;
	}): AppThunk =>
	async (dispatch, getState) => {
		const { setIsFetchingHealthPlan } = healthPlanSlice.actions;
		
		dispatch(setIsFetchingHealthPlan(true));

		try {
			const { data }: HealthPlanFetchResponse = await api.post(
				`/api/healthplan/guide-setup`, 
				body
			);

			toast.success("Configuração incluída com sucesso");

			if (closeDialog && data.success) {
				dispatch(fetchHealthPlanGuideSetup({ idconvenio: body.idconvenio }))
			}
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingHealthPlan(false));
		}
	};

export const editHealthPlanGuideSetup =
	({
		idconfiguracaoguia,
		body,
		closeDialog,
	}: {
		idconfiguracaoguia: string;
		body: IGuideSetup;
		closeDialog?: boolean;
	}): AppThunk =>
	async (dispatch, getState) => {
		const { setIsFetchingHealthPlan } = healthPlanSlice.actions;
		
		dispatch(setIsFetchingHealthPlan(true));

		try {
			const { data }: HealthPlanFetchResponse = await api.put(
				`/api/healthplan/guide-setup/${idconfiguracaoguia}`, 
				body
			);

			toast.success("Configuração editada com sucesso");

			if (closeDialog && data.success) {
				dispatch(fetchHealthPlanGuideSetup({ idconvenio: body.idconvenio }))
			}
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingHealthPlan(false));
		}
	};

export const deleteHealthPlanGuideSetup =
	({
		idconfiguracaoguia,
		idconvenio,
		closeDialog,
	}: {
		idconfiguracaoguia: string;
		idconvenio: string;
		closeDialog?: boolean;
	}): AppThunk =>
	async (dispatch, getState) => {
		const { setIsFetchingHealthPlan } = healthPlanSlice.actions;
		
		dispatch(setIsFetchingHealthPlan(true));

		try {
			await api.delete(
				`/api/healthplan/guide-setup/${idconfiguracaoguia}`);

			toast.success("Configuração removida com sucesso");

			if (closeDialog) {
				dispatch(fetchHealthPlanGuideSetup({ idconvenio }))
			}
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingHealthPlan(false));
		}
	};

export const fetchHealthPlanGuideSetup =
	({
		idconvenio,
		typeGuide
	}: {
		idconvenio: string;
		typeGuide?: string;
	}): AppThunk =>
	async (dispatch, getState) => {
		const { setIsFetchingHealthPlan, setHealthPlanGuideSetup } = healthPlanSlice.actions;
		
		dispatch(setIsFetchingHealthPlan(true));

		try {
			const response = await api.get(
				`/api/healthplan/guide-setup?idconvenio=${idconvenio}&${typeGuide ? "&tipoguia="+ typeGuide : ""}`,
			);

			dispatch(setHealthPlanGuideSetup(response.data.data.health_plan_guide_setups));
		} catch (error: any) {
			console.log(error);
			if (error.response) {
				toast.error(error.response.data.message, toastOptions);
			}
		} finally {
			dispatch(setIsFetchingHealthPlan(false));
		}
	};

export const {
	setHealthPlans,
	setIsFetchingHealthPlans,
	setIsCreatingHealthPlan,
	setListPage,
	setListTotalSize,
	setListLimit,
	setHealthPlanFilterId,
	setHealthPlanByServiceItem
} = healthPlanSlice.actions;

export default healthPlanSlice.reducer;
