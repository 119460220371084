import {
  AnyAction,
  createSlice,
  PayloadAction,
  ThunkAction,
} from "@reduxjs/toolkit";
import {
  IServiceItem,
  IServiceItemForm,
  IServiceItemToAddInFavoritesList,
  IServiceItemWithProfessional,
} from "models/ServiceItem";
import {
  IEditServiceProfessionalBatch,
  IServiceProfessional,
  IServiceProfessionalBatch,
  IServiceProfessionalForm,
  IServiceHistory,
  IUserRestriction,
} from "models/ServiceProfessional";
import { IFilter, IReactSelectFilter } from "models/shared/index";
import { toast } from "react-toastify";
import { rootState } from "store/index";
import api from "utils/API";
import { history } from "utils/history";
import { queryStringFromFilterArray } from "utils/network";
import toastOptions from "utils/toastOptions";

export interface IItemServicoFromComboToSchedule {
  idcombo: string;
  iditemcombo: string;
  idorderitem: string;
  idordemservico: string;
  idagendamento: string | null;
  iditemservico: string;
  idusuario: string | null;
  status: string;
  quantidade: number;
  valorbase: number;
  valoratual: string;
  iddesconto: string | null;
  idpedidoexame: string | null;
  idcheckin: string | null;
  idprofissionalsolicitante: string | null;
  datacriacao: Date;
  dataatualizacao: Date;
  itemservicos: {
    agendamento: boolean;
    iditemservico: string;
    nome: string;
    descricao: string;
    idcategoriaservico: string;
    iditemvinculado: string | null;
    valor: number;
    descontoavista: {
      tipo: string;
      valor: number;
    };
    categoriaservico: {
      idcategoriaservico: string;
      nome: string;
      descricao: string;
      ativo: boolean;
      gruposervico: {
        idgruposervico: string;
        nome: string;
        descricao: string;
        ativo: boolean;
      };
    };
  };
  combo: {
    datacriacao: Date;
    idcombo: string;
    titulo: string;
    desconto: {
      tipo: string;
      valor: number;
    };
    datavalidade: string;
    ativo: boolean;
    dataatualizacao: Date;
  };
}

export interface IServiceOrdesToScheduleFromCombo {
  idordemdeservico: string;
  codigo: string;
  idusuario: string;
  idpaciente: string;
  valorbase: number;
  valoratual: number;
  status: string;
  valordesconto: number;
  valoracrescimo: number;
  datacriacao: Date;
  dataatualizacao: Date;
  ordemitemservico: IItemServicoFromComboToSchedule[];
}

export interface IPatientsCombosToSchedule {
  stats: {
    tota: number;
    livre: number;
  };
  service_orders: IServiceOrdesToScheduleFromCombo[];
  page: number;
  total: number;
}

interface IDeleteScheduleError {
  scheduleName: string;
  isException: boolean;
}

interface IInitialState {
  isFetchingUserComboItemsWaitingSchedule: boolean;
  userComboItemsWaitingSchedule: IPatientsCombosToSchedule | null;
  isFetchingUserComboItemsWaitingScheduleClosed: boolean;
  userComboItemsWaitingScheduleClosed: IPatientsCombosToSchedule | null;
  isFetchingUserRestrictions: boolean;
  isCreatingUserRestrictions: boolean;
  isFetchingEditServiceItems: boolean;
  isFetchingServiceItems: boolean;
  isCreatingServiceItem: boolean;
  isDeletingServiceItem: boolean;
  isToAddProfessional: boolean;
  isEditBondDialogOpen: boolean;
  isFetchingServiceItemHistory: boolean;
  isCreatingServiceItemHistory: boolean;
  serviceItemHistory: IServiceHistory[];
  isFetchingServiceProfessionalHistory: boolean;
  isCreatingServiceProfessionalHistory: boolean;
  serviceProfessionalHistory: IServiceHistory[];
  serviceItems: IServiceItem[] | null;
  featuredServiceItems: IServiceItem[] | null;
  serviceItemsAppointmentWeb: IServiceItem[] | null;
  serviceItemsSelectOptions: {
    name: string;
    id: string;
    outside: boolean;
    category: string;
  }[];
  currentIdProfessional: string;
  currentIdServiceItem: string;
  currentServiceItem: IServiceItem | null;
  currentServiceCategory: IReactSelectFilter;
  toEditPage: number;
  toEditTotal: number;
  toAddPage: number;
  toAddTotal: number;
  total: number;
  page: number;
  pageToComboItemsWaitingSchedule: number;
  pageToServiceItems: number;
  amountOfItemsToShow: number;
  toAddFilterArray: IFilter[];
  serviceItemsOriginalFilterArray: IFilter[];
  serviceItemsFilterArray: IFilter[];
  serviceItemsSchedulePageFilterArray: IFilter[];
  isFetchingServiceProfessionals: boolean;
  isCreatingServiceProfessional: boolean;
  isCreatingServiceProfessionalBatch: boolean;
  isEditingServiceProfessionalBatch: boolean;
  isDeletingServiceProfessional: boolean;
  serviceProfessionals: IServiceProfessional[] | null;
  currentServiceProfessional: IServiceProfessional | null;
  serviceItemByCategory: IServiceItem[] | null;
  serviceProfessionalsTotal: number;
  serviceProfessionalsPage: number;
  serviceProfessionalsIncluded: IServiceProfessional[];
  editServiceItemProfessional: IServiceProfessional[];
  serviceItemProfessional: IServiceProfessional[];
  serviceItemsWithProfessional: IServiceItemWithProfessional[];
  serviceItemsToAddProfessional: IServiceItemWithProfessional[];
  serviceItemsToAddInFavoritesList: IServiceItemToAddInFavoritesList[];
  isFetchingServiceItemsToAddInFavoritesList: boolean;
  serviceItemsToAddInFavoritesListFilter: IFilter[];
  userRestrictions: IUserRestriction[];
  editServiceItemsToProfessionalRowsPerPage: number;
  editServiceItemsToProfessionalPage: number;
  serviceItemsToProfessionalRowsPerPage: number;
  serviceItemsToProfessionalPage: number;
  serviceItemsToExternalPage: number;
  serviceItemsToExternalRowsPerPage: number;
	serviceItemsStandardTableFilterArray: IFilter[];
  serviceProfessionalDeleteErrorData: IDeleteScheduleError[];
}

const initialState: IInitialState = {
  isFetchingUserComboItemsWaitingSchedule: false,
  userComboItemsWaitingSchedule: null,
  isFetchingUserComboItemsWaitingScheduleClosed: false,
  userComboItemsWaitingScheduleClosed: null,
  isFetchingUserRestrictions: false,
  isCreatingUserRestrictions: false,
  isFetchingServiceItems: false,
  isFetchingEditServiceItems: false,
  isCreatingServiceItem: false,
  isDeletingServiceItem: false,
  isEditBondDialogOpen: false,
  toAddPage: 0,
  toAddTotal: 0,
  toEditPage: 0,
  toEditTotal: 0,
  serviceItems: null,
  featuredServiceItems: null,
  serviceItemsAppointmentWeb: null,
  currentIdProfessional: "",
  currentIdServiceItem: "",
  serviceProfessionalsIncluded: [],
  serviceItemsSelectOptions: [],
  currentServiceItem: null,
  currentServiceCategory: {
    label: "",
    value: "",
  },
  total: 0,
  serviceItemByCategory: null,
  toAddFilterArray: [
    { key: "idcategoriaservico", value: null },
    { key: "nome", value: [] },
    { key: "valor", value: [] },
  ],
  page: 0,
  pageToComboItemsWaitingSchedule: 1,
  pageToServiceItems: 1,
  amountOfItemsToShow: 6,
  serviceItemsOriginalFilterArray: [{ key: "idcategoriaservico", value: null }],
  serviceItemsFilterArray: [
    { key: "idcategoriaservico", value: null },
    { key: "idplano", value: null },
    { key: "idconvenio", value: null },
    { key: "nome", value: [] },
    { key: "valor", value: [] },
    { key: "ativo", value: null },
    { key: "codigo", value: []}
  ],
  serviceItemsSchedulePageFilterArray: [
    { key: "idcategories", value: null },
    { key: "idprofessionals", value: null },
    { key: "search", value: null },
  ],
  isFetchingServiceProfessionals: false,
  isCreatingServiceProfessional: false,
  isCreatingServiceProfessionalBatch: false,
  isEditingServiceProfessionalBatch: false,
  isDeletingServiceProfessional: false,
  serviceProfessionals: null,
  currentServiceProfessional: null,
  serviceProfessionalsTotal: 0,
  serviceProfessionalsPage: 0,
  serviceItemProfessional: [],
  editServiceItemProfessional: [],
  userRestrictions: [],
  serviceItemsWithProfessional: [],
  serviceItemsToAddProfessional: [],
  serviceItemHistory: [],
  isCreatingServiceItemHistory: false,
  isFetchingServiceItemHistory: false,
  serviceProfessionalHistory: [],
  isCreatingServiceProfessionalHistory: false,
  isFetchingServiceProfessionalHistory: false,
  isToAddProfessional: false,
  editServiceItemsToProfessionalRowsPerPage: 6,
  editServiceItemsToProfessionalPage: 0,
  serviceItemsToProfessionalRowsPerPage: 6,
  serviceItemsToProfessionalPage: 0,
  serviceItemsToExternalPage: 1,
  serviceItemsToExternalRowsPerPage: 6,
  serviceItemsStandardTableFilterArray: [
    { key: "idversaotabela", value: null }
  ],
  serviceProfessionalDeleteErrorData: [],
  serviceItemsToAddInFavoritesList: [],
  isFetchingServiceItemsToAddInFavoritesList: false,
  serviceItemsToAddInFavoritesListFilter: [
    { key: "idcategoriaservico", value: null },
    { key: "nome", value: [] },
  ],
};

const serviceItemsSlice = createSlice({
  name: "serviceItemsSlice",
  initialState,
  reducers: {
    setIsFetchingUserCombosWaitingSchedule: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingUserComboItemsWaitingSchedule = payload;
    },
    setUserComboItemsWaitingSchedule: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      state.userComboItemsWaitingSchedule = payload;
    },
    setIsFetchingUserCombosWaitingScheduleClosed: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingUserComboItemsWaitingScheduleClosed = payload;
    },
    setUserComboItemsWaitingScheduleClosed: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      state.userComboItemsWaitingScheduleClosed = payload;
    },
    setIsFetchingServiceItems: (state, { payload }: PayloadAction<boolean>) => {
      state.isFetchingServiceItems = payload;
    },
    setIsFetchingEditServiceItems: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingEditServiceItems = payload;
    },
    setIsFetchingUserRestrictions: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingUserRestrictions = payload;
    },
    setIsCreatingUserRestrictions: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCreatingUserRestrictions = payload;
    },
    setIsToAddProfessional: (state, { payload }: PayloadAction<boolean>) => {
      state.isToAddProfessional = payload;
    },
    setIsCreatingServiceItem: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreatingServiceItem = payload;
    },
    setisDeletingServiceItem: (state, { payload }: PayloadAction<boolean>) => {
      state.isDeletingServiceItem = payload;
    },
    setIsEditBondDialogOpen: (state, { payload }: PayloadAction<boolean>) => {
      state.isEditBondDialogOpen = payload;
    },
    setEditServiceItemsToProfessionalRowsPerPage: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.editServiceItemsToProfessionalRowsPerPage = payload;
    },
    setEditServiceItemsToProfessionalPage: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.editServiceItemsToProfessionalPage = payload;
    },
    setServiceItemsToProfessionalRowsPerPage: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.serviceItemsToProfessionalRowsPerPage = payload;
    },
    setServiceItemsToProfessionalPage: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.serviceItemsToProfessionalPage = payload;
    },
    setServiceItemsToExternalRowsPerPage: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.serviceItemsToExternalRowsPerPage = payload;
    },
    setServiceItemsToExternalPage: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.serviceItemsToExternalPage = payload;
    },
    setServiceItems: (
      state,
      {
        payload: { service_items, total, page },
      }: PayloadAction<{
        service_items: IServiceItem[];
        total: number;
        page: number;
      }>
    ) => {
      state.serviceItems = service_items;
      state.total = total;
      state.page = page;
    },
    setFeaturedServiceItems: (
      state,
      {
        payload: { service_items, total, page },
      }: PayloadAction<{
        service_items: IServiceItem[];
        total: number;
        page: number;
      }>
    ) => {
      state.featuredServiceItems = service_items;
      state.total = total;
      state.page = page;
    },
    setServiceItemsAppointmentWeb: (
      state,
      {
        payload: { serviceItemsAppointmentWeb },
      }: PayloadAction<{
        serviceItemsAppointmentWeb: IServiceItem[];
      }>
    ) => {
      state.serviceItemsAppointmentWeb = serviceItemsAppointmentWeb;
    },
    setClearFilterItemProfessional: (state) => {
      state.serviceItemsFilterArray = [
        { key: "idcategoriaservico", value: null },
        { key: "idconvenio", value: null },
        { key: "nome", value: [] },
        { key: "valor", value: [] },
        { key: "ativo", value: null },
      ];
    },
    setServiceItemProfessional: (
      state,
      {
        payload: { service_professionals, total, page },
      }: PayloadAction<{
        service_professionals: IServiceProfessional[];
        total: number;
        page: number;
      }>
    ) => {
      state.serviceItemProfessional = service_professionals;
      state.total = total;
      state.page = page;
    },
    setEditServiceItemProfessional: (
      state,
      {
        payload: { service_professionals, total, page },
      }: PayloadAction<{
        service_professionals: IServiceProfessional[];
        total: number;
        page: number;
      }>
    ) => {
      state.editServiceItemProfessional = service_professionals;
      state.toEditTotal = total;
      state.toEditPage = page;
    },
    setUserRestrictionByProfesional: (
      state,
      {
        payload: { user_restrictions, total, page },
      }: PayloadAction<{
        user_restrictions: IUserRestriction[];
        total: number;
        page: number;
      }>
    ) => {
      state.userRestrictions = user_restrictions;
      state.total = total;
      state.page = page;
    },
    setToAddPagination: (
      state,
      {
        payload: { total, page },
      }: PayloadAction<{
        page: number;
        total: number;
      }>
    ) => {
      state.toAddPage = page;
      state.toAddTotal = total;
    },
    setCurrentIdServiceItem: (state, { payload }: PayloadAction<string>) => {
      state.currentIdServiceItem = payload;
    },
    setCurrentIdProfessional: (state, { payload }: PayloadAction<string>) => {
      state.currentIdProfessional = payload;
    },
    setServiceProfessionalsIncluded: (
      state,
      {
        payload: { service_professionals },
      }: PayloadAction<{
        service_professionals: IServiceProfessional[];
      }>
    ) => {
      state.serviceProfessionalsIncluded = service_professionals;
    },
    setServiceItemsWithProfessional: (
      state,
      { payload }: PayloadAction<IServiceItemWithProfessional[]>
    ) => {
      state.serviceItemsWithProfessional = payload;
    },
    setServiceItemsToAddProfessional: (
      state,
      { payload }: PayloadAction<IServiceItemWithProfessional[]>
    ) => {
      state.serviceItemsToAddProfessional = payload;
    },
    setServiceItemsSelectOptions: (
      state,
      {
        payload,
      }: PayloadAction<
        { name: string; id: string; outside: boolean; category: string }[]
      >
    ) => {
      state.serviceItemsSelectOptions = payload;
    },
    setCurrentServiceItem: (
      state,
      { payload }: PayloadAction<IServiceItem>
    ) => {
      state.currentServiceItem = payload;
    },
    updateToAddFilter: (state, { payload }: PayloadAction<IFilter>) => {
      const index = state.toAddFilterArray.findIndex(
        (item) => item.key === payload.key
      );
      state.toAddFilterArray[index].value = payload.value;
    },
    resetToAddFilterArray: (state) => {
      state.toAddFilterArray = state.serviceItemsOriginalFilterArray;
    },
    updateFilter: (state, { payload }: PayloadAction<IFilter>) => {
      const index = state.serviceItemsFilterArray.findIndex(
        (item) => item.key === payload.key
      );
      if (index === -1) {
        state.serviceItemsFilterArray.push({
          key: payload.key,
          value: payload.value,
        });
      } else {
        state.serviceItemsFilterArray[index].value = payload.value;
      }
    },
    updateServiceItemsSchedulePageFilter: (
      state,
      { payload }: PayloadAction<IFilter>
    ) => {
      const index = state.serviceItemsSchedulePageFilterArray.findIndex(
        (item) => item.key === payload.key
      );
      state.serviceItemsSchedulePageFilterArray[index].value = payload.value;
    },
    resetServiceItemsFilterArray: (state) => {
      state.serviceItemsFilterArray = state.serviceItemsOriginalFilterArray;
    },
    setIsFetchingServiceProfessionals: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingServiceProfessionals = payload;
    },
    setIsCreatingServiceProfessional: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCreatingServiceProfessional = payload;
    },
    setIsCreatingServiceProfessionalBatch: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCreatingServiceProfessionalBatch = payload;
    },
    setIsEditingServiceProfessionalBatch: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isEditingServiceProfessionalBatch = payload;
    },
    setisDeletingServiceProfessional: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isDeletingServiceProfessional = payload;
    },
    setServiceProfessionals: (
      state,
      {
        payload: { service_professionals, total, page },
      }: PayloadAction<{
        service_professionals: IServiceProfessional[];
        total: number;
        page: number;
      }>
    ) => {
      state.serviceProfessionals = service_professionals;
      state.serviceProfessionalsTotal = total;
      state.serviceProfessionalsPage = page;
    },
    setCurrentServiceProfessional: (
      state,
      { payload }: PayloadAction<IServiceProfessional>
    ) => {
      state.currentServiceProfessional = payload;
    },
    setServiceItemsByCategory: (
      state,
      { payload }: PayloadAction<IServiceItem[]>
    ) => {
      state.serviceItemByCategory = payload;
    },
    clearServiceItems: (state) => {
      state.serviceItems = null;
    },
    setPageToComboItemsWaitingSchedule: (
      state,
      { payload }: PayloadAction<number>
    ) => {
      state.pageToComboItemsWaitingSchedule = payload;
    },
    setPageToServiceItems: (state, { payload }: PayloadAction<number>) => {
      state.pageToServiceItems = payload;
    },
    setAmountOfItemsToShow: (state, { payload }: PayloadAction<number>) => {
      state.amountOfItemsToShow = payload;
    },
    setCurrentServiceCategory: (
      state,
      { payload }: PayloadAction<IReactSelectFilter>
    ) => {
      state.currentServiceCategory = payload;
    },
    setIsFetchingServiceItemHistory: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingServiceItemHistory = payload;
    },
    setIsCreatingServiceItemHistory: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCreatingServiceItemHistory = payload;
    },
    setServiceItemHistory: (
      state,
      { payload }: PayloadAction<IServiceHistory[]>
    ) => {
      state.serviceItemHistory = payload;
    },
    setIsFetchingServiceProfessionalHistory: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingServiceProfessionalHistory = payload;
    },
    setIsCreatingServiceProfessionalHistory: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCreatingServiceProfessionalHistory = payload;
    },
    setServiceProfessionalHistory: (
      state,
      { payload }: PayloadAction<IServiceHistory[]>
    ) => {
      state.serviceProfessionalHistory = payload;
    },
    updateServiceItemsStandardTableFilterArray: (state, { payload }: PayloadAction<IFilter>) => {
      const index = state.serviceItemsStandardTableFilterArray.findIndex(
        (item) => item.key === payload.key
      );
      state.serviceItemsStandardTableFilterArray[index].value = payload.value;
    },
    resetServiceItemsStandardTableFilterArray: (state) => {
      state.serviceItemsStandardTableFilterArray = [
        { key: "idversaotabela", value: null }
      ];
    },
    setServiceProfessionalDeleteErrorData: (
      state,
      { payload }: PayloadAction<IDeleteScheduleError[]>
    ) => {
      state.serviceProfessionalDeleteErrorData = payload;
    },
    setServiceItemsToAddInFavoritesList: (
      state,
      { payload }: PayloadAction<IServiceItemToAddInFavoritesList[]>
    ) => {
      state.serviceItemsToAddInFavoritesList = payload;
    },
    setIsFetchingServiceItemsToAddInFavoritesList: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isFetchingServiceItemsToAddInFavoritesList = payload;
    },
    updateServiceItemsToAddInFavoritesListFilter: (
      state,
      { payload }: PayloadAction<IFilter>
    ) => {
      const index = state.serviceItemsToAddInFavoritesListFilter.findIndex(
        (item) => item.key === payload.key
      );
      state.serviceItemsToAddInFavoritesListFilter[index].value = payload.value;
    },
  },
});

export const fetchUserComboItemsWaitingSchedule =
  ({
    patientId,
    limit = 6,
    page = 1,
  }: {
    patientId: string;
    limit: number;
    page: number;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const {
      setIsFetchingUserCombosWaitingSchedule,
      setUserComboItemsWaitingSchedule,
    } = serviceItemsSlice.actions;
    dispatch(setIsFetchingUserCombosWaitingSchedule(true));

    try {
      const response = await api.get(
        `/api/patients/${patientId}/wallet/unpaid/combo?limit=${limit}&page=${page}`
      );
      dispatch(setUserComboItemsWaitingSchedule(response.data.data));
      dispatch(setIsFetchingUserCombosWaitingSchedule(false));
    } catch (error: any) {
      dispatch(setIsFetchingUserCombosWaitingSchedule(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchUserComboItemsWaitingScheduleClosed =
  ({
    patientId,
    limit = 6,
    page = 1,
  }: {
    patientId: string;
    limit: number;
    page: number;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const {
      setIsFetchingUserCombosWaitingScheduleClosed,
      setUserComboItemsWaitingScheduleClosed,
    } = serviceItemsSlice.actions;
    dispatch(setIsFetchingUserCombosWaitingScheduleClosed(true));

    try {
      const response = await api.get(
        `/api/patients/${patientId}/wallet/paid/combo?limit=${limit}&page=${page}`
      );
      dispatch(setUserComboItemsWaitingScheduleClosed(response.data.data));
      dispatch(setIsFetchingUserCombosWaitingScheduleClosed(false));
    } catch (error: any) {
      dispatch(setIsFetchingUserCombosWaitingScheduleClosed(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchServiceItems =
  ({
    page = 1,
    limit = 6,
    idCategory,
    nome,
    highlights = false,
  }: {
    page?: number;
    limit?: number;
    idCategory?: string;
    nome?: string;
    highlights?: boolean;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsFetchingServiceItems, setServiceItems } =
      serviceItemsSlice.actions;
    dispatch(setIsFetchingServiceItems(true));
    try {
      const state = getState();
      const { serviceItemsFilterArray: filterArray } = state.serviceItems;
      const queryParameters = queryStringFromFilterArray(filterArray);
      const nomeParams = nome ? `&nome=${nome}` : "";
      const idCategoryParams = idCategory
        ? `&idcategoriaservico=${idCategory}`
        : "";
      const highlight = highlights ? `&destaque=${highlights}` : "";

      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;
      const response = await api.get(
        `/api/serviceitems${queryParameters}${pageAndLimit}${nomeParams}${idCategoryParams}${highlight}`
      );
      dispatch(setServiceItems(response.data.data));
      dispatch(setIsFetchingServiceItems(false));
    } catch (error: any) {
      dispatch(setIsFetchingServiceItems(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchServiceItemsAppointmentWeb =
  ({
    search,
  }: {
    search?: string;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsFetchingServiceItems, setServiceItemsAppointmentWeb } =
      serviceItemsSlice.actions;
    dispatch(setIsFetchingServiceItems(true));
    try {
      const queryParameters = search ? `?search=${search}` : "";

      const response = await api.get(
        `/api/users-patients/service-items${queryParameters}`
      );
      dispatch(
        setServiceItemsAppointmentWeb({
          serviceItemsAppointmentWeb: response.data.data,
        })
      );
      dispatch(setIsFetchingServiceItems(false));
    } catch (error: any) {
      dispatch(setIsFetchingServiceItems(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchServiceItemsActive =
  ({
    page = 1,
    limit = 6,
    idCategory,
    nome,
    highlights = false,
  }: {
    page?: number;
    limit?: number;
    idCategory?: string;
    nome?: string;
    highlights?: boolean;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsFetchingServiceItems, setServiceItems } =
      serviceItemsSlice.actions;
    dispatch(setIsFetchingServiceItems(true));
    try {
      const state = getState();
      const { serviceItemsFilterArray: filterArray } = state.serviceItems;
      const queryParameters = queryStringFromFilterArray(filterArray);
      const nomeParams = nome ? `&nome=${nome}` : "";
      const idCategoryParams = idCategory
        ? `&idcategoriaservico=${idCategory}`
        : "";
      const highlight = highlights ? `&destaque=${highlights}` : "";
      const active = `&ativo=true`;

      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;
      const response = await api.get(
        `/api/serviceitems${queryParameters}${pageAndLimit}${nomeParams}${idCategoryParams}${highlight}${active}`
      );

      dispatch(setServiceItems(response.data.data));
      dispatch(setIsFetchingServiceItems(false));
    } catch (error: any) {
      dispatch(setIsFetchingServiceItems(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchOnlySchedulableServiceItems =
  ({
    page = 1,
    limit = 6,
    idCategory,
    nome,
    highlights = false,
  }: {
    page?: number;
    limit?: number;
    idCategory?: string;
    nome?: string;
    highlights?: boolean;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsFetchingServiceItems, setServiceItems } =
      serviceItemsSlice.actions;
    dispatch(setIsFetchingServiceItems(true));
    try {
      const state = getState();
      const { serviceItemsFilterArray: filterArray } = state.serviceItems;
      const queryParameters = queryStringFromFilterArray(filterArray);
      const nomeParams = nome ? `&nome=${nome}` : "";
      const idCategoryParams = idCategory
        ? `&idcategoriaservico=${idCategory}`
        : "";
      const highlight = highlights ? `&destaque=${highlights}` : "";
      const active = `&ativo=true`;
      const agendamento = `&agendamento=true`;

      const pageAndLimit =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;
      const response = await api.get(
        `/api/serviceitems${queryParameters}${pageAndLimit}${nomeParams}${idCategoryParams}${highlight}${active}${agendamento}`
      );

      dispatch(setServiceItems(response.data.data));
      dispatch(setIsFetchingServiceItems(false));
    } catch (error: any) {
      dispatch(setIsFetchingServiceItems(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchAllServiceItemsActive =
  (): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsFetchingServiceItems, setServiceItems } =
      serviceItemsSlice.actions;
    dispatch(setIsFetchingServiceItems(true));
    try {
      const response = await api.get(`/api/serviceitems?page=1&limit=9999`);
      dispatch(setServiceItems(response.data.data));
      dispatch(setIsFetchingServiceItems(false));
    } catch (error: any) {
      dispatch(setIsFetchingServiceItems(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchFeaturedServiceItems =
  ({
    destaque,
  }: {
    destaque?: boolean;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsFetchingServiceItems, setFeaturedServiceItems } =
      serviceItemsSlice.actions;
    dispatch(setIsFetchingServiceItems(true));
    try {
      let queryParameters = "";

      if (destaque) {
        queryParameters += `?destaque=${destaque}`;
      }

      if (!destaque) {
        queryParameters += `?page=1&limit=9999`;
      } else {
        queryParameters += `&page=1&limit=9999`;
      }

      const response = await api.get(
        `/api/serviceitems-quotes${queryParameters}`
      );
      dispatch(setFeaturedServiceItems(response.data.data));
      dispatch(setIsFetchingServiceItems(false));
    } catch (error: any) {
      dispatch(setIsFetchingServiceItems(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchServiceItemById =
  (serviceItemId: string): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsFetchingServiceItems, setCurrentServiceItem } =
      serviceItemsSlice.actions;
    dispatch(setIsFetchingServiceItems(true));
    try {
      const response = await api.get(`/api/serviceitems/${serviceItemId}`);
      dispatch(setCurrentServiceItem(response.data.data));
      dispatch(setIsFetchingServiceItems(false));
    } catch (error: any) {
      dispatch(setIsFetchingServiceItems(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchServiceItemByProfessionalId =
  ({
    professionalId,
    idcategoriaservico,
    limit = 99,
  }: {
    professionalId: string;
    idcategoriaservico?: string;
    limit?: number;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsFetchingServiceItems, setServiceItems } =
      serviceItemsSlice.actions;
    dispatch(setIsFetchingServiceItems(true));
    try {
      const response = await api.get(
        `/api/serviceitems?idprofissional=${professionalId}&idcategoriaservico=${idcategoriaservico}&limit=${limit}`
      );
      dispatch(setServiceItems(response.data.data));
      dispatch(setIsFetchingServiceItems(false));
    } catch (error: any) {
      dispatch(setIsFetchingServiceItems(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const setItemByCategory =
  ({
    categoryId,
    limit = 6,
    ativo,
  }: {
    categoryId: string;
    limit?: number;
    ativo?: boolean;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsFetchingServiceItems, setServiceItemsByCategory } =
      serviceItemsSlice.actions;
    try {
      dispatch(setIsFetchingServiceItems(true));
      let requestString = `/api/serviceitems?idcategoriaservico=${categoryId}&limit=${999}`;
      if (ativo) {
        requestString += `&ativo=${ativo}`;
      }
      const response = await api.get(requestString);
      dispatch(setServiceItemsByCategory(response.data.data.service_items));
      dispatch(setIsFetchingServiceItems(false));
    } catch (error: any) {
      dispatch(setIsFetchingServiceItems(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const setHealthPlanItemByCategory =
  ({
    categoryId,
    ativo
  }: {
    categoryId: string;
    limit?: number;
    ativo?: boolean;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsFetchingServiceItems, setServiceItemsByCategory } =
      serviceItemsSlice.actions;
    const state = getState();

    dispatch(setIsFetchingServiceItems(true));
    try {
      const { serviceItemsStandardTableFilterArray: filterArray } = state.serviceItems;
      const queryParameters = queryStringFromFilterArray(filterArray);

      let requestString = `/api/healthplan/standardtable/service-items${queryParameters}&limit=${9999}`;

      if (categoryId) {
        requestString += `&idcategoriaservico=${categoryId}`;
      }
      if (ativo) {
        requestString += `&ativo=${ativo}`;
      }
      const response = await api.get(requestString);
      dispatch(setServiceItemsByCategory(response.data.data.service_items));
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsFetchingServiceItems(false));
    }
  };

export const fetchServiceItemsProfessionalsByServiceItemAndProfessional =
  ({
    page = 1,
    limit = 6,
    serviceItemId,
    professionalId,
  }: {
    page?: number;
    limit?: number;
    serviceItemId: string;
    professionalId: string;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setServiceProfessionalsIncluded } = serviceItemsSlice.actions;
    try {
      const response = await api.get(
        `/api/serviceprofessionals?iditemservico=${serviceItemId}&idprofissional=${professionalId}`
      );

      dispatch(
        setServiceProfessionalsIncluded({
          service_professionals: response.data.data.service_professionals,
        })
      );
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchServiceItemsProfessionalsByServiceItem =
  ({
    page = 1,
    limit = 6,
    serviceItemId,
  }: {
    page?: number;
    limit?: number;
    serviceItemId: string;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsFetchingServiceProfessionals, setServiceProfessionals } =
      serviceItemsSlice.actions;
    dispatch(setIsFetchingServiceProfessionals(true));
    try {
      // const state = getState();
      // const { filterArray } = state.serviceItems;
      // const queryParameters = queryStringFromFilterArray(filterArray);
      const response = await api.get(
        `/api/serviceprofessionals?page=${page}&limit=${limit}&iditemservico=${serviceItemId}`
      );
      dispatch(setServiceProfessionals(response.data.data));
      dispatch(setIsFetchingServiceProfessionals(false));
    } catch (error: any) {
      dispatch(setIsFetchingServiceProfessionals(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchServiceItemsWithProfessional =
  ({
    page = 1,
    limit = 6,
    professionalId,
    isLab,
  }: {
    page?: number;
    limit?: number;
    professionalId: string;
    isLab?: boolean;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const {
      setServiceItemsWithProfessional,
      setIsFetchingServiceItems,
      setServiceItems,
      setServiceItemProfessional,
    } = serviceItemsSlice.actions;
    dispatch(setIsFetchingServiceItems(true));
    try {
      const state = getState();
      const { serviceItemsFilterArray: filterArray } = state.serviceItems;
      const queryParameters = queryStringFromFilterArray(filterArray);
      var link =
        queryParameters.length === 0
          ? `?idprofissional=${professionalId}&page=${page}&limit=${limit}`
          : `&idprofissional=${professionalId}&page=${page}&limit=${limit}`;
      if (isLab) {
        link =
          queryParameters.length === 0
            ? `?idprestador=${professionalId}&page=${page}&limit=${limit}`
            : `&idprestador=${professionalId}&page=${page}&limit=${limit}`;
      }
      const response = await api.get(
        `/api/serviceitems${queryParameters}${link}`
      );
      dispatch(setServiceItems(response.data.data));
      // TO-DO: Refactor External Partners
      if (isLab) {
        const items: IServiceItemWithProfessional[] = [];
        if (response.data.success) {
          let si_query: string[] = [];

          response.data.data.service_items.map((si: IServiceItem) =>
            si_query.push(si.iditemservico)
          );

          const responseServiceProfessional = await api.get(
            `/api/serviceprofessionals?page=${1}&limit=${999}&iditemservico=${si_query}`
          );

          response.data.data.service_items.map(
            (si: IServiceItem) =>
              new Promise<void>(async (resolve, reject) => {
                let professional: any = "";
                let prestador: any = null;
                if (isLab) {
                  prestador =
                    responseServiceProfessional.data.data.service_professionals.find(
                      (pro: any) =>
                        pro.idprestador === professionalId &&
                        pro.iditemservico === si.iditemservico
                    );
                  items.push({
                    ...si,
                    professional: null,
                    prestador,
                  });
                } else {
                  professional =
                    responseServiceProfessional.data.data.service_professionals.find(
                      (pro: IServiceProfessional) =>
                        pro.idprofissional === professionalId &&
                        pro.iditemservico === si.iditemservico
                    );
                  items.push({
                    ...si,
                    professional,
                    prestador: null,
                  });
                }

                resolve();
              })
          );
        }

        dispatch(setServiceItemsWithProfessional(items));
      } else {
        const responseServiceProfessional = await api.get(
          `/api/serviceitems/professionals${queryParameters}${link}&filterItems=true`
        );

        dispatch(
          setServiceItemsWithProfessional(
            responseServiceProfessional.data.data.items
          )
        );
      }

      dispatch(setIsFetchingServiceItems(false));
    } catch (error: any) {
      dispatch(setIsFetchingServiceItems(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchServiceItemProfessional =
  ({
    page = 1,
    limit = 6,
    professionalId,
    isLab,
  }: // itemName,
  {
    page?: number;
    limit?: number;
    professionalId: string;
    isLab?: boolean;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const {
      setServiceItemsWithProfessional,
      setIsFetchingServiceItems,
      setServiceItems,
      setServiceItemProfessional,
    } = serviceItemsSlice.actions;
    const state = getState();
    const { serviceItemsFilterArray: filterArray } = state.serviceItems;
    const queryParameters = queryStringFromFilterArray(filterArray);

    let link =
      queryParameters.length === 0
        ? `?idprofissional=${professionalId}&page=${page}&limit=${limit}`
        : `&idprofissional=${professionalId}&page=${page}&limit=${limit}`;

    if (isLab) {
      link =
        queryParameters.length === 0
          ? `?idprestador=${professionalId}&page=${page}&limit=${limit}`
          : `&idprestador=${professionalId}&page=${page}&limit=${limit}`;
    }

    dispatch(setIsFetchingServiceItems(true));
    try {
      const response = await api.get(
        `/api/serviceprofessionals${queryParameters}${link}`
      );
      dispatch(setServiceItemProfessional(response.data.data));
      dispatch(setIsFetchingServiceItems(false));
    } catch (error: any) {
      dispatch(setIsFetchingServiceItems(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchEditServiceItemProfessional =
  ({
    page = 1,
    limit = 6,
    professionalId,
    isLab,
  }: {
    page?: number;
    limit?: number;
    professionalId: string;
    isLab?: boolean;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsFetchingEditServiceItems, setEditServiceItemProfessional } =
      serviceItemsSlice.actions;
    const state = getState();
    const { toAddFilterArray: filterArray } = state.serviceItems;
    const queryParameters = queryStringFromFilterArray(filterArray);

    let link =
      queryParameters.length === 0
        ? `?idprofissional=${professionalId}&page=${page}&limit=${limit}`
        : `&idprofissional=${professionalId}&page=${page}&limit=${limit}`;

    if (isLab) {
      link =
        queryParameters.length === 0
          ? `?idprestador=${professionalId}&page=${page}&limit=${limit}`
          : `&idprestador=${professionalId}&page=${page}&limit=${limit}`;
    }

    dispatch(setIsFetchingEditServiceItems(true));
    try {
      const response = await api.get(
        `/api/serviceprofessionals${queryParameters}${link}`
      );
      dispatch(setEditServiceItemProfessional(response.data.data));
      dispatch(setIsFetchingEditServiceItems(false));
    } catch (error: any) {
      dispatch(setIsFetchingEditServiceItems(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const unbindServiceItemFromProfessional =
  ({
    serviceProfessionalId,
    professionalId,
    isLab,
  }: {
    serviceProfessionalId: string;
    professionalId: string;
    isLab?: boolean;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsFetchingServiceItems, setServiceProfessionalDeleteErrorData } =
      serviceItemsSlice.actions;
    try {
      dispatch(setIsFetchingServiceItems(true));
      const response = await api.delete(
        `/api/serviceprofessionals/${serviceProfessionalId}`
      );
      if (response.data.success) {
        toast.success("Item de serviço desvinculado do profissional");
      }
      if (isLab) {
        dispatch(fetchServiceItemsWithProfessional({ professionalId, isLab }));
      } else {
        dispatch(fetchServiceItemProfessional({ professionalId }));
      }
      dispatch(setIsFetchingServiceItems(false));
    } catch (error: any) {
      dispatch(setIsFetchingServiceItems(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        if (error.response.data.data.schedulesWithProblemRelated) {
          dispatch(
            setServiceProfessionalDeleteErrorData(
              error.response.data.data.schedulesWithProblemRelated
            )
          );
        }
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchServiceItemsToAddToFavoritesList =
  ({
    page = 1,
    limit = 6,
    professionalId,
    isLab = false,
  }: {
    page?: number;
    limit?: number;
    professionalId?: string;
    isLab?: boolean;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const {
      setServiceItemsToAddInFavoritesList,
      setIsFetchingServiceItemsToAddInFavoritesList,
    } = serviceItemsSlice.actions;

    try {
      const state = getState();
      const { serviceItemsToAddInFavoritesListFilter: filterArray } =
        state.serviceItems;
      const queryParameters = queryStringFromFilterArray(filterArray);
      const active = `&ativo=true`;

      const link =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;

      dispatch(setIsFetchingServiceItemsToAddInFavoritesList(true));
      const response = await api.get(
        `/api/serviceitems/professionals/favorites${queryParameters}${link}${active}`
      );

      dispatch(setServiceItemsToAddInFavoritesList(response.data.data));
      dispatch(setIsFetchingServiceItemsToAddInFavoritesList(false));
    } catch (error: any) {
      dispatch(setIsFetchingServiceItemsToAddInFavoritesList(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchServiceItemsToAddProfessional =
  ({
    page = 1,
    limit = 6,
    professionalId,
    isLab = false,
  }: {
    page?: number;
    limit?: number;
    professionalId?: string;
    isLab?: boolean;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const {
      setIsFetchingServiceItems,
      setToAddPagination,
      setServiceItemsToAddProfessional,
      setIsToAddProfessional,
    } = serviceItemsSlice.actions;
    dispatch(setIsToAddProfessional(true));

    try {
      const state = getState();
      const { toAddFilterArray: filterArray } = state.serviceItems;
      const queryParameters = queryStringFromFilterArray(filterArray);
      const active = `&ativo=true`;
      let professional = "";

      if (isLab) {
        professional = `&idprestador=${professionalId}`;
      } else {
        professional = `&idprofissional=${professionalId}`;
      }

      const link =
        queryParameters.length === 0
          ? `?page=${page}&limit=${limit}`
          : `&page=${page}&limit=${limit}`;
      const response = await api.get(
        `/api/serviceitems/professionals${queryParameters}${link}${active}${professional}`
      );
      // const response = await api.get(
      //   `/api/serviceitems${queryParameters}${link}${active}`
      // );
      /* const items: IServiceItemWithProfessional[] = [];
      if (response.data.success) {
        let si_query: string[] = [];

        response.data.data.service_items.map((si: IServiceItem) =>
          si_query.push(si.iditemservico)
        );

        const responseServiceProfessional = await api.get(
          `/api/serviceprofessionals?page=${1}&limit=${999}&iditemservico=${si_query}`
        );

        response.data.data.service_items.map(
          (si: IServiceItem) =>
            new Promise<void>(async (resolve, reject) => {
              let professional: any = null;
              let prestador: any = null;
              if (isLab) {
                prestador =
                  responseServiceProfessional.data.data.service_professionals.find(
                    (pro: any) =>
                      pro.idprestador === professionalId &&
                      pro.iditemservico === si.iditemservico
                  );
                items.push({
                  ...si,
                  professional: null,
                  prestador,
                });
              } else {
                professional =
                  responseServiceProfessional.data.data.service_professionals.find(
                    (pro: IServiceProfessional) =>
                      pro.idprofissional === professionalId &&
                      pro.iditemservico === si.iditemservico
                  );
                items.push({
                  ...si,
                  professional,
                  prestador: null,
                });
              }

              resolve();
            })
        );
      } */
      //@ts-ignore
      dispatch(
        setToAddPagination({
          page: response.data.data.page,
          total: response.data.data.total,
        })
      );
      dispatch(setServiceItemsToAddProfessional(response.data.data.items));
      dispatch(setIsToAddProfessional(false));
    } catch (error: any) {
      dispatch(setIsFetchingServiceItems(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchServiceItemsProfessionalsById =
  ({
    serviceProfessionalId,
  }: {
    serviceProfessionalId: string;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsFetchingServiceProfessionals, setCurrentServiceProfessional } =
      serviceItemsSlice.actions;
    dispatch(setIsFetchingServiceProfessionals(true));
    try {
      const response = await api.get(
        `/api/serviceprofessionals/${serviceProfessionalId}`
      );
      dispatch(setCurrentServiceProfessional(response.data.data));
      dispatch(setIsFetchingServiceProfessionals(false));
    } catch (error: any) {
      dispatch(setIsFetchingServiceProfessionals(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const createServiceItem =
  (
    serviceItem: IServiceItemForm
  ): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsCreatingServiceItem } = serviceItemsSlice.actions;
    dispatch(setIsCreatingServiceItem(true));
    try {
      await api.post(`/api/serviceitems`, serviceItem);
      dispatch(setIsCreatingServiceItem(false));
      history.replace("/service-items");
      toast.success("Item de serviço cadastrado", toastOptions);
    } catch (error: any) {
      dispatch(setIsCreatingServiceItem(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const newCreateServiceProfessional =
  (
    serviceProfessional: IServiceProfessionalForm | any
  ): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { currentIdProfessional, currentIdServiceItem } =
      getState().serviceItems;
    const { setIsCreatingServiceProfessional } = serviceItemsSlice.actions;
    dispatch(setIsCreatingServiceProfessional(true));
    try {
      const serviceProfessionals: IServiceProfessionalForm[] = [];

      serviceProfessionals.push(serviceProfessional);

      await api.post(`/api/serviceprofessionals`, {
        service_professionals: serviceProfessionals,
      });

      dispatch(
        fetchServiceItemsProfessionalsByServiceItemAndProfessional({
          serviceItemId: currentIdServiceItem,
          professionalId: currentIdProfessional,
        })
      );

      toast.success("Profissional vinculado", toastOptions);
      dispatch(setIsCreatingServiceProfessional(false));
    } catch (error: any) {
      dispatch(setIsCreatingServiceProfessional(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const createServiceProfessional =
  (
    serviceProfessional: IServiceProfessionalForm | any,
    cb?: any
  ): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsCreatingServiceProfessional } = serviceItemsSlice.actions;
    dispatch(setIsCreatingServiceProfessional(true));
    try {
      console.log(serviceProfessional);
      await api.post(`/api/serviceprofessionals`, serviceProfessional);
      dispatch(setIsCreatingServiceProfessional(false));
      toast.success("Profissional vinculado", toastOptions);
      if (cb) {
        cb();
      } else {
        history.replace(
          `/service-items/${serviceProfessional.iditemservico}/service-professionals`
        );
      }
    } catch (error: any) {
      dispatch(setIsCreatingServiceProfessional(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const createServiceProfessionalBatch =
  (
    data: IServiceProfessionalBatch,
    professionalId: string,
    cb?: any,
    isLab?: boolean
  ): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsCreatingServiceProfessionalBatch } = serviceItemsSlice.actions;
    dispatch(setIsCreatingServiceProfessionalBatch(true));

    try {
      console.log(data);
      const response = await api.post(`/api/serviceprofessionals`, data);

      if (response.data.success) {
        const message = `Profissional vinculado ${
          data.service_professionals.length > 1 ? "aos itens" : "ao item"
        }`;
        toast.success(message, toastOptions);
      }

      dispatch(setServiceItemsToProfessionalRowsPerPage(6));
      dispatch(setServiceItemsToProfessionalPage(0));

      if (isLab) {
        dispatch(
          fetchServiceItemsToAddProfessional({
            professionalId: professionalId,
            isLab: isLab,
          })
        );
      } else {
        dispatch(
          fetchServiceItemsToAddProfessional({
            professionalId: professionalId,
          })
        );
      }

      dispatch(setIsCreatingServiceProfessionalBatch(false));
    } catch (error: any) {
      dispatch(setIsCreatingServiceProfessionalBatch(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const editServiceProfessionalBatch =
  (
    data: IEditServiceProfessionalBatch,
    professionalId: string,
    cb?: any,
    isLab?: boolean
  ): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsEditingServiceProfessionalBatch } = serviceItemsSlice.actions;
    dispatch(setIsEditingServiceProfessionalBatch(true));

    try {
      const response = await api.put(`/api/serviceprofessionals`, data);

      if (response.data.success) {
        const message = `${
          data.service_professionals.length > 1
            ? "Itens editados"
            : "Item editado"
        } com sucesso`;
        toast.success(message, toastOptions);
      }

      dispatch(setServiceItemsToProfessionalRowsPerPage(6));
      dispatch(setServiceItemsToProfessionalPage(0));

      if (isLab) {
        dispatch(
          fetchEditServiceItemProfessional({
            professionalId: professionalId,
            isLab: isLab,
          })
        );
      } else {
        dispatch(
          fetchEditServiceItemProfessional({
            professionalId: professionalId,
          })
        );
      }

      dispatch(setIsEditingServiceProfessionalBatch(false));
    } catch (error: any) {
      dispatch(setIsEditingServiceProfessionalBatch(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const addServiceItemToUserRestrictions =
  ({
    user_restrictions,
  }: {
    user_restrictions: {
      idprofissional: string;
      iditemservico: string;
      idademinima: number;
      idademaxima: number;
    }[];
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsCreatingUserRestrictions } = serviceItemsSlice.actions;
    dispatch(setIsCreatingUserRestrictions(true));
    try {
      await api.post(`/api/user-restrictions`, { user_restrictions });
      dispatch(setIsCreatingUserRestrictions(false));
      const message =
        user_restrictions.length > 1
          ? "Itens vinculados com sucesso"
          : "Item vinculado com sucesso";
      toast.success(message, toastOptions);
    } catch (error: any) {
      dispatch(setIsCreatingUserRestrictions(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };
export const deleteRestriction =
  (
    id: string,
    professionalId: string
  ): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsFetchingUserRestrictions, setUserRestrictionByProfesional } =
      serviceItemsSlice.actions;
    dispatch(setIsFetchingUserRestrictions(true));
    try {
      const response = await api.delete(`/api/user-restrictions/${id}`);
      dispatch(setIsFetchingUserRestrictions(false));
      dispatch(fetchUserRestrictionsByProfessional({ professionalId }));
    } catch (error: any) {
      dispatch(setIsFetchingUserRestrictions(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };
export const fetchUserRestrictionsByProfessional =
  ({
    page = 1,
    limit = 6,
    professionalId,
  }: // isLab,
  {
    page?: number;
    limit?: number;
    professionalId: string;
    // isLab?: boolean;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsFetchingUserRestrictions, setUserRestrictionByProfesional } =
      serviceItemsSlice.actions;
    dispatch(setIsFetchingUserRestrictions(true));
    try {
      const response = await api.get(
        `/api/user-restrictions?idprofissional=${professionalId}&page=${page}&limit=${limit}`
      );
      dispatch(setUserRestrictionByProfesional(response.data.data));
      dispatch(setIsFetchingUserRestrictions(false));
    } catch (error: any) {
      dispatch(setIsFetchingUserRestrictions(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updateUserRestriction =
  ({
    professionalId,
    restrictionId,
    idademinima,
    idademaxima,
  }: {
    professionalId: string;
    restrictionId: string;
    idademinima: number;
    idademaxima: number;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setisDeletingServiceItem } = serviceItemsSlice.actions;
    dispatch(setisDeletingServiceItem(true));
    try {
      await api.put(`/api/user-restrictions/${restrictionId}`, {
        idademinima,
        idademaxima,
      });
      dispatch(setisDeletingServiceItem(false));
      toast.success("Item atualizado com sucesso", toastOptions);

      dispatch(fetchUserRestrictionsByProfessional({ professionalId }));
    } catch (error: any) {
      dispatch(setisDeletingServiceItem(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updateServiceItem =
  ({
    serviceItem,
    serviceItemId,
  }: {
    serviceItem: IServiceItemForm;
    serviceItemId: string | number;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsCreatingServiceItem } = serviceItemsSlice.actions;
    dispatch(setIsCreatingServiceItem(true));
    try {
      console.log(serviceItem);
      await api.put(`/api/serviceitems/${serviceItemId}`, serviceItem);
      dispatch(setIsCreatingServiceItem(false));
      toast.success("Item atualizado", toastOptions);
      history.replace("/service-items");
    } catch (error: any) {
      dispatch(setIsCreatingServiceItem(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const updateServiceProfessional =
  ({
    serviceProfessional,
    serviceProfessionalId,
    cb,
  }: {
    serviceProfessional: IServiceProfessionalForm | any;
    serviceProfessionalId: string | number;
    cb?: any;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsCreatingServiceItem } = serviceItemsSlice.actions;
    const { currentIdProfessional, currentIdServiceItem } =
      getState().serviceItems;
    dispatch(setIsCreatingServiceItem(true));
    try {
      await api.put(
        `/api/serviceprofessionals/${serviceProfessionalId}`,
        serviceProfessional
      );

      if (currentIdProfessional && currentIdServiceItem) {
        dispatch(
          fetchServiceItemsProfessionalsByServiceItemAndProfessional({
            serviceItemId: currentIdServiceItem,
            professionalId: currentIdProfessional,
          })
        );
      }
      dispatch(setIsCreatingServiceItem(false));
      toast.success("Vínculo atualizado", toastOptions);
      if (cb) {
        cb();
      } else {
        history.replace(
          `/service-items/${serviceProfessional.iditemservico}/service-professionals`
        );
      }
    } catch (error: any) {
      dispatch(setIsCreatingServiceItem(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const serviceItemActivation =
  ({
    serviceItemId,
    isItemActive,
  }: {
    serviceItemId: string;
    isItemActive: boolean;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setisDeletingServiceItem } = serviceItemsSlice.actions;
    dispatch(setisDeletingServiceItem(true));
    try {
      await api.put(`/api/serviceitems/${serviceItemId}`, {
        ativo: !isItemActive,
      });
      dispatch(setisDeletingServiceItem(false));
      toast.success(
        `Item de serviço ${
          isItemActive ? "desativado" : "ativado"
        } com sucesso`,
        toastOptions
      );
      dispatch(fetchServiceItems({}));
    } catch (error: any) {
      dispatch(setisDeletingServiceItem(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const deleteServiceProfessional =
  (
    serviceProfessionalId: string,
    serviceItemId: string
  ): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setisDeletingServiceItem } = serviceItemsSlice.actions;
    dispatch(setisDeletingServiceItem(true));
    try {
      await api.delete(`/api/serviceprofessionals/${serviceProfessionalId}`);
      dispatch(setisDeletingServiceItem(false));
      toast.success("Profissional desvinculado com sucesso", toastOptions);
      dispatch(fetchServiceItemsProfessionalsByServiceItem({ serviceItemId }));
    } catch (error: any) {
      dispatch(setisDeletingServiceItem(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchServiceItemsNotInServicePackage =
  ({
    servicePackageId,
  }: {
    servicePackageId: string;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsFetchingServiceItems, setServiceItems } =
      serviceItemsSlice.actions;
    dispatch(setIsFetchingServiceItems(true));
    try {
      const data = await api.get(
        `/api/combos/${servicePackageId}/items-missing`
      );
      dispatch(setIsFetchingServiceItems(false));
      dispatch(setServiceItems(data.data.data));
    } catch (error: any) {
      dispatch(setIsFetchingServiceItems(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchServiceItemsNotInCustomerQuote =
  ({
    customerQuoteId,
    page = 1,
    limit = 6,
  }: {
    customerQuoteId: string;
    page?: number;
    limit?: number;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsFetchingServiceItems, setServiceItems } =
      serviceItemsSlice.actions;
    dispatch(setIsFetchingServiceItems(true));
    try {
      const state = getState();
      const { serviceItemsFilterArray: filterArray } = state.serviceItems;
      const queryParameters = queryStringFromFilterArray(filterArray);
      console.log(queryParameters);
      const data = await api.get(
        `/api/customerquotes/${customerQuoteId}/items-missing${queryParameters}`
      );
      dispatch(setIsFetchingServiceItems(false));
      dispatch(setServiceItems(data.data.data));
    } catch (error: any) {
      dispatch(setIsFetchingServiceItems(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchServiceItemsByProfessionalAndCategory =
  ({
    page = 1,
    limit = 6,
  }: {
    page?: number;
    limit?: number;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    const { setIsFetchingServiceItems, setServiceItemsSelectOptions } =
      serviceItemsSlice.actions;
    dispatch(setIsFetchingServiceItems(true));
    try {
      const state = getState();
      const { serviceItemsSchedulePageFilterArray } = state.serviceItems;
      const queryParameters = queryStringFromFilterArray(
        serviceItemsSchedulePageFilterArray
      );
      const response = await api.get(
        `/api/serviceprofessionals-items${queryParameters}`
      );
      dispatch(setServiceItemsSelectOptions(response.data.data));
      dispatch(setIsFetchingServiceItems(false));
    } catch (error: any) {
      dispatch(setIsFetchingServiceItems(false));
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    }
  };

export const fetchServiceProfessionalHistory =
  ({
    serviceProfessionalId,
    serviceHealthPlanProfessionalId,
  }: {
    serviceProfessionalId?: string;
    serviceHealthPlanProfessionalId?: string;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const {
      setServiceProfessionalHistory,
      setIsFetchingServiceProfessionalHistory,
    } = serviceItemsSlice.actions;
    dispatch(setIsFetchingServiceProfessionalHistory(true));

    try {
      if (serviceProfessionalId) {
        const response = await api.get(
          `/api/serviceprofessionals-changelogs?idservicoprofissional=${serviceProfessionalId}`
        );

        dispatch(
          setServiceProfessionalHistory(response.data.data as IServiceHistory[])
        );
      }

      if (serviceHealthPlanProfessionalId) {
        const response = await api.get(
          `/api/serviceprofessionals-changelogs?idservicoconvenioprofissional=${serviceHealthPlanProfessionalId}`
        );

        dispatch(
          setServiceProfessionalHistory(response.data.data as IServiceHistory[])
        );
      }
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsFetchingServiceProfessionalHistory(false));
    }
  };

export const createServiceProfessionalHistory =
  ({
    serviceProfessionalId,
    serviceHealthPlanProfessionalId,
    descricao,
    observacao,
    disableToast,
    callback,
  }: {
    serviceProfessionalId?: string;
    serviceHealthPlanProfessionalId?: string;
    descricao: string;
    observacao?: string;
    disableToast?: boolean;
    callback?: Function;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsCreatingServiceProfessionalHistory } =
      serviceItemsSlice.actions;
    dispatch(setIsCreatingServiceProfessionalHistory(true));

    try {
      if (serviceProfessionalId) {
        await api.post(`/api/serviceprofessionals-changelogs`, {
          idservicoprofissional: serviceProfessionalId,
          descricao: descricao,
          observacao: observacao,
        });
      }

      if (serviceHealthPlanProfessionalId) {
        await api.post(`/api/serviceprofessionals-changelogs`, {
          idservicoconvenioprofissional: serviceHealthPlanProfessionalId,
          descricao: descricao,
          observacao: observacao,
        });
      }

      if (!disableToast) {
        toast.success("Atividade adicionada ao histórico", toastOptions);
      }

      if (callback) callback();
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsCreatingServiceProfessionalHistory(false));
    }
  };

export const fetchServiceItemHistory =
  ({
    serviceItemId,
    serviceHealthPlanItemId,
  }: {
    serviceItemId?: string;
    serviceHealthPlanItemId?: string;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setServiceItemHistory, setIsFetchingServiceItemHistory } =
      serviceItemsSlice.actions;
    dispatch(setIsFetchingServiceItemHistory(true));

    try {
      const queryParameters = serviceItemId
        ? `?iditemservico=${serviceItemId}`
        : serviceHealthPlanItemId
        ? `?idservicoconveniocategoria=${serviceHealthPlanItemId}`
        : "";

      const response = await api.get(
        `/api/serviceitems-changelogs${queryParameters}`
      );

      dispatch(setServiceItemHistory(response.data.data as IServiceHistory[]));
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsFetchingServiceItemHistory(false));
    }
  };

export const createServiceItemHistory =
  ({
    serviceItemId,
    serviceHealthPlanItemId,
    descricao,
    observacao,
    disableToast,
    callback,
  }: {
    serviceItemId?: string;
    serviceHealthPlanItemId?: string;
    descricao: string;
    observacao?: string;
    disableToast?: boolean;
    callback?: Function;
  }): ThunkAction<void, rootState, unknown, AnyAction> =>
  async (dispatch) => {
    const { setIsCreatingServiceItemHistory } = serviceItemsSlice.actions;
    dispatch(setIsCreatingServiceItemHistory(true));

    try {
      if (serviceItemId) {
        await api.post(`/api/serviceitems-changelogs`, {
          iditemservico: serviceItemId,
          descricao: descricao,
          observacao: observacao,
        });
      }

      if (serviceHealthPlanItemId) {
        await api.post(`/api/serviceitems-changelogs`, {
          idservicoconveniocategoria: serviceHealthPlanItemId,
          descricao: descricao,
          observacao: observacao,
        });
      }

      if (!disableToast) {
        toast.success("Atividade adicionada ao histórico", toastOptions);
      }

      if (callback) callback();
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data?.error?.message, toastOptions);
      } else {
        console.log(error.message);
      }
    } finally {
      dispatch(setIsCreatingServiceItemHistory(false));
    }
  };

export const {
  updateToAddFilter,
  updateFilter: updateServiceItemsFilter,
  updateServiceItemsStandardTableFilterArray,
  resetServiceItemsStandardTableFilterArray,
  resetServiceItemsFilterArray,
  clearServiceItems,
  updateServiceItemsSchedulePageFilter,
  setCurrentIdServiceItem,
  setCurrentIdProfessional,
  setServiceProfessionalsIncluded,
  setEditServiceItemsToProfessionalRowsPerPage,
  setEditServiceItemsToProfessionalPage,
  setServiceItemsToProfessionalRowsPerPage,
  setServiceItemsToProfessionalPage,
  setServiceItemsToExternalPage,
  setServiceItemsToExternalRowsPerPage,
  setPageToComboItemsWaitingSchedule,
  setIsEditBondDialogOpen,
  setPageToServiceItems,
  setAmountOfItemsToShow,
  setCurrentServiceCategory,
  setClearFilterItemProfessional,
  setServiceItemsByCategory,
  setServiceProfessionalDeleteErrorData,
  updateServiceItemsToAddInFavoritesListFilter,
} = serviceItemsSlice.actions;

export default serviceItemsSlice.reducer;
